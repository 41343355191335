import { FilterBuilderParamOpt } from './../../../models/filter-builder';
import { ItemSearchOptionFieldOption } from './../../filter-list/models/filterClasses';
import { ModalConfirmComponent } from './../../system/modal-confirm/modal-confirm.component';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  TableColumn,
  ColumnMode,
  NgxDatatableModule
} from '@swimlane/ngx-datatable';
import { Workflow, WorkflowType } from 'src/app/models';
import {
  DataSetField,
  FilterConfig,
  WorkflowFilter
} from 'src/app/models/custom-report';
import {
  DataSet,
  ReportDataSetOrder,
  SortDirection,
  WorkflowApplicationReportDataSet
} from 'src/app/models/report-data-set';
import { ReportDataSetFieldMapping } from 'src/app/models/report-data-set-field-mapping';
import {
  Utilities,
  WorkflowContextService,
  WorkflowService
} from 'src/app/services';
import { ReportService } from 'src/app/services/report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, TimeoutError, of } from 'rxjs';
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
  faTrash,
  faCog,
  faFilter,
  faSearch,
  faArrowsAlt,
  faBars
} from '@fortawesome/free-solid-svg-icons';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import {
  FilterBuilderFilter,
  FilterBuilderParam
} from 'src/app/models/filter-builder';
import {
  DataEntity,
  DateDataEntity,
  FeeDataEntity,
  ListDataEntity,
  NumericDataEntity
} from 'src/app/models/data-entities';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'wm-dataset-designer',
  templateUrl: './dataset-designer.component.html',
  styleUrls: ['./dataset-designer.component.css']
})
export class DatasetDesignerComponent implements OnInit {
  availbleWorkflows: Workflow[];
  dataSet: WorkflowApplicationReportDataSet;
  ds: DataSet;
  columns: TableColumn[];
  rows: any[];
  workflowEntities: { [key: string]: string[] };
  resultColumns: TableColumn[];
  resultRows: any[];
  workflowIdx: { [key: string]: Workflow };

  @ViewChild('workflow', { static: true }) workflowColumnTmpl: TemplateRef<any>;
  @ViewChild('workflowFooter', { static: false }) workflowColumnFooterTmpl: TemplateRef<any>;
  @ViewChild('cell', { static: true }) columnTmpl: TemplateRef<any>;
  @ViewChild('columnHeader', { static: true }) columnHeaderTmpl: TemplateRef<any>;
  @ViewChild('dsGrid', { static: false }) dsGrid: DatatableComponent;
  @ViewChild('columnActions', { static: true }) columnActions: ElementRef<HTMLElement>;
  @ViewChild('fieldSettings', { static: true }) fieldSettings: ModalConfirmComponent;
  @ViewChild('workflowStaticFilter', { static: true })
  workflowStaticFilter: ModalConfirmComponent;
  @ViewChild('filterInputConfig', { static: true })
  filterInputConfig: ModalConfirmComponent;
  @ViewChild('mappingConfig', { static: true })
  mappingConfig: ModalConfirmComponent;
  form: UntypedFormGroup;
  filterInputForm: UntypedFormGroup;
  fieldSettingsForm: UntypedFormGroup;
  mappingForm: UntypedFormGroup;

  faPlus = faPlus;
  faTrash = faTrash;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faCog = faCog;
  faFilter = faFilter;
  faSearch = faSearch;
  faArrowsAlt = faArrowsAlt;
  faBars = faBars;

  ColumnMode = ColumnMode;

  hoverColumn: TableColumn;
  actionColumn: TableColumn;
  actionField: DataSetField;
  hoveringOnAction = false;
  hoverLeft = 0;
  workflowFilterConfig: WorkflowFilter;
  workflowFilterParams: FilterBuilderParam[];
  workflowFilters: FilterBuilderFilter[];
  filterTypes = [];
  filterInputField: DataSetField;
  activeMapping: ReportDataSetFieldMapping;
  allowFiltering = false;
  executingTest = false;
  columnHideId: any;
  fieldSettingsTitle: string;
  ALL_WORKFLOWS_ID = Utilities.EMPTY_GUID; // 'DAD0A740-C081-44DD-B8FC-08D1B7FB79F7'; // static generated GUID because there was a workflow with EMPTY GUID in DEV.
  canPublish = false;

  constructor(
    private _workflowSvc: WorkflowService,
    private _reportSvc: ReportService,
    private _context: WorkflowContextService,
    private _route: ActivatedRoute,
    private _toastr: ToastrService,
    private _router: Router,
    private _ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      fieldOrder: new UntypedFormGroup({}),
      workflows: new UntypedFormGroup({}),
      dataSetName: new UntypedFormControl('', Validators.required),
      dataSetDesc: new UntypedFormControl('', Validators.nullValidator)
    });

    this.filterInputForm = new UntypedFormGroup({
      field: new UntypedFormControl('', Validators.required),
      label: new UntypedFormControl('', Validators.nullValidator),
      isRequired: new UntypedFormControl('', Validators.nullValidator)
    });

    this.fieldSettingsForm = new UntypedFormGroup({
      fieldName: new UntypedFormControl('', Validators.required),
      fieldHeader: new UntypedFormControl('', Validators.required),
      fieldDataType: new UntypedFormControl('', Validators.required)
    });

    this.mappingForm = new UntypedFormGroup({
      columnEntity: new UntypedFormControl('', Validators.required)
    });

    this._workflowSvc
      .getWorkflows(this._context.client, false, false, [
        WorkflowType.ContractorRegistration,
        WorkflowType.Renewable,
        WorkflowType.ContractorRenewal,
        WorkflowType.Permit,
        WorkflowType.Other
      ])
      .subscribe(workflows => {
        this.workflowIdx = {};
        workflows.forEach(w => {
          this.workflowIdx[w.id] = w;
        });
        this.availbleWorkflows = workflows;
      });

    this._route.params.subscribe(parms => {
      const dataSetId: string = parms.dataSetId;

      Observable.create(o => {
        if (dataSetId === Utilities.EMPTY_GUID) {
          const ds = new DataSet();
          ds.dataSetConfig = new WorkflowApplicationReportDataSet();
          ds.clientId = this._context.client.id;
          ds.dataSetConfig.clientIds = [this._context.client.id];
          o.next(ds);
        } else {
          this._workflowSvc.getDataSet(dataSetId).subscribe(ds => {
            o.next(ds);
          });
        }
      }).subscribe(ds => {
        this.workflowEntities = {};

        if (!ds.filterConfig) {
          ds.filterConfig = new FilterConfig({ fields: [] });
        }

        this.allowFiltering = (ds.filterConfig.fields || []).length > 0;

        this.ds = ds;
        this.dataSet = ds.dataSetConfig;

        // default to asc sort
        if (ds.dataSetConfig.fieldOrder.length === 0) {
          this.addSortOrder();
        } else {
          this.buildSortOrderUI();

          if (this.dataSet.workflowIds.length > 0) {
            const workflowGroup: UntypedFormGroup = <UntypedFormGroup>(
              this.form.controls['workflows']
            );
            this.dataSet.workflowIds.forEach((id, idx) => {
              workflowGroup.addControl(
                idx.toString(),
                new UntypedFormControl(id, Validators.required)
              );
            });
            this.workflowFilterIndex = {};
            this.dataSet.workflowFilters.forEach(f => {
              this.workflowFilterIndex[f.workflowId] = f;
            });
          }
        }

        this.refreshGrid();

        this.canPublish = this.canTest();

        this.form.valueChanges.subscribe(f => {
          this.canPublish = this.canTest();
          this._ref.detectChanges();
        });

        this._ref.detectChanges();
      });
    });
  }

  buildSortOrderUI() {
    this.form.removeControl('fieldOrder');

    this.sortFieldOrder();

    const fg = new UntypedFormGroup({});

    this.ds.dataSetConfig.fieldOrder.forEach((f, idx) => {
      fg.addControl(
        'field' + idx.toString(),
        new UntypedFormControl('', Validators.required)
      );
      fg.addControl(
        'order' + idx.toString(),
        new UntypedFormControl('', Validators.required)
      );
    });

    this.form.addControl('fieldOrder', fg);

    this._ref.detectChanges();
  }

  addFilterInput(field?: DataSetField) {
    if (!field) {
      field = new DataSetField();
    }

    this.filterInputField = field;
    this.filterInputConfig.open();
  }

  editFilterInput(field: DataSetField) {
    this.filterInputField = field;
    this.filterInputConfig.open();
  }

  removeFilterInput(field: DataSetField) {
    this.ds.filterConfig.fields = this.ds.filterConfig.fields.filter(
      f => f.name !== field.name
    );
  }

  updateFilterInput() {
    const f = this.ds.filterConfig.fields.find(
      fld => fld.name === this.filterInputField.name
    );

    if (!f) {
      this.ds.filterConfig.fields.push(this.filterInputField);
    }
    this.filterInputField = null;
  }

  workflowFilterIndex: { [key: string]: WorkflowFilter };

  isFiltered(workflowId: string): boolean {
    return (
      this.workflowFilterIndex &&
      this.workflowFilterIndex[workflowId] != null &&
      this.workflowFilterIndex[workflowId].filtersList &&
      this.workflowFilterIndex[workflowId].filtersList.length > 0
    );
  }

  async filterWorkflow(workflowId: string) {
    this.workflowFilterParams = null;
    this.workflowFilters = null;
    this.workflowFilterConfig = null;

    let filter = (this.dataSet.workflowFilters || []).find(
      f => f.workflowId === workflowId
    );
    if (!filter) {
      filter = new WorkflowFilter();
      filter.workflowId = workflowId;
    }

    let entityObs = null;

    if (workflowId !== this.ALL_WORKFLOWS_ID) {
      entityObs = this._workflowSvc.getDraftWorkflowEntities(workflowId);
    } else {
      entityObs = this._workflowSvc.getDataEntities(null, null, true);
    }

    entityObs.subscribe(entities => {
      const filterParams: FilterBuilderParam[] = entities.map(e => {
        return {
          name: e.templateCode,
          id: e.templateCode,
          paramId: e.templateCode,
          inputType: DataSet.resolveInputType(e),
          types: DataSet.resolveTypes(e),
          options: (() => {
            const os = DataSet.resolveOptions(e);
            if (os) {
              return os.map(o => {
                return { name: o, value: o };
              });
            } else {
              return null;
            }
          })()
        };
      });

      let filters = [];
      if (filter.filtersList) {
        filters = filter.filtersList
          .map(fl => {
            let value = null;
            let valueString = null;

            if (fl.searchText && fl.searchText.indexOf('{') > -1) {
              value = JSON.parse(fl.searchText);
            } else if (fl.strOperator == 'in' && fl.searchText) {
              value = fl.searchText.split(',');
            } else {
              valueString = fl.searchText;
            }

            if (filterParams.find(fp => fp.name === fl.title)) {
              const p = filterParams.find(fp => fp.name === fl.title);
              return new FilterBuilderFilter({
                param: p,
                value: valueString,
                queryType: fl.strOperator,
                customValue: value,
                inputType: fl.inputType,
                paramId: p.id
              });
            }
          })
          .filter(f => f !== null);
      }

      this.workflowFilterConfig = filter;
      this.workflowFilters = filters;

      this.workflowFilterParams = filterParams;

      this.workflowStaticFilter.open();
    });
  }

  workflowFilterChanged(e) {
    const filterKeys = Object.keys(e);

    this.workflowFilterConfig.filtersList = [];
    for (let idx = 0; idx < filterKeys.length; idx++) {
      const key = filterKeys[idx];

      this.workflowFilterConfig.filtersList.push(
        new ItemSearchOptionFieldOption({
          strOperator: e[key].type,
          title: e[key].id,
          filterText: e[key].value,
          searchText: e[key].value,
          inputType: e[key].inputType
        })
      );
    }
  }

  updateWorkflowFilter(workflowFilterConfig) {
    if (!this.dataSet.workflowFilters) {
      this.dataSet.workflowFilters = [];
    }

    const filter = this.dataSet.workflowFilters.find(
      f => f.workflowId == workflowFilterConfig.workflowId
    );

    if (!filter) {
      this.dataSet.workflowFilters.push(workflowFilterConfig);
    }
    this.workflowFilterIndex = {};

    this.dataSet.workflowFilters.forEach(f => {
      this.workflowFilterIndex[f.workflowId] = f;
    });
  }

  canMoveLeft(): boolean {
    if (this.hoverColumn) {
      const hoverColIndex = this.columns.indexOf(this.hoverColumn);
      return hoverColIndex > 1;
    }
    return false;
  }

  canMoveRight(): boolean {
    if (this.hoverColumn) {
      const hoverColIndex = this.columns.indexOf(this.hoverColumn);
      return hoverColIndex < this.columns.length - 2;
    }
    return false;
  }

  hideColumnActions() {
    this.columnHideId = setTimeout(() => {
      if (!this.hoveringOnAction) {
        this.hoverLeft = 0;
        this.hoverColumn = null;
      }
    }, 1000);
  }

  columnHover(column: TableColumn, e) {
    if (this.columnHideId) {
      clearTimeout(this.columnHideId);
    }
    if (this.hoverLeft === 0 || this.hoverColumn !== column) {
      this.hoverLeft =
        e.clientX - this.columnActions.nativeElement.offsetWidth * 2;
    }
    this.hoverColumn = column;
  }

  moveFieldLeft(column: TableColumn) {
    const idx = this.columns.indexOf(column);

    if (idx > -1 && idx > 1) {
      this.moveFieldToIndex(column, idx - 1);
    }
  }

  moveFieldToIndex(column: TableColumn, targetColumnIdx: number) {
    // columns list has a workflowId column at beginning and add column at the end
    // fields list does not have the workflowId column or the add column so we have to offset field indexes from targetColumn Index

    const idx = this.columns.indexOf(column);

    // we don't allow the first column to be moved or the last column to be moved
    if (idx > 0 && idx < this.columns.length) {
      const fieldIdx = idx - 1; // workflowId column isn't in fields list
      const targetFieldIdx = targetColumnIdx - 1;

      if (targetFieldIdx >= 0 && targetFieldIdx < this.dataSet.fields.length) {
        const field = this.dataSet.fields[fieldIdx];
        this.dataSet.fields.splice(fieldIdx, 1); // remove the field from the old index
        this.dataSet.fields.splice(targetFieldIdx, 0, field); // insert the field into the new index
        this.refreshGrid();
      }
    }
  }

  moveFieldRight(column: TableColumn) {
    const idx = this.columns.indexOf(column);

    if (idx > -1 && idx < this.dataSet.fields.length) {
      this.moveFieldToIndex(column, idx + 1);
    }
  }

  openFieldSettings(field: DataSetField) {
    this.actionField = field;
    this.fieldSettingsTitle =
      this.actionField && this.actionField.name !== ''
        ? this.actionField.name
        : 'New';

    this.fieldSettings.open();
  }

  configureField(column: TableColumn) {
    const field = this.dataSet.fields.find(f => f.name === column.prop);

    this.openFieldSettings({ ...field });
  }

  removeSortOrder(fieldOrder: ReportDataSetOrder) {
    const idx = this.dataSet.fieldOrder.indexOf(fieldOrder);

    if (idx > -1) {
      this.dataSet.fieldOrder.splice(idx, 1);
      this.buildSortOrderUI();
    }
  }

  orderChange() {
    let idx = 0;
    this.dataSet.fieldOrder.forEach(fo => {
      fo.ordinal = idx;
      idx++;
    });
    this.buildSortOrderUI();
  }

  sortFieldOrder() {
    this.dataSet.fieldOrder.sort((a, b) => (a.ordinal < b.ordinal ? -1 : 1));
  }

  addSortOrder() {
    (<UntypedFormGroup>this.form.controls['fieldOrder']).addControl(
      'field' + this.dataSet.fieldOrder.length.toString(),
      new UntypedFormControl('', Validators.required)
    );
    (<UntypedFormGroup>this.form.controls['fieldOrder']).addControl(
      'order' + this.dataSet.fieldOrder.length.toString(),
      new UntypedFormControl('', Validators.required)
    );

    this.dataSet.fieldOrder.push(
      new ReportDataSetOrder({ ordinal: this.dataSet.fieldOrder.length })
    );

    this.buildSortOrderUI();
  }

  updateWorkflow(row) {
    const rowIdx = this.rows.indexOf(row);

    if (rowIdx > -1) {
      if (rowIdx < this.dataSet.workflowIds.length) {
        const workflowId = (<UntypedFormGroup>this.form.controls['workflows'])
          .controls[rowIdx].value;
        row['workflowId'] = workflowId;
        this.dataSet.workflowIds[rowIdx] = workflowId;
        if (
          !this.workflowEntities[workflowId] &&
          (this.dataSet.workflowIds[rowIdx] || '') !== ''
        ) {
          this._workflowSvc
            .getWorkflowEntities(this.dataSet.workflowIds[rowIdx])
            .subscribe(entities => {
              this.workflowEntities[workflowId] = entities;
            });
        }
        if (
          this.dataSet.workflowFilters &&
          rowIdx < this.dataSet.workflowFilters.length
        ) {
          const wf = this.dataSet.workflowFilters[rowIdx];

          if (wf && !wf.filtersList) {
            wf.filtersList = [];
          }
        }
      }
    }
  }

  cancelColumn() {
    this.actionField = null;
  }
  updateColumn() {
    const exColumn = this.dataSet.fields.find(f => f.id == this.actionField.id);

    if (!exColumn) {
      // check to see if a column with the same name exists
      const exColumnName = this.dataSet.fields.find(
        f => f.name.toLowerCase() == this.actionField.name.toLowerCase()
      );

      if (exColumnName) {
        this._toastr.error(
          `There is already a existing column with name ${this.actionField.name}.`
        );
        return;
      }

      this.dataSet.fields.push(this.actionField);
    } else {
      const idx = this.dataSet.fields.indexOf(exColumn);

      if (idx > -1) {
        this.dataSet.fields[idx] = this.actionField;
      }
    }

    this.refreshGrid();
    this.actionField = null;
  }

  addMapping(row, column) {
    const mapping = new ReportDataSetFieldMapping();
    mapping.workflowId = row['workflowId'];
    mapping.columnIndex = this.dsGrid.columns.indexOf(column);
    this.activeMapping = mapping;
    this.mappingForm.reset();
    this.mappingConfig.open();
  }

  cancelMapping() {
    this.activeMapping = null;
  }
  updateMapping() {
    const column = this.dsGrid.columns[this.activeMapping.columnIndex];

    const dsField = this.dataSet.fields.find(f => f.name === column.prop);

    if (dsField) {
      let mappings = dsField.mappings;

      if (!mappings) {
        mappings = [];
      }

      const mapping = mappings.find(
        m =>
          m.workflowId === this.activeMapping.workflowId &&
          m.templateCode === this.activeMapping.templateCode
      );

      if (!mapping) {
        mappings.push(this.activeMapping);

        this.refreshGrid();
      }
    }

    this.activeMapping = null;
  }

  addColumn() {
    const field = new DataSetField();

    field.name = '';

    this.openFieldSettings(field);
  }

  refreshGrid() {
    const columns = this.buildColumns();
    const rows = this.buildRows();

    this.columns = columns;
    this.rows = rows;
  }

  addWorkflow() {
    (<UntypedFormGroup>this.form.controls['workflows']).addControl(
      this.dataSet.workflowIds.length.toString(),
      new UntypedFormControl('', Validators.required)
    );
    this.dataSet.workflowIds.push('');
    this.refreshGrid();
  }

  removeWorkflow(workflowId: string) {
    const newIds = this.dataSet.workflowIds.filter(wid => wid !== workflowId);

    this.dataSet.fields.forEach(f => {
      f.mappings = f.mappings.filter(m => m.workflowId !== workflowId);
    });

    this.dataSet.workflowIds = newIds;

    if (this.dataSet.workflowFilters) {
      this.dataSet.workflowFilters = this.dataSet.workflowFilters.filter(
        f => f.workflowId !== workflowId
      );
    }

    this.refreshGrid();
  }

  removeColumn(column: TableColumn) {
    this.hoveringOnAction = true;
    const fieldOrderProp = this.dataSet.fieldOrder.find(
      fo => fo.fieldName === column.prop
    );

    // if(fieldOrderProp) {
    //   fieldOrderProp.fieldName = null;
    // }

    this.dataSet.fields = this.dataSet.fields.filter(
      f => f.name !== column.prop
    );

    // remove column from input filters if exists
    this.ds.filterConfig.fields = this.ds.filterConfig.fields.filter(
      f => f.name !== column.prop
    );

    // flip the allow filters toggle to false if there aren't any fields in the dataset
    if (this.dataSet.fields.length === 0) {
      this.allowFiltering = false;
    }

    this.refreshGrid();
  }

  allowFilteringChanged(e) {
    if (!e) {
      this.ds.filterConfig.fields = [];
    }
  }

  removeMapping(column: TableColumn, mappings: any[], idx) {
    const field = this.dataSet.fields.find(f => f.name === column.prop);

    if (field) {
      const mappingToRemove = mappings[idx];
      const mapIdx = field.mappings.indexOf(mappingToRemove);
      field.mappings.splice(mapIdx, 1);
    }
    this.refreshGrid();
  }

  canTest() {
    return (
      this.dataSet &&
      this.dataSet.fields.length !== 0 && // no fields defined
      this.ds &&
      (this.ds.name || '') !== '' && // name defined
      !this.dataSet.fieldOrder.some(fo => (fo.fieldName || '') === '')
    );
  }

  columnsReordered(e: { column: any; newValue: number; prevValue: number }) {
    this.moveFieldToIndex(e.column, e.newValue);
  }

  buildColumns() {
    return [
      {
        prop: 'workflowId',
        name: 'Workflow',
        cellTemplate: this.workflowColumnTmpl,
        canAutoResize: true,
        draggable: false,
        sortable: false,
        frozenLeft: true,
        width: 250
      },
      ...this.dataSet.fields.map(f => {
        return {
          prop: f.name,
          name: f.resultHeader ? `${f.resultHeader} (${f.name})` : f.name,
          cellTemplate: this.columnTmpl,
          headerTemplate: this.columnHeaderTmpl,
          sortable: false,
          minWidth: 150,
          resizeable: false
        };
      }),
      {
        headerTemplate: this.columnHeaderTmpl,
        canAutoResize: false,
        resizeable: false,
        frozenRight: true,
        width: 150,
        sortable: false
      }
    ];
  }

  buildRows() {
    // build a row for each workflow and a column in that row for each column in the dataset
    const rows = [];

    this.dataSet.workflowIds.forEach(w => {
      const row = {
        workflowId: w
      };

      this.dataSet.fields.forEach(f => {
        row[f.name] = f.mappings.filter(m => m.workflowId === w);
      });

      rows.push(row);
    });

    // this needs to be here so that the All Workflows shows up and the horizontal scrollbar displays if necessary
    if (rows.length === 0) {
      rows.push({
        empty: true
      });
    }

    return rows;
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  saveDataSet() {
    if (this.canTest() && this.form.valid) {
      this._workflowSvc.saveDataSet(this.ds).subscribe(ds => {
        if ((this.ds.id || '') === '') {
          this.navigateToExistingDS(ds.id);
        } else {
          this.ds = ds;
          this.dataSet = ds.dataSetConfig;
          this._toastr.success('Data Set Saved');
        }
      });
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  navigateToExistingDS(id: string) {
    this._router.navigate([
      '/admin/jurisdiction',
      this._context.client.id,
      'reports',
      'data-set',
      id
    ]);
  }

  publishDataSet() {
    if (this.canTest()) {
      this._workflowSvc.saveDataSet(this.ds).subscribe(resultDS => {
        this._workflowSvc.publishDataSet(resultDS.id).subscribe(() => {
          this._toastr.success('Data Set Published');
          if ((this.ds.id || '') == '') {
            this.navigateToExistingDS(resultDS.id);
          }
        });
      });
    }
  }

  testDataSet() {
    const request = {
      customReport: {
        dataSetConfig: this.dataSet
      },
      filterInput: { maxRows: 20 }
    };

    this.executingTest = true;
    this.resultColumns = null;

    this.resultRows = null;

    this._reportSvc.testDataSet(request).subscribe(
      results => {
        const resultColumns = this.dataSet.fields.map(f => {
          return {
            prop: (f.resultHeader || f.name).toLowerCase(),
            name: f.resultHeader || f.name
          };
        });

        const resultRows = [];

        for (let idx = 0; idx < results.rows.length; idx++) {
          const r = results.rows[idx];
          const result = {};

          result['id'] = r.id;

          Object.keys(r.values).forEach(k => {
            const newKey = k.toLowerCase();
            result[newKey] = r.values[k];
          });

          resultRows.push(result);
        }

        this.executingTest = false;

        this.resultColumns = resultColumns;

        this.resultRows = resultRows;
      },
      e => {
        this.executingTest = false;
        if (e instanceof TimeoutError) {
          this._toastr.error('Test took too long');
        } else {
          this._toastr.error(e.error.exceptionMessage);
        }
      }
    );
  }
}
