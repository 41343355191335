import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { DataSet } from 'src/app/models/report-data-set';
import { WorkflowService } from 'src/app/services';
import {
  FilterBuilderOutput,
  FilterBuilderParam
} from 'src/app/models/filter-builder';
import { DataSetField } from 'src/app/models/custom-report';
import { ColumnOption } from 'src/app/models/grid-settings';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ShapefileValidationCriteria } from 'src/app/models/scheduled-export';

@Component({
  selector: 'wm-dataset-input',
  templateUrl: './dataset-input.component.html',
  styleUrls: ['./dataset-input.component.css']
})
export class DatasetInputComponent implements OnInit, OnChanges, OnDestroy {
  constructor(private _workflowSvc: WorkflowService) {}
  @Input() shapefileValidationCriteria: ShapefileValidationCriteria;

  @Input() dataSetId: string;
  @Output() filtersChanged: EventEmitter<
    FilterBuilderOutput
  > = new EventEmitter();
  @Output() dataSetLoaded: EventEmitter<DataSet> = new EventEmitter();
  @Output() filterParamsChanged: EventEmitter<
    FilterBuilderParam[]
  > = new EventEmitter();
  @Output() columnsChanged: EventEmitter<string[]> = new EventEmitter();
  @Output() filterFormChanged: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Input() form: UntypedFormGroup;
  reqGrp: UntypedFormGroup;

  columns: ColumnOption[];
  dataSet: DataSet;
  dsFilterParams: FilterBuilderParam[];

  requiredFilterInput: DataSetField[];
  loading = false;

  configureDataSet() {
    if (this.dataSetId) {
      this.loading = true;
      this.dsFilterParams = null;
      this._workflowSvc.getDataSet(this.dataSetId, true).subscribe(ds => {
        // reset all the controls
        const valKeys = Object.keys(this.reqGrp.controls);

        valKeys.forEach(k => {
          this.reqGrp.removeControl(k);
        });

        this.loading = false;

        // setup form controls for search filters
        this.requiredFilterInput = ds.filterConfig.fields.filter(
          f => f.requireInput
        );

        this.requiredFilterInput.forEach(f => {
          this.reqGrp.addControl(
            f.name,
            new UntypedFormControl(f.defaultSearchValue, Validators.required)
          );
          this.reqGrp.controls[f.name].markAsDirty();
        });

        this.filterFormChanged.emit(this.reqGrp);

        const fieldIndex: { [key: string]: DataSetField } = {};
        const fields = ds.publishedDataSetConfig.fields;
        fields.forEach(f => {
          fieldIndex[f.name] = f;
        });

        this.dsFilterParams = ds.publishedFilterConfig.fields.map(f => {
          if (fieldIndex[f.name]) {
            f.dataType = fieldIndex[f.name].dataType;
          }
          return {
            name: f.label || f.name,
            id: f.name,
            inputType: DataSet.resolveInputTypeForField(fieldIndex[f.name]),
            types: DataSet.resolveTypesForField(f),
            options: (() => {
              const os = DataSet.resolveOptionsForField(f);
              if (os) {
                return os.map(o => {
                  return { name: o, value: o };
                });
              } else {
                return null;
              }
            })()
          };
        });

        this.columns = fields.map(
          f =>
            new ColumnOption({
              name: (f.resultHeader || '') != '' ? f.resultHeader : f.name,
              label: (f.resultHeader || '') != '' ? f.resultHeader : f.name,
              includeInExport: true
            })
        );

        this.dataSet = ds;

        this.dataSetLoaded.emit(ds);
        this.filterParamsChanged.emit(this.dsFilterParams);
        this.columnsChanged.emit(this.columns.map(c => c.name));
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSetId']) {
      this.configureDataSet();
    }
  }

  ngOnDestroy(): void {
    this.form.removeControl('requiredParams');
  }

  ngOnInit() {


    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }

    this.reqGrp = new UntypedFormGroup({});

    this.form.addControl('requiredParams', this.reqGrp);
  }

  dsFilterChanged(e) {
    const keys = Object.keys(e);

    const valKeys = Object.keys(this.reqGrp.controls);

    valKeys.forEach(k => {
      this.reqGrp.controls[k].setValue(null);
      this.reqGrp.controls[k].markAsDirty();
      this.reqGrp.controls[k].markAsTouched();
    });

    keys.forEach(k => {
      const ctl = this.reqGrp.controls[k];

      if (ctl) {
        ctl.setValue('populated');
        ctl.markAsDirty();
        ctl.markAsTouched();
      }
    });

    this.filtersChanged.emit(e);
  }

  dsColumnsChanged(e) {
    this.columnsChanged.emit(e);
  }
}
