<form [formGroup]="form" novalidate>
  <div class="form-group" *ngIf="showMapServiceSelection">
    <label class="bmd-label-floating" for="mapService">Map Service</label>
    <select
      class="form-control"
      [(ngModel)]="mapService"
      (change)="onMapServiceChanged()"
      formControlName="mapService"
      [compareWith]="compareMapServiceFn"
    >
      <option *ngFor="let ms of mapServices" [ngValue]="ms"
        >{{ ms.name }} ({{ ms.endpointUrl }})</option
      >
    </select>
  </div>

  <div class="switch">
    <label>
      <input
        type="checkbox"
        formControlName="showToc"
        id="showToc"
        [(ngModel)]="mapConfig.showToc"
      />
      Show the table of contents control?</label
    >
  </div>

  <div class="form-group">
    <label class="bmd-label-floating" for="basemap">Basemap</label>
    <select
      class="form-control"
      formControlName="basemap"
      [(ngModel)]="mapConfig.basemapName"
    >
      <option [ngValue]="null">no basemap</option>
      <option value="topo">topo</option>
      <option value="satellite">satellite</option>
      <option value="hybrid">hybrid</option>
      <option value="gray">gray</option>
      <option value="terrain">terrain</option>
      <option value="osm">osm</option>
      <option value="topo-vector">topo-vector</option>
      <option value="streets-relief-vector">streets-relief-vector</option>
      <option value="streets-vector">streets-vector</option>
      <option value="streets-navigation-vector"
        >streets-navigation-vector</option
      >
    </select>
  </div>

  <div *ngIf="mapConfig.mapServiceId">
    <div class="card">
      <app-esri-map-designtime
        [mapServiceId]="mapConfig.mapServiceId"
        [basemapName]="mapConfig.basemapName"
        [initialExtent]="mapConfig.initialExtent"
        [mapLayerOptions]="mapConfig.layers"
        [showToc]="mapConfig.showToc"
        (mapExtentChanged)="onMapExtentChanged($event)"
        (mapImageLayerChanged)="onMapImageLayerChanged($event)"
        (mapImageLayerFirstLoad)="onMapImageLayerFirstLoad($event)"
        [mapDimensionLayout_class]="mapDimensionLayout_class"
      >
      </app-esri-map-designtime>

      <div class="card-body">
        <div class="btn-group" role="group" aria-label="Map extent controls">
          <button
            class="btn btn-raised btn-primary"
            type="button"
            (click)="onSaveMapExtent()"
          >
            Set extent
          </button>
          <button
            class="btn btn-raised btn-primary"
            type="button"
            (click)="onClearMapExtent()"
          >
            Clear extent
          </button>
          <button
            class="btn btn-raised btn-primary"
            type="button"
            (click)="onZoomMapExtent()"
          >
            zoom to start
          </button>
        </div>

        <div>
          Initial map extent:
          <span *ngIf="mapConfig.initialExtent">
            ({{ mapConfig.initialExtent?.xmin | number: '1.4-4' }},
            {{ mapConfig.initialExtent?.ymin | number: '1.4-4' }}) --- ({{
              mapConfig.initialExtent?.xmax | number: '1.4-4'
            }}, {{ mapConfig.initialExtent?.ymax | number: '1.4-4' }})
          </span>
          <span *ngIf="!mapConfig.initialExtent">not set</span>
        </div>

        <div [formGroup]="layerOptionGroup">
          <h5>Layers</h5>
          <table *ngIf="mapConfig.layers" class="table">
            <thead>
              <tr>
                <th>Layer</th>
                <th>Visibility</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let lyr of mapConfig.layers; let i = index">
                <td>{{ lyr.name }}</td>
                <td>
                  <div class="switch">
                    <label>
                      <input
                        type="checkbox"
                        [formControlName]="['layerVis-' + i]"
                        [(ngModel)]="lyr.visible"
                      />
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="layerOptionGroup.touched && !layerOptionGroup.valid">
            Error: you need a layer.
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
