<div class="card mb-3 mt-3 pl-4 pr-4">
  <div class="row justify-content-between">
    <!-- filters -->
    <div class="col-8 align-self-center" *ngIf="showFilterBuilder">
      <span *ngFor="let filter of filters">
        <ng-template #popContent>
          <div *ngFor="let type of getFilterTypes(filter.param)">
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="queryType"
                [id]="type.name"
                [value]="type.name"
                (change)="changeFilterType(filter)"
                [(ngModel)]="filter.queryType"
              />
              <label class="form-check-label" [for]="type.name">
                {{ type.name }}
              </label>
            </div>
            <div
              class="md-form search-form"
              *ngIf="filter.queryType === type.name"
            >
              <select
                *ngIf="
                  (filter.queryType === 'is' ||
                    filter.queryType === 'is not') &&
                  filter.param.options
                "
                class="form-control"
                style="min-width: 200px;"
                [name]="type.name + '-input'"
                (change)="bypassBinding(filter, $event)"
                [(ngModel)]="filter.value"
              >
                <option [ngValue]="null" hidden selected>
                  Select an option
                </option>
                <ng-container
                  *ngFor="let opt of getFilterOptions(filter.param)"
                >
                  <option [value]="opt.value">
                    {{ opt.name }}
                  </option>
                </ng-container>
              </select>

              <select
                *ngIf="filter.queryType === 'in' && filter.param.options"
                class="form-control"
                style="min-width: 200px; min-height: 300px;"
                [name]="type.name + '-input'"
                (change)="bypassBinding(filter, $event)"
                [(ngModel)]="filter.customValue"
                multiple
              >
                <option [ngValue]="null" hidden selected>
                  Select an option
                </option>
                <ng-container
                  *ngFor="let opt of getFilterOptions(filter.param)"
                >
                  <option [value]="opt.value">
                    {{ opt.name }}
                  </option>
                </ng-container>
              </select>

              <div *ngIf="!filter.param.options && filter.queryType === 'in'">
                <input
                  [type]="filter.param.inputType || 'text'"
                  class="form-control"
                  [name]="type.name + '-input'"
                  placeholder=""
                  [attr.aria-describedby]="type.name + '-inputHelp'"
                  (ngModelChange)="filtersChanged()"
                  [(ngModel)]="filter.value"
                />
                <small
                  [id]="type.name + '-inputHelp'"
                  class="form-text text-muted"
                  >Enter a comma separated list of items to search by.</small
                >
              </div>

              <ng-container *ngIf="filter.queryType === 'range'">
                <select
                  class="form-control mb-3"
                  style="min-width: 200px;"
                  [name]="type.name + '-input'"
                  (change)="filtersChanged()"
                  [(ngModel)]="filter.value"
                  *ngIf="filter.param.inputType === 'date'"
                >
                  <ng-container
                    *ngFor="let opt of getFilterRangeOptions(filter.param)"
                  >
                    <option [value]="opt.value">
                      {{ opt.name }}
                    </option>
                  </ng-container>
                </select>

                <!-- custom absolute date ranges -->
                <ng-container
                  *ngIf="
                    filter.value === 'custom' ||
                    filter.param.inputType === 'text' ||
                    filter.param.inputType === 'number'
                  "
                >
                  <div class="form-group">
                    <label [for]="type.name + '-custom-input-start'"
                      >From</label
                    >
                    <input
                      [type]="filter.param.inputType"
                      class="form-control"
                      [name]="type.name + '-custom-input-start'"
                      placeholder=""
                      (ngModelChange)="filtersChanged()"
                      [(ngModel)]="filter.customValue.start"
                      *ngIf="(filter.param.options || []).length === 0"
                    />
                  </div>
                  <select
                    *ngIf="(filter.param.options || []).length !== 0"
                    class="form-control"
                    [name]="type.name + '-custom-input-start'"
                    (ngModelChange)="filtersChanged()"
                    [(ngModel)]="filter.customValue.start"
                  >
                    <option></option>
                    <option
                      *ngFor="let o of filter.param.options"
                      [value]="o.value"
                    >
                      {{ o.name }}
                    </option>
                  </select>

                  <div class="form-group">
                    <label [for]="type.name + '-custom-input-end'">To</label>
                    <input
                      [type]="filter.param.inputType"
                      class="form-control"
                      [name]="type.name + '-custom-input-end'"
                      placeholder=""
                      (ngModelChange)="filtersChanged()"
                      [(ngModel)]="filter.customValue.end"
                      *ngIf="(filter.param.options || []).length === 0"
                    />
                    <select
                      *ngIf="(filter.param.options || []).length !== 0"
                      class="form-control"
                      [name]="type.name + '-custom-input-end'"
                      (ngModelChange)="filtersChanged()"
                      [(ngModel)]="filter.customValue.end"
                    >
                      <option></option>
                      <option
                        *ngFor="let o of filter.param.options"
                        [value]="o.value"
                      >
                        {{ o.name }}
                      </option>
                    </select>
                  </div>
                </ng-container>
              </ng-container>

              <input
                *ngIf="
                  (!filter.param.options || filter.queryType === 'contains') &&
                  filter.queryType !== 'range' &&
                  filter.queryType !== 'in' &&
                  filter.queryType !== 'exists' &&
                  filter.queryType !== 'not exists'
                "
                [type]="filter.param.inputType || 'text'"
                class="form-control"
                [name]="type.name + '-input'"
                placeholder=""
                (ngModelChange)="filtersChanged()"
                [(ngModel)]="filter.value"
              />
            </div>
          </div>

          <button
            type="button"
            class="btn btn-block btn-primary"
            (click)="p.close()"
          >
            Done
          </button>
        </ng-template>

        <div
          class="chip"
          [autoClose]="'outside'"
          triggers="manual"
          #p="ngbPopover"
          (click)="openFilter(p)"
          #popovers="ngbPopover"
          [ngbPopover]="popContent"
          container="body"
          placement="bottom"
          [id]="'filter-' + filter.id"
        >
          <span class="font-weight-bold">{{ filter.param.name }}</span>
          {{ filterText(filter) }}

          <fa-icon
            placement="top"
            ngbTooltip="Remove this filter"
            class="close"
            [icon]="faTimes"
            (click)="removeFilter(filter)"
          ></fa-icon>
        </div>
      </span>

      <ng-template #filterPopover>
        <input
          id="typeahead-template"
          type="text"
          class="form-control"
          placeholder="Search..."
          [(ngModel)]="searchModel"
        />
        <ul class="list-group">
          <li
            class="list-group-item"
            style="cursor: pointer;"
            *ngFor="let item of filteredParams"
            (click)="addFilter(item)"
          >
            {{ item.name }}
          </li>
        </ul>
      </ng-template>
      <a
        class="btn btn-primary"
        [ngbPopover]="filterPopover"
        #fp="ngbPopover"
        (click)="showFilters()"
        [autoClose]="'outside'"
        triggers="manual"
        container="body"
        placement="bottom"
      >
        + Filter
      </a>
    </div>

    <!-- saved filters -->
    <div
      class="flex-column align-self-center text-right mr-3"
      *ngIf="savedFilters"
    >
      <wm-saved-filter
        [id]="id"
        [getCurrentFilter]="getCurrentFilter"
        [applyFilter]="applyFilter"
        [clientId]="context.client && context.client.id"
      ></wm-saved-filter>
    </div>

    <!--columns button-->
    <div
      class="flex-column align-self-center text-right mr-3"
      [ngClass]="{ 'col-12': !showFilterBuilder }"
      *ngIf="columnOptions"
    >
      <div class="form-group mt-3">
        <!-- only export options -->
        <div
          *ngIf="onlyExportOptions"
          ngbDropdown
          placement="bottom-right"
          (openChange)="dropdownChanged($event)"
          class="d-inline-block"
        >
          <button
            class="btn btn-raised btn-primary float-right"
            id="columns-dropdown"
            ngbDropdownToggle
          >
            Columns <br />
            (
            <span *ngIf="selectedExportColumnsNumber">
              {{ selectedExportColumnsNumber }} / {{ columnOptions.length }}
            </span>
            <span *ngIf="!selectedExportColumnsNumber">
              none
            </span>
            )
          </button>
          <div ngbDropdownMenu aria-labelledby="columns-dropdown">
            <div class="p-3">
              <div class="custom-control custom-checkbox mb-4">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [id]="'selectAll-' + parentId"
                  [name]="'selectAll-' + parentId"
                  (change)="triggerAll($event.target.checked)"
                  [(ngModel)]="allTriggered"
                />
                <label
                  class="custom-control-label"
                  [for]="'selectAll-' + parentId"
                >
                  Select All
                </label>
              </div>

              <div
                class="custom-control custom-checkbox"
                *ngFor="let column of columnOptions"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [id]="'column-' + column.name + '-' + parentId"
                  [name]="'column-' + column.name + '-' + parentId"
                  [value]="column.name"
                  [(ngModel)]="column.includeInExport"
                  (ngModelChange)="dropdownChanged($event)"
                />
                <label
                  class="custom-control-label"
                  [for]="'column-' + column.name + '-' + parentId"
                >
                  <span style="text-wrap: nowrap;">
                    {{ column.label }}&nbsp;
                    <fa-icon
                      placement="top"
                      ngbTooltip="GIS data"
                      [icon]="faGlobe"
                      [hidden]="!isGeoJSON(column)"
                    ></fa-icon
                  ></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- column visibility and export options -->
        <div *ngIf="!onlyExportOptions && exportingEnabled">
          <button
            class="btn btn-raised btn-primary"
            (click)="openColumnModal()"
          >
            Columns
          </button>
          <wm-modal-confirm
            #columnOptionsModal
            title="Column Settings"
            commandText="Save"
            [showButton]="false"
            (accepted)="columnModalClosed()"
            (canceled)="columnModalCanceled()"
          >
            <div class="row">
              <div class="col-1"></div>
              <div class="col-4"></div>
              <div class="col-3">
                Show in Grid
              </div>
              <div class="col-3">
                Include in Export
              </div>
              <div class="col-1"></div>
            </div>

            <div class="row" *ngFor="let column of trialColumnOptions">
              <div class="col-1"></div>
              <div class="col-4">
                {{ column.label }}
              </div>
              <div class="col-3">
                <input
                  class="ml-4"
                  type="checkbox"
                  [(ngModel)]="column.checked"
                />
              </div>
              <div class="col-3">
                <input
                  class="ml-5"
                  type="checkbox"
                  [(ngModel)]="column.includeInExport"
                />
              </div>
              <div class="col-1"></div>
            </div>
          </wm-modal-confirm>
        </div>

        <!-- only column visibility options -->
        <div *ngIf="!onlyExportOptions && !exportingEnabled">
          <button
            class="btn btn-raised btn-primary"
            (click)="openColumnModal()"
          >
            Columns
          </button>
          <wm-modal-confirm
            #columnOptionsModal
            title="Column Settings"
            commandText="Save"
            [showButton]="false"
            (accepted)="columnModalClosed()"
            (canceled)="columnModalCanceled()"
            [small]="true"
          >
            <div class="row">
              <div class="col-8"></div>
              <div class="col-4">
                Show
              </div>
            </div>

            <div class="row" *ngFor="let column of trialColumnOptions">
              <div class="col-8">
                {{ column.label }}
              </div>
              <div class="col-4">
                <input
                  class="ml-2"
                  type="checkbox"
                  [(ngModel)]="column.checked"
                />
              </div>
            </div>
          </wm-modal-confirm>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="showSimpleSearch">
  <div class="col-10">
    <input
      id="simplesearch-template"
      type="text"
      class="form-control simpleSearch"
      [placeholder]="simpleSearchTitle"
      (keyup)="simpleSearchKeyUp($event)"
      [(ngModel)]="simpleSearchModel"
    />
  </div>
</div>
