import { Component, OnInit, Input } from '@angular/core';
import { PaymentAccountConfigBaseComponent } from '../payment-account-config-base/payment-account-config-base.component';
import { VpsPaymentProcessorAccountConfiguration } from '../../../models/payment-providers/vps/vps-payment-processor-account-configuration';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wm-payment-account-config-vps',
  templateUrl: './payment-account-config-vps.component.html',
  styleUrls: ['./payment-account-config-vps.component.css']
})
export class PaymentAccountConfigVpsComponent
  extends PaymentAccountConfigBaseComponent
  implements OnInit {
  @Input() config: VpsPaymentProcessorAccountConfiguration;

  constructor(fb: UntypedFormBuilder) {
    super(fb);
    // this.showUsername = false;
  }

  ngOnInit() {
    this.addValidation('partnerId', Validators.required, this.config.partnerId);
  }
}
