import {
  CustomReportRequest,
  CustomReportFilterInput
} from './../../../models/custom-report';
import { SavedColumn } from './../../../models/saved-filter';
import { ReportService } from './../../../services/report.service';
import { ActivatedRoute } from '@angular/router';
import { WorkflowContextService } from './../../../services/workflow-context.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import {
  ItemSearchOptionField,
  ItemSearchOptionFieldOption
} from './../../filter-list/models/filterClasses';
import { UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import {
  FilterBuilderParam,
  FilterBuilderOutput,
  translateFilterBuilderToLegacy
} from './../../../models/filter-builder';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CustomReport,
  ReportDataSetFieldDataType
} from 'src/app/models/custom-report';

@Component({
  selector: 'wm-custom-report-input',
  templateUrl: './custom-report-input.component.html',
  styleUrls: ['./custom-report-input.component.css']
})
export class CustomReportInputComponent implements OnInit, OnDestroy {
  name = new UntypedFormControl('');
  form: any;
  reportId: string;
  s: string[];
  executing = false;
  executeDisabled = false;
  showDownloadLink = false;
  report: CustomReport;
  columns: SavedColumn[];

  public params: FilterBuilderParam[] = [];
  public filters: ItemSearchOptionField[];

  private clientSubscription: Subscription;

  constructor(
    private sanitizer: DomSanitizer,
    private _context: WorkflowContextService,
    private route: ActivatedRoute,
    private _fb: UntypedFormBuilder,
    private _reportSvc: ReportService
  ) {}

  link = document.createElement('a');

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  executeReport() {
    this.showDownloadLink = false;
    this.executing = true;
    this.executeDisabled = true;

    const request: CustomReportRequest = new CustomReportRequest();

    request.customReportId = this.reportId;
    const filterInput = new CustomReportFilterInput();

    let inputValues: ItemSearchOptionFieldOption[] = [];

    this.filters.forEach(f => {
      f.options.forEach(o => {
        o.title = f.title;
      });
      inputValues = inputValues.concat(f.options);
    });

    filterInput.inputValues = inputValues;
    request.filterInput = filterInput;

    this._reportSvc.executeCustomReport(request).subscribe(
      response => {
        const blob = new Blob([response], {
          type: 'application/pdf'
        });
        // const url = window.URL.createObjectURL(blob);
        this.link.href = window.URL.createObjectURL(blob);
        this.link.download = this.report.name + '.pdf';

        // left these outside the if/else because they should be set
        // the same regardless of export format
        this.executing = false;
        this.executeDisabled = false;
        this.showDownloadLink = true;
      },
      e => {
        this.executing = false;
        this.executeDisabled = false;
        throw e;
      }
    );
  }

  loadReport() {
    this._reportSvc.getCustomReportForInput(this.reportId).subscribe(report => {
      const parms = report.filterConfig.fields.map(f => {
        if (f.dataType === ReportDataSetFieldDataType.Date) {
          return {
            name: f.label,
            id: f.name,
            inputType: 'date',
            types: ['range']
          };
        } else if (f.dataType === ReportDataSetFieldDataType.String) {
          return {
            name: f.label,
            id: f.name,
            inputType: 'text',
            types: ['range'],
            options:
              f.availableFilterItems && f.availableFilterItems.length > 0
                ? f.availableFilterItems.map(afi => {
                    return {
                      name: afi.text,
                      value: afi.value
                    };
                  })
                : null
          };
        }
      });

      this.params = parms;
      this.report = report;
    });
  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.reportId = params['reportId'];

      if (this._context.client) {
        this.loadReport();
      } else {
        this.clientSubscription = this._context.client$.subscribe(() => {
          this.loadReport();
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  filtersChanged(filters: FilterBuilderOutput) {
    this.filters = translateFilterBuilderToLegacy(filters);
  }
}
