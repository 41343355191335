<ng-container *ngIf="applicationEmails">
  <div
    class="row pl-3"
    *ngIf="activity.showResendEmails && canResendEmails() | async"
  >
    <button
      type="button"
      class="btn btn-raised btn-primary"
      (click)="resendEmail()"
      [disabled]="resendingEmail"
    >
      <span>{{ resendingEmail ? 'Sending...' : 'Resend Email' }}</span>
    </button>
    &nbsp;
    <app-loader *ngIf="resendingEmail"></app-loader>
  </div>

  <br />

  <div *ngFor="let applicationEmail of applicationEmails">
    <div class="card">
      <div class="card-body">
        <h6 class="mt-1" *ngIf="applicationEmail.delivered">
          <span class="text-success"><strong>Delivered</strong></span> on
          {{ applicationEmail.sentAtString }}
        </h6>
        <h6
          class="mt-1"
          *ngIf="!applicationEmail.delivered && applicationEmail.sentAtString"
        >
          <span class="text-warning"><strong>Not Delivered</strong></span> -
          Attempted delivery {{ applicationEmail.sentAtString }}
        </h6>
        <h6
          class="mt-1"
          *ngIf="
            !applicationEmail.delivered &&
            applicationEmail.sendOnString &&
            !applicationEmail.sentAtString &&
            !applicationEmail.canceledOnString
          "
        >
          <span class="text-success"><strong>Scheduled</strong></span> for
          {{ applicationEmail.sendOnString }} (<strong
            >Any application data entities are evaluated when the email is
            sent</strong
          >)
        </h6>
        <h6 class="mt-1" *ngIf="applicationEmail.canceledOnString">
          <span class="text-warning"><strong>Canceled</strong></span> on
          {{ applicationEmail.canceledOnString }}
        </h6>

        <div class="mt-3" *ngIf="applicationEmail.errorStatus">
          <span class="text-danger">{{ applicationEmail.errorStatus }}</span>
        </div>

        <div class="mt-3">
          <h6><strong>Options</strong></h6>
        </div>
        <ul style="list-style-type:none;">
          <li>
            Send to the applicant?
            {{ applicationEmail.emailApplicant ? 'Yes' : 'No' }}
          </li>
          <li *ngIf="applicationEmail.roleNames.length > 0">
            Send to members of the following roles:
            <ul class="list-group bmd-list-group-sm">
              <li
                class="list-group-item"
                *ngFor="let roleName of applicationEmail.roleNames"
              >
                {{ roleName }}
              </li>
            </ul>
          </li>
        </ul>

        <div class="mt-3">
          <h6><strong>Recipients</strong></h6>
          <p
            class="text-warning"
            *ngIf="
              applicationEmail.toRecipients.length < 1 &&
              applicationEmail.ccRecipients.length < 1 &&
              applicationEmail.bccRecipients.length < 1 &&
              applicationEmail.roleNames.length < 1 &&
              !applicationEmail.emailApplicant
            "
          >
            There were no recipients...
          </p>

          <div
            *ngIf="
              (applicationEmail.toRecipients &&
                applicationEmail.toRecipients.length > 0) ||
              applicationEmail.emailApplicant
            "
          >
            <h6>To:</h6>
            <ul class="list-group bmd-list-group-sm">
              <li
                class="list-group-item"
                *ngFor="let to of applicationEmail.toRecipients"
              >
                {{ to }}
              </li>
            </ul>
          </div>

          <div
            *ngIf="
              applicationEmail.ccRecipients &&
              applicationEmail.ccRecipients.length > 0
            "
          >
            <h6>CC:</h6>
            <ul class="list-group bmd-list-group-sm">
              <li
                class="list-group-item"
                *ngFor="let cc of applicationEmail.ccRecipients"
              >
                {{ cc }}
              </li>
            </ul>
          </div>

          <div
            *ngIf="
              applicationEmail.bccRecipients &&
              applicationEmail.bccRecipients.length > 0
            "
          >
            <h6>BCC:</h6>
            <ul class="list-group bmd-list-group-sm">
              <li
                class="list-group-item"
                *ngFor="let bcc of applicationEmail.bccRecipients"
              >
                {{ bcc }}
              </li>
            </ul>
          </div>
        </div>

        <div
          class="mt-3"
          *ngIf="
            applicationEmail.attachments &&
            applicationEmail.attachments.length > 0
          "
        >
          <h6><strong>Attachments</strong></h6>
          <ul class="list-group bmd-list-group-sm">
            <li
              class="list-group-item"
              *ngFor="let attachment of applicationEmail.attachments"
            >
              <a href="{{ attachment.path }}" target="blank">{{
                attachment.name
              }}</a>
            </li>
          </ul>
        </div>

        <hr />

        <h6><strong>Subject: </strong> {{ applicationEmail.subject }}</h6>
        <div
          class="p-2"
          style="border:1px solid lightgray; border-radius:5px; box-shadow: 1px 1px lightgray;"
          [innerHTML]="applicationEmail.body"
        ></div>
      </div>
    </div>

    <br />
  </div>
</ng-container>
