import { GeomUtils } from 'src/app/components/arcgis/geom-utils';
import { MapDimensionLayout_htmlClassName } from './../../../models/data-entities/mapsketch-data-entity';
import { ToastrService } from 'ngx-toastr';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  OnDestroy,
  HostListener
} from '@angular/core';

// Esri bits:

// the esri-loader npm module defines the default api version, but you have to also include that version in the css ref in the .css file.
import esri = __esri;
import { MapLayer } from 'src/app/models';
import { MapExtent } from 'src/app/models/mapextent';
import { EsriLoaderService } from 'src/app/components/arcgis/esri-loader.service';
import { MapImageLayerService } from '../mapimagelayer.service';
import { Extent } from 'esri/geometry';
import { Utilities } from 'src/app/services';
import { MapsketchData } from '../mapsketch-data';
import * as numeral from 'numeral';
import * as BufferParameters from 'esri/tasks/support/BufferParameters';
import { ModalConfirmComponent } from '../../system/modal-confirm/modal-confirm.component';
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormControl,
  FormBuilder,
  Validators,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Observable } from 'rxjs';
import * as Graphic from 'esri/Graphic';

@Component({
  selector: 'app-esri-map',
  templateUrl: './esri-map.component.html',
  styleUrls: ['./esri-map.component.css']
})
export class EsriMapComponent implements OnInit, OnDestroy {
  @Output()
  mapLoaded = new EventEmitter();
  @Output() sketchLayerReady = new EventEmitter();
  @Output()
  mapExtentChanged = new EventEmitter<MapExtent>();
  @Output()
  featuresIdentified = new EventEmitter<string[]>();
  @Output()
  featuresBeforeIdentifying = new EventEmitter();
  @Output()
  mapChanging = new EventEmitter<boolean>();
  private sub: any;
  @ViewChild('mapViewNode', { static: true })
  private mapViewEl: ElementRef;
  @ViewChild('tocViewNode', { static: true })
  private tocViewEl: ElementRef;
  @HostListener('document:click', ['$event']) documentClickEvent(
    $event: MouseEvent
  ) {
    this.zoomOnScroll = false;
  }
  @Input()
  useSketchLayer = false;

  @Input()
  useSketchSingleItemMode = false;

  @Input()
  toolbarMode = 'none'; // values: none | sketch

  /* --------------------------------------------------------------------------------------------------- */
  /* MapdisplayConfiguration Object Binding - separate properties for now to keep change detection happy */

  @Input()
  mapServiceId: string;

  @Input()
  initialExtent?: MapExtent; // This should always be WGS84 coords

  @Input()
  basemapName?: string;

  @Input()
  showToc: boolean;

  @Input()
  mapLayerOptions: MapLayer[] = null;

  @Input()
  allowZoom = true;
  @Input()
  featureKeyAsString = true;

  @Input()
  mapDimensionLayout_class?: MapDimensionLayout_htmlClassName;

  @Input()
  multiSelect = false;

  @ViewChild('SaveXYCoordinates', { static: true })
  addCoordinatesModal: ModalConfirmComponent;
  //#endregion
  //#region Toolbar Support
  @ViewChild('toolbarItems', { static: false })
  toolbarItems: ElementRef;
  protected toolbarVisible = false;

  /* --------------------------------------------------------------------------------------------------- */
  public addCoordinatesForm: UntypedFormGroup;
  public xvalue = new UntypedFormControl('358908.02', this.validInput());
  public yvalue = new UntypedFormControl('2139040.37', this.validInput());
  public ddlXyCoordSys = new UntypedFormControl('4326_dd');
  public fromCoordSys = '4326_dd';
  private featureLayerName: string;
  private featureFieldName: string;
  private whereField: string;

  private map: esri.Map;
  private mapView: esri.MapView;
  private mapLayer: esri.MapImageLayer;
  private mapLayerView: esri.LayerView;
  private layerList: esri.LayerList;

  mapLoading: boolean;
  Graphic: any;
  mapDisplayX = '0';
  mapDisplayY = '0';
  private selectedDisplayCoordinateSystem = 0;
  coordinateSystems = new Array();
  displayXYCoords = false;
  displayXYCoordsTitle = 'Lat/Long (dd.dddd)';
  activeButton: string;
  validCoordinate = true;
  graphicsDictionary_cache: {} = {};
  originalMapLayerOptions: MapLayer[] = null;

  constructor(
    private esriLoaderService: EsriLoaderService,
    private mapImageLayerService: MapImageLayerService,
    private _toastrSvc: ToastrService
  ) {
    this.mapLoading = true;
  }

  async ngOnInit() {
    this.setupAddByXYCoordinateForm();
    this.configureAddByXYCoordinate_OnChangeEvent();

    if (this.mapDimensionLayout_class == null) {
      this.mapDimensionLayout_class = `screen-capture-layout-default`;
    }

    this.originalMapLayerOptions = this.mapLayerOptions;

    await this.initializeMap();
    this.setupCoordinateSystemOptions();
  }

  zoomOnScroll = false;

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  async saveScreenshot() {
    const ext = await this.getCurrentMapExtentWGS84();
    const sd = await this.getSketchData();
    // HACK: this is here because the screenshot happens before the view is updated with the new graphics and I can't find anywhere in the docs/forums on what exactly to wait for.
    this.setAllSketchItemsToDefault();
    return new Promise(resolve => {
      setTimeout(async () => {
        const url = await this.getScreenshotURL();
        await this.mapSketchDataChanged.emit({
          graphics: sd,
          extent: ext,
          imageURL: url
        });
        resolve(null);
      }, 100);
    });
  }

  async persistChildComponent() {
    return this.saveScreenshot();
  }

  async toggleCoordinateDisplay(point: any) {
    const [
      EsriMap,
      EsriMapView,
      MapImageLayer,
      watchUtils,
      Extent,
      SpatialReference,
      Graphic,
      LayerList,
      webMercatorUtils
    ] = await this.esriLoaderService.LoadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/MapImageLayer',
      'esri/core/watchUtils',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
      'esri/Graphic',
      'esri/widgets/LayerList',
      'esri/geometry/support/webMercatorUtils'
    ]);

    const geomUtils = new GeomUtils();
    // the map is in web mercator but display coordinates in geographic (lat, long)
    let mp: any = null;
    // add the if checks... make this logic a function...
    if (
      this.coordinateSystems[this.selectedDisplayCoordinateSystem].value ===
      '4326_dd'
    ) {
      mp = webMercatorUtils.webMercatorToGeographic(point);
      this.mapDisplayX = Number(!isNaN(parseFloat(mp.x)) ? mp.x : 0)
        .toFixed(5)
        .toString();

      this.mapDisplayY = Number(!isNaN(parseFloat(mp.y)) ? mp.y : 0)
        .toFixed(5)
        .toString();
    }
    if (
      this.coordinateSystems[this.selectedDisplayCoordinateSystem].value ===
      '4326_dms'
    ) {
      mp = webMercatorUtils.webMercatorToGeographic(point);
      this.mapDisplayX = geomUtils.roundSeconds(
        geomUtils
          .ConvertDDToDMS(!isNaN(parseFloat(mp.x)) ? mp.x : 0, true)
          .toString(),
        2
      );

      this.mapDisplayY = geomUtils.roundSeconds(
        geomUtils
          .ConvertDDToDMS(!isNaN(parseFloat(mp.y)) ? mp.y : 0, false)
          .toString(),
        2
      );
    }
    if (
      this.mapLayer.spatialReference.wkid &&
      this.coordinateSystems[this.selectedDisplayCoordinateSystem].value ===
        this.mapLayer.spatialReference.wkid.toString()
    ) {
      mp = webMercatorUtils.webMercatorToGeographic(point);

      this.projectCoordinates(
        mp.x,
        mp.y,
        this.coordinateSystems[this.selectedDisplayCoordinateSystem].value,
        '4326',
        (D: number, lng: boolean) => {
          this.mapDisplayX = Number(D)
            .toFixed(2)
            .toString();
        },
        (D: number, lng: boolean) => {
          this.mapDisplayY = Number(D)
            .toFixed(2)
            .toString();
        }
      );
    }
  }

  async refreshLayers() {
    if (this.originalMapLayerOptions) {
      this.originalMapLayerOptions.forEach(ol => {
        const ml = this.mapLayer.allSublayers.find(
          l => l.title == ol.name.toString()
        );

        if (ml) {
          ml.visible = ol.visible;
        }
      });
    }
    if (this.mapLayer) {
      await this.mapLayer.load();
    }
  }

  async initializeMap() {
    const [
      EsriMap,
      EsriMapView,
      MapImageLayer,
      watchUtils,
      Extent,
      SpatialReference,
      Graphic,
      LayerList
    ] = await this.esriLoaderService.LoadModules([
      'esri/Map',
      'esri/views/MapView',
      'esri/layers/MapImageLayer',
      'esri/core/watchUtils',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
      'esri/Graphic',
      'esri/widgets/LayerList'
    ]);
    this.Graphic = Graphic;

    try {
      this.mapLayer = new MapImageLayer({
        url: this.getMapServiceEndpointUrl()
      });

      // Set type of map
      const mapProperties: esri.MapProperties = {
        basemap: this.basemapName,
        layers: [this.mapLayer]
      };

      this.map = new EsriMap(mapProperties);

      // Set type of map view
      const mapViewProperties: esri.MapViewProperties = {
        container: this.mapViewEl.nativeElement,
        map: this.map,
        constraints: {
          snapToZoom: false
        },
        spatialReference: SpatialReference.WebMercator // Required for any of the esri basemaps to work.
      };

      if (this.initialExtent) {
        const initExt = new Extent(
          this.initialExtent.xmin,
          this.initialExtent.ymin,
          this.initialExtent.xmax,
          this.initialExtent.ymax
        );
        initExt.spatialReference = SpatialReference.WGS84;
        mapViewProperties.extent = initExt;
      }

      this.mapView = new EsriMapView(mapViewProperties);

      this.initToolbar();

      // let everything spin up:

      await this.mapLayer.load();

      if (!this.originalMapLayerOptions) {
        this.originalMapLayerOptions = [];
        this.mapLayer.allSublayers.forEach(sl => {
          const l = new MapLayer({
            id: sl.id,
            visible: sl.visible,
            name: sl.title
          });
          this.originalMapLayerOptions.push(l);
        });
      }

      if (!this.initialExtent) {
        // if we don't do this, the mapView promise is never fulfilled.
        this.mapView.extent = await this.getFeatureLayerFullExtent();
      }

      await this.mapView.when();
      await this.mapLayer.when();
      await this.mapView.whenLayerView(this.mapLayer).then(layerView => {
        this.mapLayerView = layerView;
        watchUtils.watch(
          this.mapLayerView,
          'updating',
          async (newValue, oldValue, propertyName, target) => {
            this.mapChanging.emit(newValue);
            this.mapLoading = newValue || false;
          }
        );
      });

      this.updateSublayers();

      if (this.showToc) {
        const layerList = new LayerList({
          view: this.mapView,
          container: this.tocViewEl.nativeElement
        });
        this.layerList = layerList;
      }

      // wire up extent tracking
      watchUtils.whenTrue(this.mapView, 'stationary', async () => {
        if (this.mapView.extent) {
          const ext = await this.getCurrentMapExtentWGS84();
          this.mapExtentChanged.emit(ext);
        }
      });

      // stop zooming in/out using scroll wheel
      this.mapView.on('mouse-wheel', event => {
        if (!this.allowZoom || !this.zoomOnScroll) {
          event.stopPropagation();
        }
      });

      this.mapView.on('click', event => {
        if (!this.zoomOnScroll) {
          this.zoomOnScroll = true;
        }
      });

      this.mapView.on('double-click', event => {
        if (!this.allowZoom) {
          event.stopPropagation();
        }
      });

      this.mapView.on('drag', event => {
        if (!this.allowZoom) {
          event.stopPropagation();
        }
      });

      this.mapView.on('drag', ['Shift'], event => {
        if (!this.allowZoom) {
          event.stopPropagation();
        }
      });

      this.mapView.on('drag', ['Shift', 'Control'], event => {
        if (!this.allowZoom) {
          event.stopPropagation();
        }
      });

      this.mapView.on('pointer-move', async event => {
        const [webMercatorUtils] = await this.esriLoaderService.LoadModules([
          'esri/geometry/support/webMercatorUtils'
        ]);
        if (this.coordinateSystems.length > 0) {
          this.displayXYCoords = true;

          const point = this.mapView.toMap({ x: event.x, y: event.y });
          this.toggleCoordinateDisplay(point);
        }
      });

      await this.initReferenceLayer();

      if (this.useSketchLayer) {
        await this.initSketchEnv();
        this.sketchLayerReady.emit();
      }

      this.mapLoaded.emit();
    } catch (ex) {
      this._toastrSvc.error(
        'The map service configured for this workflow is not responding, please try again.  If this problem continues, please contact support to review the map service.',
        null,
        { disableTimeOut: true }
      );
    }
  }

  toggleXYValue(togglex, toggley, toVal) {
    let x = togglex;
    let y = toggley;

    const geomUtils = new GeomUtils();
    if (toVal === '4326_dd') {
      if (this.fromCoordSys === '4326_dms') {
        x = geomUtils.parseDMS(togglex);
        y = geomUtils.parseDMS(toggley);
        this.xvalue.setValue(x);
        this.yvalue.setValue(y);
      }
      if (
        this.fromCoordSys === this.mapLayer.spatialReference.wkid.toString()
      ) {
        this.projectCoordinates(
          x,
          y,
          '4326',
          this.fromCoordSys,
          (D: number, lng: boolean) => {
            this.xvalue.setValue(D);
          },
          (D: number, lng: boolean) => {
            this.yvalue.setValue(D);
          }
        );
      }
    }

    if (toVal === '4326_dms') {
      if (this.fromCoordSys === '4326_dd') {
        x = geomUtils.ConvertDDToDMS(x, true).toString();
        y = geomUtils.ConvertDDToDMS(y, false).toString();

        this.xvalue.setValue(x);
        this.yvalue.setValue(y);
      }
      if (
        this.fromCoordSys === this.mapLayer.spatialReference.wkid.toString()
      ) {
        // then project it to state Plane.
        this.projectCoordinates(
          x,
          y,
          '4326',
          this.fromCoordSys,
          (D: number, lng: boolean) => {
            this.xvalue.setValue(geomUtils.ConvertDDToDMS(D, lng).toString());
          },
          (D: number, lng: boolean) => {
            this.yvalue.setValue(geomUtils.ConvertDDToDMS(D, lng).toString());
          }
        );
      }
    }
    if (toVal === this.mapLayer.spatialReference.wkid.toString()) {
      if (this.fromCoordSys === '4326_dd') {
        x = this.xvalue.value;
        y = this.yvalue.value;
        this.projectCoordinates(
          x,
          y,
          toVal,
          '4326',
          (D: number, lng: boolean) => {
            this.xvalue.setValue(D);
          },
          (D: number, lng: boolean) => {
            this.yvalue.setValue(D);
          }
        );
      }

      if (this.fromCoordSys === '4326_dms') {
        x = geomUtils.parseDMS(togglex);
        y = geomUtils.parseDMS(toggley);
        this.projectCoordinates(
          x,
          y,
          toVal,
          '4326',
          (D: number, lng: boolean) => {
            this.xvalue.setValue(D);
          },
          (D: number, lng: boolean) => {
            this.yvalue.setValue(D);
          }
        );
      }
    }
  }

  // #region XYCoordinate support
  configureAddByXYCoordinate_OnChangeEvent() {
    // (change)='onCordinateSystemChange($event)'
    const me = this;
    this.sub = this.addCoordinatesForm
      .get('ddlXyCoordSys')
      .valueChanges.subscribe(toVal => {
        const srid = this.ddlXyCoordSys.value;
        const x = this.xvalue.value;
        const y = this.yvalue.value;
        this.toggleXYValue(x, y, toVal);
        this.fromCoordSys = toVal;
      });
  }

  projectCoordinates(
    x: number,
    y: number,
    toCoordinateSystem: string,
    xyCoordianateSystem: string,
    xValueFunction: (x: number, lng: boolean) => void = coord => {},
    yValueFunction: (x: number, lng: boolean) => void = coord => {}
  ) {
    const geomUtils = new GeomUtils();

    geomUtils.getGeoPoint(x, y, xyCoordianateSystem).then(g => {
      this.fixGraphic(
        g,
        this.pointSymbol,
        this.polylineSymbol,
        this.polygonSymbol
      );

      const gWM = g.clone();

      geomUtils
        .ObservableProjectedGraphicPromise(gWM, toCoordinateSystem)
        .then(sequence => {
          sequence.subscribe(val => {
            const gt = this.Graphic;
            const graphicValue = val as typeof gt;
            xValueFunction(graphicValue.geometry['x'], true);
            yValueFunction(graphicValue.geometry['y'], false);
          });
        });
    });
  }
  async isInFeatureExtentBounds(pnt) {
    const pExtent = await this.getFeatureLayerFullExtent();
    if (pnt.geometry['x'] < pExtent.xmin || pnt.geometry['x'] > pExtent.xmax) {
      return false;
    }
    if (pnt.geometry['y'] < pExtent.ymin || pnt.geometry['y'] > pExtent.ymax) {
      return false;
    }
    return true;
  }

  validBounds(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = this.validCoordinate;
      const result = !valid
        ? { invalidBounds: { value: control.value } }
        : null;
      return result;
    };
  }

  validInput(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let valid = true;
      const coordinate = control.value;
      coordinate
        .toString()
        .split(' ')
        .forEach(x => {
          if (isNaN(x)) {
            valid = false;
          }
        });
      const result = !valid
        ? { invalidCoordinate: { value: control.value } }
        : null;
      return result;
    };
  }

  async saveXYCoordinates() {
    const [
      Graphic,
      Extent,
      SpatialReference,
      webMercatorUtils
    ] = await this.esriLoaderService.LoadModules([
      'esri/Graphic',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
      'esri/geometry/support/webMercatorUtils'
    ]);
    // validate.
    // if x and y aren't valid, then cancel the processing and let the user know, otherwise continue the processing and cancel the dialog when we are done.
    if (this.addCoordinatesForm.valid) {
      if (this.useSketchSingleItemMode) {
        this.clearSketchLayer();
      }

      // select coordinate system
      let srid = this.ddlXyCoordSys.value;
      let x;
      let y;
      const geomUtils = new GeomUtils();
      if (srid === '4326_dms') {
        // DD or DMS
        x = geomUtils.ToggleDMS_DD(this.xvalue.value);
        y = geomUtils.ToggleDMS_DD(this.yvalue.value);
        srid = '4326';
      }

      if (srid === '4326_dd') {
        x = parseFloat(this.xvalue.value);
        y = parseFloat(this.yvalue.value);
        srid = '4326';
      }

      if (srid === this.mapLayer.spatialReference.wkid.toString()) {
        x = parseFloat(this.xvalue.value);
        y = parseFloat(this.yvalue.value);
      }
      const g = await geomUtils.getGeoPoint(x, y, srid);
      this.fixGraphic(
        g,
        this.pointCyanCircleSymbol,
        this.polylineSymbol,
        this.polygonSymbol
      );

      const gWM = g.clone();
      geomUtils
        .ObservableProjectedGraphicPromise(gWM, '102100')
        .then(observableProjectedGraphic => {
          observableProjectedGraphic.subscribe(projectedGraphic => {
            this.isInFeatureExtentBounds(projectedGraphic).then(isInbounds => {
              this.validCoordinate = isInbounds;
              if (this.validCoordinate) {
                const gt = this.Graphic;
                this.setAllSketchItemsToDefault();
                this.sketchLayer.add(projectedGraphic as typeof gt);
                this.notifySketchChanged();
                this.resetCoordinateSystemDropdown();

                // zoom to the sketchLayer??
                this.mapView.goTo(this.sketchLayer.graphics).then(layerView => {
                  const ext = this.mapView.extent;
                  ext.expand(1.2);
                  this.mapView.goTo(ext);
                });

                this.addCoordinatesModal.cancel();
              } else {
                this.addCoordinatesModal.cancelProcessing();
              }
            });
          });
        });
    } else {
      // the x y valid must not have been valid... notify the user and cancel the processing, BUT NOT the modal.
      this.addCoordinatesModal.cancelProcessing();
    }
  }

  setAllSketchItemsToDefault() {
    if (this.sketchLayer) {
      this.sketchLayer.graphics.forEach(v => {
        this.fixGraphic(
          v,
          this.pointSymbol,
          this.polylineSymbol,
          this.polygonSymbol
        );
      });
    }
  }

  resetCoordinateSystemDropdown() {
    this.ddlXyCoordSys.setValue('4326_dd');
    this.fromCoordSys = '4326_dd';
  }

  toggleDisplayCoordinateSystem() {
    this.selectedDisplayCoordinateSystem =
      this.selectedDisplayCoordinateSystem < this.coordinateSystems.length - 1
        ? (this.selectedDisplayCoordinateSystem += 1)
        : 0;
    this.displayXYCoordsTitle = this.coordinateSystems[
      this.selectedDisplayCoordinateSystem
    ].text;
    // I need to project the current mapDisplayX and mapDisplayY
    const toVal = this.coordinateSystems[this.selectedDisplayCoordinateSystem]
      .value;
    const point = this.mapView.toMap({
      x: Number(this.mapDisplayX),
      y: Number(this.mapDisplayY)
    });
    this.toggleCoordinateDisplay(point);
  }

  setupCoordinateSystemOptions() {
    // commented because we haven't decided if we are going to support projected systems yet.
    // the sticking point is that we don't have a relevant way of Labeling them.
    // this.coordinateSystems.push({
    //   value: this.mapLayer.spatialReference.wkid,
    //   text: 'State Plane'
    // });

    this.coordinateSystems.push({
      value: '4326_dd',
      text: 'Lon/Lat (dd.dddddd)'
    });
    this.coordinateSystems.push({
      value: '4326_dms',
      text: 'Lon/Lat (dd mm ss)'
    });
    this.displayXYCoordsTitle = this.coordinateSystems[
      this.selectedDisplayCoordinateSystem
    ].text;
  }

  setupAddByXYCoordinateForm() {
    this.addCoordinatesForm = new UntypedFormGroup({
      xvalue: this.xvalue,
      yvalue: this.yvalue,
      ddlXyCoordSys: this.ddlXyCoordSys
    });
  }

  setActiveButton(button: string) {
    this.activeButton = button;
  }

  async getCurrentMapExtentWGS84() {
    const [webMercatorUtils] = await this.esriLoaderService.LoadModules([
      'esri/geometry/support/webMercatorUtils'
    ]);

    if (!this.mapView.extent) {
      throw new Error('No map extent has been defined');
    }

    const wgs84Ext = webMercatorUtils.webMercatorToGeographic(
      this.mapView.extent
    );
    return new MapExtent(
      wgs84Ext.xmin,
      wgs84Ext.ymin,
      wgs84Ext.xmax,
      wgs84Ext.ymax
    );
  }

  updateSublayers() {
    if (!this.mapLayer) {
      return;
    }
    if (!this.mapLayerOptions) {
      return;
    }

    this.mapLayerOptions.forEach((layer, idx) => {
      const mapSubLayer = this.mapLayer.findSublayerById(layer.id);
      if (mapSubLayer) {
        mapSubLayer.visible = layer.visible;
      }
    });
  }

  getMapServiceEndpointUrl(): string {
    const apiUrl = Utilities.getRootURL();
    if (this.mapServiceId) {
      return `${apiUrl}/api/mapproxy/mapserver/${this.mapServiceId}`;
    } else {
      return null;
    }
  }

  zoom(ext) {
    this.mapView.goTo(ext);
  }

  async zoomToInitialExtent() {
    const [
      SpatialReference,
      Extent
    ] = await this.esriLoaderService.LoadModules([
      'esri/geometry/SpatialReference',
      'esri/geometry/Extent'
    ]);

    if (this.initialExtent) {
      const initExt = new Extent(
        this.initialExtent.xmin,
        this.initialExtent.ymin,
        this.initialExtent.xmax,
        this.initialExtent.ymax
      );
      initExt.spatialReference = SpatialReference.WGS84;
      this.mapView.goTo(initExt);
    } else {
      await this.zoomToFeatureLayerFullExtent();
    }
  }

  async zoomToFeatureLayerFullExtent() {
    const [
      SpatialReference,
      projection
    ] = await this.esriLoaderService.LoadModules([
      'esri/geometry/SpatialReference',
      'esri/geometry/projection'
    ]);

    // need to reproject it...  load the magic esri projector sauce.  It uses Web Assemblies - so if you are here to add IE support, you'll be sad.
    if (!projection.isLoaded()) {
      await projection.load();
    }
    const wmExt: esri.Extent = projection.project(
      this.mapLayer.fullExtent,
      SpatialReference.WebMercator
    );
    wmExt.expand(1.2);
    this.mapView.goTo(wmExt);
  }

  async getFeatureLayerFullExtent() {
    const [
      SpatialReference,
      projection
    ] = await this.esriLoaderService.LoadModules([
      'esri/geometry/SpatialReference',
      'esri/geometry/projection'
    ]);

    // need to reproject it...  load the magic esri projector sauce.  It uses Web Assemblies - so if you are here to add IE support, you'll be sad.
    if (!projection.isLoaded()) {
      await projection.load();
    }
    const wmExt: esri.Extent = projection.project(
      this.mapLayer.fullExtent,
      SpatialReference.WebMercator
    );
    wmExt.expand(1.2);
    return wmExt;
  }

  initToolbar() {
    if (this.toolbarMode === 'none') {
    } else if (this.toolbarMode === 'sketch') {
      this.toolbarVisible = true; // this prevents the tools from appearing in various spots on the map while it loads
      this.mapView.ui.add(this.toolbarItems.nativeElement, 'top-left');
    } else {
    }
  }
  // #endregion

  // #region Selection Layer
  private selectionLayer: esri.GraphicsLayer;

  // blue outline
  private selectionSymbol_ItemSearched = {
    type: 'simple-fill',
    color: [226, 119, 40],
    style: 'none',
    outline: {
      color: '#00ffff',
      width: 3
    }
  };

  // red outline
  private selectionSymbol_ItemSelected = {
    type: 'simple-fill',
    color: [226, 119, 40],
    style: 'none',
    outline: {
      color: '#ff0000',
      width: 5
    }
  };

  async enableFeatureSelection(
    layerName: string,
    fieldName: string,
    readOnlyMode: boolean,
    whereField?: string
  ) {
    // don't call this until the map is fully loaded and has fired the onMapLoaded event
    if (!this.map || !this.map.initialized) {
      console.error('Map is not initialized, can not load feature layer');
      return;
    }

    this.featureFieldName = fieldName;
    this.featureLayerName = layerName;

    const layer = this.mapLayer.allSublayers.find(lyr => {
      return lyr.title === this.featureLayerName;
    });

    if (layer) {
      layer.visible = true;
    }

    this.whereField = whereField;

    const [GraphicsLayer] = await this.esriLoaderService.LoadModules([
      'esri/layers/GraphicsLayer'
    ]);

    this.selectionLayer = new GraphicsLayer({
      listMode: 'hide'
    });

    this.map.add(this.selectionLayer);

    if (!readOnlyMode) {
      this.mapView.on('click', async event => {
        this.onMapClick(event.mapPoint);
      });
    }
  }

  async onMapClick(mapPoint: esri.Point) {
    const [Query, QueryTask] = await this.esriLoaderService.LoadModules([
      'esri/tasks/support/Query',
      'esri/tasks/QueryTask'
    ]);
    this.setAllSketchItemsToDefault();
    // dig up the layer id of the feature layer

    const featureLayer = this.mapLayer.allSublayers.find(lyr => {
      return lyr.title === this.featureLayerName;
    });

    if (featureLayer) {
      const query = new Query({
        returnGeometry: true,
        outFields: '*',
        geometry: mapPoint,
        units: 'meters',
        distance: 3 * this.mapView.resolution // this isn't supported in ags 10.3 (you'll just get the click point, no buffer)
      });

      // start by clearing old features
      if (this.multiSelect === false) {
        this.graphicsDictionary_cache = {};
        this.selectionLayer.graphics.removeAll();
      }
      this.featuresBeforeIdentifying.emit();

      const queryTask = new QueryTask({
        url: featureLayer.url
      });
      const response = await queryTask.execute(query);

      // this isn't a mistake, if there are more than one feature selected on the map abort... don't do anything!
      if (response.features.length === 1) {
        const featuresNotInMap = this.toggleColorOrAddfeatures(
          response.features,
          null
        );

        const featureIds = response.features.map(o => {
          return o.attributes[this.featureFieldName];
        });
        this.featuresIdentified.emit(featureIds);
      } else {
        // if I selected multiple parcels then alert the parent component that nothing was selected...
        this.featuresIdentified.emit(null);
      }
      const featureIds = response.features.map(o => {
        return o.attributes[this.featureFieldName];
      });
      this.featuresIdentified.emit(featureIds);
    }
  }

  async webMercatorToWkt(wmg: any): Promise<string> {
    const [webMercatorUtils] = await this.esriLoaderService.LoadModules([
      'esri/geometry/support/webMercatorUtils'
    ]);

    const geom = webMercatorUtils.webMercatorToGeographic(wmg);

    return GeomUtils.ArcGisGeomToWkt(geom);
  }

  async getFeatureGeometry(
    layerName: string,
    fieldName: string,
    featureIds: string[],
    whereField?: string
  ) {
    const [Query, QueryTask] = await this.esriLoaderService.LoadModules([
      'esri/tasks/support/Query',
      'esri/tasks/QueryTask'
    ]);

    // dig up the layer id of the feature layer
    const featureLayer = this.mapLayer.allSublayers.find(lyr => {
      return lyr.title === layerName;
    });

    featureLayer.visible = true;

    // fix quote characters, glue list together for use in a query
    const fidQuotedList = featureIds
      ? (this.featureKeyAsString ? '\'' : '') +
        featureIds
          .map((v, i) => {
            return this.featureKeyAsString ? v.replace('\'', '\'\'') : v;
          })
          .join(this.featureKeyAsString ? '\',\'' : ',') +
        (this.featureKeyAsString ? '\'' : '')
      : '';

    const query = new Query({
      outSpatialReference: this.mapView.spatialReference,
      returnGeometry: true,
      outFields: fieldName,
      where: `${whereField || fieldName} IN (${fidQuotedList})`
    });

    const queryTask = new QueryTask({
      url: featureLayer.url
    });

    const response = await queryTask.execute(query);
    // HINT: if the querytask has an error, sometimes it appears as "Uncaught (in promise): Cancel Error" with nothing helpful to debug.

    // dig out features and add symbology
    const useSymbol =
      response.features.length === 1
        ? this.selectionSymbol_ItemSelected
        : this.selectionSymbol_ItemSearched;
    response.features.forEach(o => {
      o.symbol = useSymbol;
    });

    return response.features;
  }

  toggleColorOrAddfeatures(
    features: any[],
    layercolor: 'redlayer' | 'bluelayer'
  ) {
    // only add it if it is not already there.
    const categorizeFeatures = this.categorizeFeatures(features);
    if (categorizeFeatures.featuresNotInMap.length > 0) {
      this.selectionLayer.graphics.addMany(categorizeFeatures.featuresNotInMap);
      categorizeFeatures.featuresNotInMap.forEach(f => {
        let useSymbol = null;
        if (f.geometry.type === 'point') {
          useSymbol = this.pointCyanCircleSymbol;
        } else {
          if (layercolor === 'redlayer') {
            useSymbol = this.selectionSymbol_ItemSelected;
          } else if (layercolor === 'bluelayer') {
            useSymbol = this.selectionSymbol_ItemSearched;
          } else {
            useSymbol = this.selectionSymbol_ItemSelected;
          }
        }
        f.symbol = useSymbol;

        this.graphicsDictionary_cache[f.attributes[this.featureFieldName]] = f;
      });
    }
    if (categorizeFeatures.featuresAlreadyInMap.length > 0) {
      categorizeFeatures.featuresAlreadyInMap.forEach(g => {
        if (g.symbol['outline'] !== undefined) {
          const c = g.symbol['outline'].color;
          const testarr: number[] = [c['r'], c['g'], c['b']];
          const redarray: number[] = [255, 0, 0];
          const testcolor = testarr;
          const arrayEquals = function(a, b) {
            return (
              Array.isArray(a) &&
              Array.isArray(b) &&
              a.length === b.length &&
              a.every((val, index) => val === b[index])
            );
          };

          const existingLayercolor: 'redlayer' | 'bluelayer' = arrayEquals(
            testcolor,
            redarray
          )
            ? 'redlayer'
            : 'bluelayer';
          this.toggleFromGraphicColor(g, existingLayercolor);
        }
      });
    }
    return categorizeFeatures.featuresNotInMap;
  }

  // this is changing the selected feature color if it's already a selected feature, but if it doesn't change any colors then it returns false so that we can then add it to the map.
  categorizeFeatures(features: any[]) {
    const featuresAlreadyInMap = [];
    const featuresNotInMap = [];

    features.forEach((f, fi) => {
      // one selected feature might have already been added from the searchresults, the other might be from a previous search so it isn't in the most recent search results.
      const g = this.graphicsDictionary_cache[
        f.attributes[this.featureFieldName]
      ];
      if (g) {
        featuresAlreadyInMap.push(g);
      } else {
        featuresNotInMap.push(f);
      }
    });
    return {
      featuresAlreadyInMap: featuresAlreadyInMap,
      featuresNotInMap: featuresNotInMap
    };
  }

  toggleToGraphicColor(g: Graphic, layercolor: 'redlayer' | 'bluelayer') {
    const newLayercolor = layercolor === 'redlayer' ? 'bluelayer' : 'redlayer';
    this.toggleFromGraphicColor(g, newLayercolor);
  }
  toggleFromGraphicColor(g: Graphic, layercolor: 'redlayer' | 'bluelayer') {
    let selectionSymbol = this.selectionSymbol_ItemSearched;
    if (layercolor === 'redlayer') {
      // it is red, so make it blue
      selectionSymbol = this.selectionSymbol_ItemSearched;
    } else {
      // it is blue, so make it read
      selectionSymbol = this.selectionSymbol_ItemSelected;
    }
    this.fixGraphic(g, selectionSymbol, selectionSymbol, selectionSymbol);
  }
  async selectFeaturesOnLayer(
    layerName: string,
    featureIds: string[],
    layercolor: 'redlayer' | 'bluelayer'
  ) {
    const features = await this.getFeatureGeometry(
      layerName,
      this.featureFieldName,
      featureIds
    );

    // start by clearing old features
    if (this.selectionLayer && this.multiSelect === false) {
      this.selectionLayer.graphics.removeAll();
    }

    const selectionSymbol = null;

    if (this.selectionLayer) {
      if (this.multiSelect === false) {
        this.selectionLayer.graphics.removeAll(); // do this again, as multiple async requests may arrive, we only want the last batch of features
      }

      this.toggleColorOrAddfeatures(features, layercolor);
    }
  }

  async selectFeaturesOnMap(
    featureIds: string[],
    layercolor: 'redlayer' | 'bluelayer' = 'bluelayer'
  ) {
    if (featureIds.length > 0) {
      await this.selectFeaturesOnLayers([
        { layerName: this.featureLayerName, featureIds, layercolor }
      ]);

      await this.zoomToSelection();
    }
  }
  async selectFeaturesOnLayers(
    selectedLayerFeatures: {
      layerName: string;
      featureIds: string[];
      layercolor: 'redlayer' | 'bluelayer';
    }[]
  ) {
    // remove all selection to start with
    if (this.selectionLayer && this.multiSelect === false) {
      this.graphicsDictionary_cache = {};
      this.selectionLayer.graphics.removeAll();
    }

    if (selectedLayerFeatures.length === 0) {
      // shortcut - skip the query if we have no input
      return;
    }

    for (const layerFeatures of selectedLayerFeatures) {
      await this.selectFeaturesOnLayer(
        layerFeatures.layerName,
        layerFeatures.featureIds,
        layerFeatures.layercolor
      );
    }
  }

  async zoomToSelection(): Promise<any> {
    if (this.selectionLayer.graphics.length === 0) {
      return;
    }
    const [
      Extent,
      SpatialReference
    ] = await this.esriLoaderService.LoadModules([
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference'
    ]);

    const geo1 = this.selectionLayer.graphics.getItemAt(0)
      .geometry as esri.Point;
    let ext1 = this.selectionLayer.graphics.getItemAt(0).geometry.extent
      ? this.selectionLayer.graphics.getItemAt(0).geometry.extent.clone()
      : null;

    if (ext1) {
      for (let i = 1; i < this.selectionLayer.graphics.length; i++) {
        if (this.selectionLayer.graphics.getItemAt(i).geometry.extent != null) {
          ext1 = ext1.union(
            this.selectionLayer.graphics.getItemAt(i).geometry.extent
          );
        }
      }
      ext1.expand(2);
      return this.mapView.goTo(ext1);
    } else {
      // dig up the layer id of the feature layer
      const featureLayer = this.mapLayer.allSublayers.find(lyr => {
        return lyr.title === this.featureLayerName;
      });

      const ext = new Extent({
        xmax: geo1.x + 1,
        xmin: geo1.x - 1,
        ymax: geo1.y + 1,
        ymin: geo1.y - 1,
        spatialReference: this.mapView.spatialReference
      });

      ext.expand(10);
      return this.mapView.goTo(ext);
    }
  }

  //#endregion

  //#region sketch environment

  private sketchLayer: esri.GraphicsLayer = null;
  public sketchViewModel: esri.SketchViewModel = null;
  // private editGraphic: esri.Graphic = null;
  private sketchUseDimensions = false;
  private referenceLayer: esri.GraphicsLayer = null;

  @Output()
  mapSketchDataChanged = new EventEmitter<MapsketchData>();

  private pointSymbol = {
    type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
    style: 'x',
    color: '#00ffff',
    size: '16px',
    outline: {
      // autocasts as new SimpleLineSymbol()
      color: '#00ffff',
      width: 2
    }
  };
  private pointCyanCircleSymbol = {
    type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
    style: 'circle',
    color: '#00ffff',
    size: '8px',
    outline: {
      // autocasts as new SimpleLineSymbol()
      color: '#00ffff',
      width: 2
    }
  };

  private textSymbol = {
    type: 'text', // autocasts as new TextSymbol()
    color: 'white',
    haloColor: 'black',
    haloSize: '1px',
    text: '',
    xoffset: 3,
    yoffset: 3,
    font: {
      // autocast as new Font()
      size: 12,
      family: 'sans-serif',
      weight: 'bold'
    }
  };

  private polylineSymbol = {
    type: 'simple-line', // autocasts as new SimpleLineSymbol()
    color: '#00ffff',
    width: '2',
    style: 'solid'
  };

  private polygonSymbol = {
    type: 'simple-fill', // autocasts as new SimpleFillSymbol()
    color: 'rgba(0,255,255, 0.3)',
    style: 'solid',
    outline: {
      color: '00ffff',
      width: 2
    }
  };

  private refPointSymbol = {
    type: 'simple-marker', // autocasts as new SimpleMarkerSymbol()
    style: 'x',
    color: '#ff0000',
    size: '16px',
    outline: {
      // autocasts as new SimpleLineSymbol()
      color: '#ff0000',
      width: 2
    }
  };

  private refPolylineSymbol = {
    type: 'simple-line', // autocasts as new SimpleLineSymbol()
    color: '#ff0000',
    width: '2',
    style: 'solid'
  };

  private refPolygonSymbol = {
    type: 'simple-fill', // autocasts as new SimpleFillSymbol()
    color: 'rgba(255,0,0, 0)',
    style: 'solid',
    outline: {
      color: '#ff0000',
      width: 2
    }
  };

  private buttonCommands = {
    xypoint: this.openPlaceByCoordinatesDialog.bind(this),
    freehand: this.startFreehandSketch.bind(this),
    polyline: this.startPolylineSketch.bind(this),
    rect: this.startRectangleSketch.bind(this),
    polygon: this.startPolygonSketch.bind(this),
    point: this.startPointSketch.bind(this),
    text: this.startTextSketch.bind(this),
    measure: this.startMeasureSketch.bind(this)
  };

  private async initReferenceLayer() {
    if (this.referenceLayer) {
      return;
    }

    const [GraphicsLayer] = await this.esriLoaderService.LoadModules([
      'esri/layers/GraphicsLayer'
    ]);

    this.referenceLayer = new GraphicsLayer({
      id: 'referenceLayer',
      title: 'Reference Layer'
    });
    this.map.layers.add(this.referenceLayer);
    await this.mapView.whenLayerView(this.referenceLayer);
  }

  private async initSketchEnv() {
    if (this.sketchLayer) {
      return;
    } // already initialized

    const [
      GraphicsLayer,
      SketchViewModel,
      Graphic
      // Extent,
      // SpatialReference,
      // projection,
    ] = await this.esriLoaderService.LoadModules([
      'esri/layers/GraphicsLayer',
      'esri/widgets/Sketch/SketchViewModel',
      'esri/Graphic'
      // "esri/geometry/Extent",
      // "esri/geometry/SpatialReference",
      // "esri/geometry/projection",
    ]);

    this.sketchLayer = new GraphicsLayer({
      id: 'sketchLayer',
      title: 'Sketch Layer'
    });

    this.map.layers.add(this.sketchLayer);

    await this.mapView.whenLayerView(this.sketchLayer);

    // we only want the editing tools to work when the toolbar is displayed
    if (this.toolbarMode === 'sketch') {
      this.sketchViewModel = new SketchViewModel({
        view: this.mapView,
        layer: this.sketchLayer,
        pointSymbol: this.pointSymbol,
        polylineSymbol: this.polylineSymbol,
        polygonSymbol: this.polygonSymbol
      });

      this.sketchViewModel.on('create', async event => {
        if (!event.graphic) {
          return;
        }

        if (this.activeButton === 'text') {
          this.addImage(event);
        }

        if (event.state === 'cancel') {
          this.clearDimensions(event.graphic);
        } else {
          this.updateDimensions(event.graphic);
        }
        if (event.state === 'complete' || event.state === 'cancel') {
          await this.notifySketchChanged();
          if (this.activeButton && !this.useSketchSingleItemMode) {
            this.buttonCommands[this.activeButton]();
          } else if (this.useSketchSingleItemMode) {
            this.setActiveButton(null);
          }
        }
      });

      this.sketchViewModel.on('update', async event => {
        this.updateDimensions(event.graphics[0]);
        if (event.state === 'complete') {
          await this.notifySketchChanged();
        }
      });

      this.sketchViewModel.on('redo', async event => {
        await this.notifySketchChanged();
      });

      this.sketchViewModel.on('undo', async event => {
        await this.notifySketchChanged();
      });
    }
  }

  async prePopulateCenterPoint() {
    const [webMercatorUtils] = await this.esriLoaderService.LoadModules([
      'esri/geometry/support/webMercatorUtils'
    ]);

    const centerPoint = webMercatorUtils.webMercatorToGeographic(
      this.mapView.center
    );

    this.xvalue.setValue(centerPoint.x);
    this.yvalue.setValue(centerPoint.y);
  }

  async openPlaceByCoordinatesDialog() {
    // get the center of the map to prepopulate tha value.
    await this.prePopulateCenterPoint();
    this.sketchUseDimensions = false;
    this.addCoordinatesModal.open();
  }

  resetTool() {
    this.setActiveButton(null);
    this.sketchViewModel.cancel();
  }

  startFreehandSketch() {
    this.setActiveButton('freehand');
    if (this.useSketchSingleItemMode) {
      this.clearSketchLayer();
    }
    this.sketchUseDimensions = false;
    this.sketchViewModel.create('polyline', { mode: 'freehand' });
  }

  startPolylineSketch() {
    this.setActiveButton('polyline');
    if (this.useSketchSingleItemMode) {
      this.clearSketchLayer();
    }
    this.sketchUseDimensions = false;
    this.sketchViewModel.create('polyline');
  }

  startRectangleSketch() {
    this.setActiveButton('rect');
    if (this.useSketchSingleItemMode) {
      this.clearSketchLayer();
    }
    this.sketchUseDimensions = false;
    this.sketchViewModel.create('rectangle');
  }

  startPolygonSketch() {
    this.setActiveButton('polygon');
    if (this.useSketchSingleItemMode) {
      this.clearSketchLayer();
    }
    this.sketchUseDimensions = false;
    this.sketchViewModel.create('polygon');
  }

  startPointSketch() {
    this.setActiveButton('point');
    if (this.useSketchSingleItemMode) {
      this.clearSketchLayer();
    }
    this.sketchUseDimensions = false;
    this.sketchViewModel.create('point');
  }

  addImage(e) {
    if (this.sketchLayer) {
      if (!e.graphic || !e.graphic.geometry || !e.graphic.geometry.x) {
        return;
      }

      let graphicSymbol;

      graphicSymbol = this.textSymbol;
      graphicSymbol.text = prompt('Enter Text:', '');

      const graphic = new this.Graphic({
        geometry: e.graphic.geometry,
        symbol: graphicSymbol
      });
      this.sketchLayer.add(graphic);
      this.sketchLayer.remove(e.graphic);
    }
  }

  startTextSketch(e) {
    this.setActiveButton('text');
    if (this.useSketchSingleItemMode) {
      this.clearSketchLayer();
    }
    this.sketchUseDimensions = false;
    this.sketchViewModel.create('point');
  }

  startMeasureSketch() {
    this.setActiveButton('measure');
    this.sketchUseDimensions = true;
    this.sketchViewModel.create('polyline', { mode: 'click' });
  }

  undoSketch() {
    // if in a sketch, do this:
    if (this.sketchLayer) {
      const isEditing = this.sketchViewModel.state === 'active';
      const sketchViewGraphicVertexCount = this.getSketchViewGraphicVertexCount();
      if (isEditing && sketchViewGraphicVertexCount > 1) {
        this.sketchViewModel.undo();
      } else {
        // otherwise out of a sketch, drop last item:
        const n = this.sketchLayer.graphics.length;
        if (n > 0) {
          this.sketchLayer.graphics.removeAt(n - 1);
        }
      }

      this.notifySketchChanged();
    }
  }

  clearSketchLayer() {
    if (this.sketchLayer) {
      this.sketchViewModel.cancel();
      this.sketchLayer.graphics.removeAll();
      this.notifySketchChanged();
    }
  }

  private async notifySketchChanged() {
    // const ext = await this.getCurrentMapExtentWGS84();
    // const sd = await this.getSketchData();
    // // HACK: this is here because the screenshot happens before the view is updated with the new graphics and I can't find anywhere in the docs/forums on what exactly to wait for.
    // setTimeout(async () => {
    //   const url = await this.getScreenshotURL();
    //   this.mapSketchDataChanged.emit({
    //     graphics: sd,
    //     extent: ext,
    //     imageURL: url
    //   });
    // }, 100);
  }

  private async updateDimensions(g: esri.Graphic) {
    if (!g) {
      return;
    }

    // this should only happen on new features - and should pick up the current setting regarding dimension support
    if (g.getAttribute('dim') == null) {
      g.setAttribute('dim', this.sketchUseDimensions);
    }

    // bail out if we don't want dimensions
    if (g.getAttribute('dim') === false) {
      return;
    }

    const [
      Graphic,
      Polygon,
      Polyline,
      geometryEngine,
      unitFormatUtils
    ] = await this.esriLoaderService.LoadModules([
      'esri/Graphic',
      'esri/geometry/Polygon',
      'esri/geometry/Polyline',
      'esri/geometry/geometryEngine',
      'esri/core/unitFormatUtils'
    ]);

    await this.clearDimensions(g);

    let path: number[][] = null;

    if (g.geometry.type === 'polygon') {
      path = (g.geometry as esri.geometry.Polygon).rings[0];
    }

    if (g.geometry.type === 'polyline') {
      path = (g.geometry as esri.geometry.Polyline).paths[0];
    }

    if (path) {
      for (let i = 0; i < path.length - 1; i++) {
        const p1 = path[i];
        const p2 = path[i + 1];

        const centerPt = {
          x: (p1[0] + p2[0]) / 2.0,
          y: (p1[1] + p2[1]) / 2.0,
          type: 'point',
          spatialReference: g.geometry.spatialReference
        };

        const leg = {
          paths: [[p1, p2]],
          type: 'polyline',
          spatialReference: g.geometry.spatialReference
        };

        const mLength = geometryEngine.geodesicLength(leg, 9003); // note: never use planar length with Web Mercator
        // 9003 == us foot from https://developers.arcgis.com/java/10-2/api-reference/constant-values.html#com.esri.core.geometry.LinearUnit.Code.CENTIMETER
        let sLength: string;

        sLength = `${numeral(mLength).format('0,0[.][00]')} ft`;

        const lbl: esri.Graphic = new Graphic({
          geometry: centerPt,
          symbol: {
            type: 'text',
            color: [255, 255, 255, 1],
            haloColor: [0, 0, 0, 0.5],
            haloSize: 1.5,
            text: sLength,
            font: {
              size: 10,
              family: 'sans-serif'
            }
          }
        });

        if (!g.getAttribute('id')) {
          g.setAttribute('id', new Date().valueOf());
        }
        lbl.setAttribute('parent', g.getAttribute('id'));

        this.sketchLayer.add(lbl);

        // if we are sufficiently closed, then add the area label:
        // todo: need to calculate closure?
      }

      let isClosed = false; // geomtype=polyline, verts>=3, 1st and last pt "close" to each other
      if (path.length >= 3) {
        const closureLine = new Polyline({
          paths: [path[0], path[path.length - 1]],
          spatialReference: g.geometry.spatialReference
        });
        const closureDist = geometryEngine.geodesicLength(
          closureLine,
          'meters'
        );
        const ext = g.geometry.extent;
        const maxDim = Math.max(ext.width, ext.height);
        const closureRatio = closureDist / maxDim;
        isClosed = closureRatio < 0.05;
      }

      if (isClosed) {
        // make a closed path
        const closedPath: number[][] = [];
        path.forEach(pt => closedPath.push(pt));
        closedPath.push(path[0]); // close the polyline

        const poly: esri.geometry.Polygon = new Polygon({
          rings: [closedPath],
          spatialReference: g.geometry.spatialReference
        });

        const mArea = geometryEngine.geodesicArea(poly, 109406); // note: never use planar area with Web Mercator
        // 109406 is SQUARE_FOOT_US
        const sArea = `${numeral(Math.abs(mArea)).format('0,0')} ft²`;

        const labelPt = poly.centroid;

        const lblArea: esri.Graphic = new Graphic({
          geometry: labelPt,
          symbol: {
            type: 'text',
            color: [255, 255, 255, 1],
            haloColor: [0, 0, 0, 0.5],
            haloSize: 2,
            text: sArea,
            font: {
              size: 14,
              family: 'sans-serif'
            }
          }
        });

        lblArea.setAttribute('parent', g.getAttribute('id'));
        this.sketchLayer.add(lblArea);
      }
    }
  }

  private getSketchViewGraphicVertexCount() {
    let count = 0;
    if (
      this.sketchViewModel.createGraphic &&
      this.sketchViewModel.createGraphic.geometry['paths']
    ) {
      count += this.sketchViewModel.createGraphic.geometry['paths'].reduce(
        function(currentCount, row) {
          return currentCount + row.length;
        },
        0
      );
    }
    if (
      this.sketchViewModel.createGraphic &&
      this.sketchViewModel.createGraphic.geometry['rings']
    ) {
      count += this.sketchViewModel.createGraphic.geometry['rings'].reduce(
        function(currentCount, row) {
          return currentCount + row.length;
        },
        0
      );
    }
    return count;
  }

  private async clearDimensions(g: esri.Graphic) {
    const toDelete: esri.Graphic[] = [];
    const id = g.getAttribute('id');
    if (!id) {
      return;
    }
    if (this.sketchLayer) {
      this.sketchLayer.graphics.forEach(s => {
        if (s.getAttribute('parent') === id) {
          toDelete.push(s);
        }
      });
      this.sketchLayer.removeMany(toDelete);
    }
  }

  async getSketchData(): Promise<any[]> {
    // store all geometry state in WGS84 lat/long for maximum portability if we use alternate map projections in the future

    const [
      Graphic,
      Extent,
      SpatialReference,
      webMercatorUtils
    ] = await this.esriLoaderService.LoadModules([
      'esri/Graphic',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
      'esri/geometry/support/webMercatorUtils'
    ]);

    const graphicsState = [];

    if (this.sketchLayer) {
      this.sketchLayer.graphics.forEach(graphic => {
        // reproject graphic geometry to WGS84
        const graphic84 = graphic.clone();
        graphic84.geometry = webMercatorUtils.webMercatorToGeographic(
          graphic.geometry
        );

        graphicsState.push(graphic84.toJSON());
      });
    }
    // const mapExt = await this.getCurrentMapExtentWGS84();

    // const g: MapsketchData = {
    //   extent: mapExt,
    //   graphics: graphicsState
    // };

    // return g;

    return graphicsState;
  }

  async getExtentOfGraphicCollection(
    extentGraphics: esri.Collection<esri.Graphic>
  ) {
    const [
      Graphic,
      Extent,
      SpatialReference,
      webMercatorUtils
    ] = await this.esriLoaderService.LoadModules([
      'esri/Graphic',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
      'esri/geometry/support/webMercatorUtils'
    ]);
    // zoom to the items in the graphics layer
    let finalExtent = null;
    for (let i = 0; i < extentGraphics.length; i++) {
      const g = extentGraphics.getItemAt(i).geometry;

      // if the graphic has an extent then get it, otherwise return null; i.e. points do not have extents.
      const checkExtent = g.extent ? g.extent.clone() : null;

      if (checkExtent) {
        finalExtent = finalExtent ? finalExtent : checkExtent;
        finalExtent = finalExtent.union(checkExtent);
      } else {
        const geo1 = extentGraphics.getItemAt(i).geometry as esri.Point;
        const gExtent = new Extent({
          xmax: geo1.x + 1,
          xmin: geo1.x - 1,
          ymax: geo1.y + 1,
          ymin: geo1.y - 1,
          spatialReference: this.mapView.spatialReference
        });
        finalExtent = finalExtent ? finalExtent : gExtent;
        finalExtent = finalExtent.union(gExtent);
      }
    }

    return finalExtent;
  }

  async setSketchData(
    graphicsState: any[],
    extent: MapExtent,
    referenceGeomWKT: string[]
  ) {
    const [
      Graphic,
      Extent,
      SpatialReference,
      webMercatorUtils
    ] = await this.esriLoaderService.LoadModules([
      'esri/Graphic',
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference',
      'esri/geometry/support/webMercatorUtils'
    ]);

    if (this.sketchLayer) {
      this.sketchLayer.graphics.removeAll();
    }
    if (this.referenceLayer) {
      this.referenceLayer.graphics.removeAll();
    }
    if (graphicsState && this.sketchLayer) {
      for (let i = 0; i < graphicsState.length; i++) {
        // loaded as WGS84, the map knows what to do with it:
        const g = Graphic.fromJSON(graphicsState[i]);
        this.fixGraphic(
          g,
          this.pointSymbol,
          this.polylineSymbol,
          this.polygonSymbol
        );
        const gWM = g.clone();
        gWM.geometry = webMercatorUtils.geographicToWebMercator(gWM.geometry);
        this.sketchLayer.add(gWM);
      }
    }

    let referenceGeom: esri.Geometry = null;

    if (referenceGeomWKT) {
      for (let idx = 0; idx < referenceGeomWKT.length; idx++) {
        const wkt = referenceGeomWKT[idx];

        const geom1 = GeomUtils.WktToArcGisGeom(wkt);
        referenceGeom = webMercatorUtils.geographicToWebMercator(
          geom1
        ) as esri.Geometry;

        const refG = new Graphic({
          geometry: referenceGeom
        });

        this.fixGraphic(
          refG,
          this.refPointSymbol,
          this.refPolylineSymbol,
          this.refPolygonSymbol
        );
        this.referenceLayer.add(refG);
      }
    }

    if (extent) {
      const initExt = new Extent(
        extent.xmin,
        extent.ymin,
        extent.xmax,
        extent.ymax
      );
      initExt.spatialReference = SpatialReference.WGS84;
      await this.mapView.goTo(initExt);
    } else {
      let graphicsExtent = null;

      if (
        this.sketchLayer &&
        this.sketchLayer.graphics &&
        this.sketchLayer.graphics.length > 0
      ) {
        // zoom to the items in the graphics layer
        const sketchExtent = await this.getExtentOfGraphicCollection(
          this.sketchLayer.graphics
        );
        graphicsExtent = sketchExtent;
      } else if (
        this.referenceLayer &&
        this.referenceLayer.graphics &&
        this.referenceLayer.graphics.length > 0
      ) {
        const referenceExtent = await this.getExtentOfGraphicCollection(
          this.referenceLayer.graphics
        );
        graphicsExtent = graphicsExtent
          ? graphicsExtent.union(referenceExtent)
          : referenceExtent;
      }

      if (graphicsExtent) {
        graphicsExtent.expand(2);
        this.mapView.goTo(graphicsExtent);
      }
    }
  }

  private fixRings(rings: number[][][]) {
    let fixed = false;
    if (rings) {
      let vertextCount = 0;
      let ringCount = rings.length;
      rings.forEach(r => {
        ringCount += 1;
        vertextCount = r.length;
        if (vertextCount <= 1) {
          const v1 = r[0].slice();
          const v2 = r[0].slice();
          v1[0] += 0.001;
          v2[1] += 0.001;
          r[1] = v1;
          r[2] = v2;
          r[3] = r[0];
          fixed = true;
        }
        vertextCount = 0;
      });
    }
    return fixed;
  }

  private fixGraphic(
    g: any,
    pointSymbol: any,
    polylineSymbol: any,
    polygonSymbol: any
  ) {
    const fixed = this.fixRings(g.geometry.rings);
    switch (g.geometry.type) {
      case 'point':
      case 'multipoint':
        // text has a geomtry of point so don't reset the symbol if it is text.
        if (!g.symbol || g.symbol.type !== 'text') {
          g.symbol = pointSymbol;
        }
        break;

      case 'polyline':
        g.symbol = polylineSymbol;
        break;

      case 'polygon':
        g.symbol = polygonSymbol;
        break;
    }

    if (g.symbol.type === 'text' && fixed === true) {
      g.geometry['x'] = g.geometry.rings[0][0].slice()[0];
      g.geometry['y'] = g.geometry.rings[0][0].slice()[1];
      delete g.rings;
    }
  }

  async getScreenshotURL(): Promise<string> {
    if (!this.mapView) {
      return null;
    }

    return (
      await this.mapView.takeScreenshot({
        format: 'png'
      })
    ).dataUrl;
  }
  //#endregion
}
