<form [formGroup]="form" *ngIf="config">
  <div class="form-group">
    <label class="bmd-label-floating" for="partnerId">
      Partner ID
    </label>
    <input
      type="text"
      class="form-control"
      formControlName="partnerId"
      name="partnerId"
      [(ngModel)]="config.partnerId"
    />
  </div>
</form>
