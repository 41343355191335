import { Contractor, RoleMember } from '.';
import { UserAddress } from './address';

export class User {
  id: string;
  name: string;
  userName: string;
  password: string;
  confirmPassword: string;
  isEnabled: boolean;
  disabledOn: string;
  emailAddress: string; //Partial<UserEmailAddress>;
  address: Partial<UserAddress>;
  phoneNumber: string; // Partial<UserPhoneNumber>;
  contractorId?: string;
  lastActivityDate?: string;
  members?: RoleMember[];
  contractors?: Contractor[];

  constructor(options?: Partial<User>) {
    if (options) {
      for (const source in options) {
        this[source] = options[source];
      }
    }
  }
}

export class SaveUserResponse {
  user: User;
  responseMessage: string;
}
