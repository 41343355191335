import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationConfiguration } from '../../../models/application-configuration';
import { WorkflowContextService } from '../../../services/workflow-context.service';
import { SecurityService, ClientService } from '../../../services';
import { Client } from '../../../models/client';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/app/models';

import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  @Input() client: Client;
  @Input() isAdminArea: boolean;

  appConfiguration: ApplicationConfiguration;
  configSubscription: any;
  userSubscription: any;
  loggedInSubscription: any;
  isAdministrator$: Observable<boolean>;
  isGlobalUser$: Observable<boolean>;
  isContractor: boolean;

  constructor(
    public context: WorkflowContextService,
    private _securitySvc: SecurityService,
    private _clientSvc: ClientService,
    private _router: Router
  ) {
    // this.userSubscription = this.context.user$.subscribe(item => {
    //   this.checkIfAdministrator();
    // });
  }

  ngOnInit() {
    this.appConfiguration = this.context.appConfiguration;
    this.checkIfAdministrator();

    this.configSubscription = this.context.appConfiguration$.subscribe(
      item => (this.appConfiguration = item)
    );

    if (this.context.user) {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: this.context.user.id,
          email: this.context.user.emailAddress,
          username: this.context.user.userName
        });
      });

      FullStory.identify(this.context.user.id, {
        username: this.context.user.userName,
        displayName: this.context.user.name,
        email: this.context.user.emailAddress
      });
    }
  }

  checkIfAdministrator() {
    //this.isAdministrator = true;
    //TODO: this is implemented in 0.2.0 we will uncomment once 0.20 is merged into here
    //if (this.context.user) {
    this.isAdministrator$ = this._securitySvc.isAnAdministrator();
    //}
    //this._securitySvc.isSystemAdministrator().subscribe(res => {
    this.isGlobalUser$ = this._securitySvc.isSystemAdministrator();

    this.isContractor =
      this.context.user != null && this.context.user.contractorId != null;

    //TODO: I brought this over from user-menu component but there isn't a client list. need to discuss
    //this._clientSvc.getAdminClients().subscribe(res => {
    //  this.clients = res;
    //  if (!this.isGlobalUser) {
    //    if ((this.clients || []).length > 0) {
    //      this.context.client$.next(this.clients[0]);
    //    }
    //  }
    //});
    // });
  }

  logout() {
    this._securitySvc.logout().subscribe(() => {
      this._router.navigate(['/']);
    });
  }
}
