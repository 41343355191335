import { DataEntityDetail } from './../../../models/data-entity-detail';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {
  Component,
  OnInit,
  Input,
  Inject,
  forwardRef,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators
} from '@angular/forms';
import {
  WorkflowService,
  WorkflowContextService,
  Utilities
} from '../../../services';
import { Observable, of, Subject, merge, lastValueFrom } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  map,
  catchError,
  filter
} from 'rxjs/operators';
import { Activity, ActivityModel } from 'src/app/models/activities';
import { Workflow } from 'src/app/models';
import { WorkflowVersion } from 'src/app/models/workflow-version';
import { NgMentionsComponent } from '@nth-cloud/ng-mentions';

@Component({
  selector: 'wm-data-entity-autocomplete',
  templateUrl: './data-entity-autocomplete.component.html',
  styleUrls: ['./data-entity-autocomplete.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DataEntityAutocompleteComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() model: any;
  @Input() required: boolean;
  @Input() form: UntypedFormGroup;
  @Input() id: string;
  @Input() serverValidator: any;
  @Input() title: string;
  @Output() valueUpdate: EventEmitter<string> = new EventEmitter<string>();
  @Input() activity: Activity<ActivityModel>;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('typeahead', { static: false }) typeahead: NgbTypeahead;
  @Input() placement: string;
  @Input() showFormulaEditor = true;

  @Input() types: string[];
  @Input() workflow: Workflow;
  @Input() onlyGlobalDataEntities = false;
  @Input() excludedTemplateCodes: string[] = [];
  @Input() clearOnSelect = false;
  @Input() workflowId: string; // pass this if Workflow is not available
  @Input() workflowVersionId: string; // pass this if Workflow is not available
  @Input() activityTypes: string[];
  @Input() columns: number = 40;

  @ViewChild('mentions', { static: false }) formula: NgMentionsComponent;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  formattedModel: string;

  // The supported template codes for this workflow.
  entityTypes = [];
  entities: DataEntityDetail[];
  activities: Activity<ActivityModel>[];
  view: any;
  maxResults = 10;
  selectedIndex = 0;
  emojiList: string[] = [];
  showSuggestions = false;
  isDestroyed = false;

  outputModel: string;

  constructor(
    @Inject(forwardRef(() => WorkflowService))
    private _workflowSvc: WorkflowService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    private _fb: UntypedFormBuilder,
    private _ref: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      this.model = this.translateFormulaToDEText(this.model);
    }
  }

  criteriaElement: HTMLTextAreaElement;

  searching: boolean;
  searchFailed: boolean;
  deType: any;

  ngOnDestroy(): void {
    this.isDestroyed = true;
    if (this._ref) {
      this._ref.detach();
    }

    this.form.removeControl(this.id);
  }

  detectChanges() {
    if (!this.isDestroyed) {
      this._ref.detectChanges();
    }
  }

  searchDataEntities(searchTerm: string): Observable<string[]> {
    let entityObs: Observable<DataEntityDetail[]>;

    if (this.activity) {
      entityObs = this._workflowSvc.getDataEntityDetailsBeforeMe(
        this.workflow || this._context.workflow,
        this.activity,
        this.types,
        (this.workflow || this._context.workflow).designStatus
      );
    } else {
      entityObs = this._workflowSvc
        .getDataEntities(
          this.workflow || this._context.workflow,
          this.types,
          this.onlyGlobalDataEntities,
          this.activityTypes
        )
        .pipe(
          map(deList => {
            return deList.map(de => {
              return new DataEntityDetail(de);
            });
          })
        );
    }

    return entityObs.pipe(
      map(de => {
        const lowerTerm = searchTerm.toLowerCase();
        return de.filter(
          (fde, index) =>
            ((fde &&
              fde.label &&
              fde.label.toLowerCase().indexOf(lowerTerm) > -1) ||
              (fde &&
                fde.templateCode &&
                fde.templateCode.toLowerCase().indexOf(lowerTerm) > -1)) &&
            !this.excludedTemplateCodes.includes(fde.templateCode)
        );
      }),
      map(de => {
        if (de) {
          return de.map(sde => sde.templateCode);
        }
      }),
      tap(() => (this.searchFailed = false)),
      catchError(() => {
        this.searchFailed = true;
        return of([]);
      })
    );
  }

  functionSelected(e) {
    e.preventDefault();
  }

  operatorSelected(e) {
    e.preventDefault();
  }

  itemSelected(e) {
    e.preventDefault();

    if (this.showFormulaEditor) {
      // grab the start/end of the selection from the text area
      var start = this.formula.textAreaInputElement.nativeElement
        .selectionStart;
      var end = this.formula.textAreaInputElement.nativeElement.selectionEnd;

      // grab the value from the ng-mentions component with the @(<template>) format
      let value = this.formula.value;
      // grab the value from the textarea element without the @(<template>) format
      let inputValue = this.formula.textAreaInputElement.nativeElement.value;

      // the start/end position of the cursor are based on the raw text and not the text with the @(<templateCode>)
      // format so we need to determine how many tokens are before the cursor and add characters for the @() characters for each token)
      const regTemplateParser = new RegExp(/@\(([^\(\)].*?)\)/, 'g');
      // grab the tockens from the value returned with the "@(<tempalteCode) format"
      const tokens = value.matchAll(regTemplateParser);

      // grab the text from the text area before the cursor
      const beforeRawText = inputValue.substring(0, start);

      let beforeTokens = 0;

      // iterate over the tokens in the string and count tokens before the cursor
      for (let t of tokens) {
        if (beforeRawText.indexOf(t[1]) > -1) {
          beforeTokens++;
        }
      }

      // calculate offset to shift the start/end for the token characters
      const tokenOffset = beforeTokens * 3;

      // add the 3 characters "@()" for each token before
      start += tokenOffset;
      end += tokenOffset;

      // insert the text from the drop down
      value = value.substring(0, start) + `@(${e.item})` + value.substring(end);

      // set the value of the ng-mentions component with the @() characters and it will do the magic
      this.formula.value = value;

      // clear the search intput value
      this.searchInput.nativeElement.value = '';
    } else {
      this.model = e.item;
      this.valueUpdate.emit(e.item);
      if (this.clearOnSelect) {
        this.searchInput.nativeElement.value = '';
      }
    }

    this.searchInput.nativeElement.blur();
  }

  clearSelectedValue() {
    this.model = null;
    this.valueUpdate.emit(this.model);
  }

  createDE(item: string): any {
    if (this.entities) {
      const entity = this.entities.find(
        de => de.templateCode.toLowerCase() === item.toLowerCase()
      );

      if (entity) {
        const deNode = this.deType.create({
          type: entity.dataEntityTypeCode,
          templateCode: item
        });

        return deNode;
      }
    }
  }

  insertDataEntity(templateCode: string) {
    this.itemSelected({ item: templateCode, preventDefault: () => {} });
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.typeahead.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap(() => (this.searching = true)),
      switchMap(term => {
        let searchTerm = '';
        if (this.criteriaElement) {
          const token = Utilities.getClosestToken(this.criteriaElement);

          if (!token.isInsideToken) {
            return of([]);
          }
          searchTerm = token.tokenValue;
        } else {
          searchTerm = term;
        }

        return this.searchDataEntities(searchTerm);
      }),
      tap(() => (this.searching = false))
    );
  };

  translateFormulaToDEText(text: string): string {
    const regTemplateParser = new RegExp(/\$\{([^\{\}].*?)\}/, 'g');

    let match;
    let formattedModel = text;
    while ((match = regTemplateParser.exec(text)) !== null) {
      if (this.entities) {
        const de = this.entities.find(
          e => e.templateCode.toLowerCase() === match[1].toLowerCase()
        );

        if (de) {
          formattedModel = formattedModel.replace(
            match[0],
            `@(${de.templateCode})`
          );
        }
      }
    }
    return formattedModel;
  }

  formulaChange(e) {
    this.outputModel = this.translateContentToText(e);
    this.valueUpdate.emit(this.outputModel);
  }

  translateContentToText(content?: string): string {
    const regTemplateParser = new RegExp(/@\(([^\{\}].*?)\)/, 'g');

    if (!content) {
      content = '';
    }

    let match;
    let formattedModel = content;

    while ((match = regTemplateParser.exec(content)) !== null) {
      if (this.entities) {
        const de = this.entities.find(
          e => e.templateCode.toLowerCase() === match[1].toLowerCase()
        );

        if (de) {
          formattedModel = formattedModel.replace(
            match[0],
            `\${${de.templateCode}}`
          );
        }
      }
    }

    content = formattedModel;

    return content;
  }

  async ngAfterViewInit() {
    let workflow: Workflow;

    // we might not be using this componment in the context of workflow design, so the calling component might only have a workflowId and versionId to pass to this component, rather than the whole Workflow object
    if (!this.workflow && this.workflowId && this.workflowVersionId) {
      workflow = new Workflow({
        id: this.workflowId,
        version: new WorkflowVersion({
          id: this.workflowVersionId
        })
      });

      this.workflow = workflow;
    } else {
      workflow = this.workflow || this._context.workflow;
    }

    if (workflow) {
      if (!this.activity) {
        this.entities = await lastValueFrom(
          this._workflowSvc
            .getDataEntities(
              workflow,
              this.types,
              this.onlyGlobalDataEntities,
              this.activityTypes
            )
            .pipe(
              map(des =>
                des
                  .map(de => new DataEntityDetail(de))
                  .filter(
                    ded =>
                      !this.excludedTemplateCodes.includes(ded.templateCode)
                  )
              )
            )
        );
      } else {
        this.entities = await lastValueFrom(
          this._workflowSvc
            .getDataEntityDetailsBeforeMe(
              workflow,
              this.activity,
              this.types,
              workflow.designStatus
            )
            .pipe(
              map(deds => {
                if (deds) {
                  return deds
                    .map(ded => ded)
                    .filter(
                      ded =>
                        !this.excludedTemplateCodes.includes(ded.templateCode)
                    );
                } else {
                  return [];
                }
              })
            )
        );
      }

      this.model = this.translateFormulaToDEText(this.model);

      if (workflow.version.graph) {
        this.activities = await lastValueFrom(
          this._workflowSvc.getWorkflowActivities(workflow)
        );
      } else {
        this.activities = await lastValueFrom(
          this._workflowSvc.getWorkflowActivitiesById(workflow.id)
        );
      }

      if (this.model) {
        // replace tokens in the data to be nodes for the editor.
        if (this.model.indexOf('${') > -1) {
          this.formattedModel = this.translateFormulaToDEText(this.model);
        } else {
          this.formattedModel = this.model || '';
        }
      } else {
        this.formattedModel = '';
      }
    } else {
      this.formattedModel = '';
    }

    // initialize the form control with the current model value
    if (this.isDestroyed) {
      return;
    }

    const outputText = this.translateContentToText(this.model);

    this.form.controls[this.id].setValue(outputText);
    this.detectChanges();
    this.valueUpdate.emit(outputText);
  }

  ngOnInit() {
    if (!this.placement) {
      this.placement = 'right';
    }

    const key: string = this.id;
    if (!this.form) {
      this.form = new UntypedFormGroup({});
    }

    if (this.required) {
      this.form.addControl(
        key,
        this._fb.control(this.model, [Validators.required])
      );
    } else {
      this.form.addControl(
        key,
        this._fb.control(this.model, [Validators.nullValidator])
      );
    }

    this.detectChanges();
  }
}
