Scheduling engine is now running...
<br />
<ng-container *ngIf="engineRunning">
  <app-loader></app-loader>
</ng-container>
<ng-container *ngIf="!engineRunning">
  Scheduling engine stopped!
</ng-container>

<div *ngIf="noUserId" class="text-danger">
  ERROR: There was no userId on context. Engine was not run.
</div>
