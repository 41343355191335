import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import {
  MonthlyFrequencyOptions,
  ScheduleFrequencyOptions,
  ScheduleFrequencyType,
  WeeklyFrequencyOptions
} from 'src/app/models/scheduled-export';
import {
  DataService,
  Utilities,
  WorkflowContextService
} from 'src/app/services';

@Component({
  selector: 'wm-schedule-editor',
  templateUrl: './schedule-editor.component.html',
  styleUrls: ['./schedule-editor.component.css']
})
export class ScheduleEditorComponent implements OnInit, OnDestroy {
  @Input() type: ScheduleFrequencyType;
  @Input() options: ScheduleFrequencyOptions;
  @Input() form: UntypedFormGroup;
  @Input() existingNextExportOn: string;

  @ViewChild('dStart', { static: true }) startDatePicker: NgbInputDatepicker;
  @ViewChild('dEnd', { static: true }) endDatePicker: NgbInputDatepicker;

  today = new Date();
  startDate: any;
  endDate: any;
  sendTime: any;
  clientTimeZoneOffset: number;
  clientTimeZoneCode: string;
  nextExportOn: string;
  gettingNextExport = false;
  ScheduleFrequencyType = ScheduleFrequencyType;

  constructor(
    private _fb: UntypedFormBuilder,
    private _context: WorkflowContextService,
    private _dataSvc: DataService
  ) {}

  ngOnInit() {
    const client = this._context.client;
    this.clientTimeZoneOffset = client.timeZoneOffset;
    this.clientTimeZoneCode = client.timeZoneCode;

    // use a separate variable to bind to the time picker,
    // then merge the configured time back into the startDate on save
    if (this.options.startDateString) {
      const startDate = { year: 0, month: 0, day: 0 };
      const sendTime = { hour: 0, minute: 0, second: 0 };

      const sd = new Date(this.options.startDateString);

      startDate.year = sd.getFullYear();
      startDate.month = sd.getMonth() + 1;
      startDate.day = sd.getDate();

      sendTime.hour = sd.getHours();
      sendTime.minute = sd.getMinutes();
      sendTime.second = 0;

      this.startDate = startDate;
      this.sendTime = sendTime;
    } else {
      this.options.startDateString = Utilities.getDateTimeString(new Date());

      // set a default time
      this.sendTime = { hour: 12, minute: 0, second: 0 };
    }

    if (this.options.endDateString) {
      const endDate = { year: 0, month: 0, day: 0 };

      const ed = new Date(this.options.endDateString);

      endDate.year = ed.getFullYear();
      endDate.month = ed.getMonth() + 1;
      endDate.day = ed.getDate();

      this.endDate = endDate;
    }

    if (this.existingNextExportOn && this.existingNextExportOn !== '') {
      this.nextExportOn = this.existingNextExportOn;
    }

    if (!this.options.frequency) {
      this.options.frequency = 1;
    }

    this.form.addControl(
      'scheduleDaysSpecified',
      this._fb.control('', this.scheduleDaysSpecified.bind(this))
    );
    this.form.addControl(
      'startDate',
      this._fb.control(this.startDate, Validators.required)
    );
    this.form.addControl(
      'endDate',
      this._fb.control(this.endDate, Validators.nullValidator)
    );
    this.form.addControl(
      'sendTime',
      this._fb.control(this.sendTime, Validators.required)
    );
  }

  ngOnDestroy() {
    this.form.removeControl('scheduleDaysSpecified');
  }

  scheduleDaysSpecified(): ValidationErrors | null {
    let castedOptions: any;

    if (this.options.type === 'weekly-frequency-options') {
      castedOptions = this.options as WeeklyFrequencyOptions;

      if (
        castedOptions.onSunday ||
        castedOptions.onMonday ||
        castedOptions.onTuesday ||
        castedOptions.onWednesday ||
        castedOptions.onThursday ||
        castedOptions.onFriday ||
        castedOptions.onSaturday
      ) {
        return null;
      } else {
        return { weekDaysSpecified: true };
      }
    }

    if (this.options.type === 'monthly-frequency-options') {
      castedOptions = this.options as MonthlyFrequencyOptions;

      if (
        castedOptions.on1st ||
        castedOptions.on2nd ||
        castedOptions.on3rd ||
        castedOptions.on4th ||
        castedOptions.on5th ||
        castedOptions.on6th ||
        castedOptions.on7th ||
        castedOptions.on8th ||
        castedOptions.on9th ||
        castedOptions.on10th ||
        castedOptions.on11th ||
        castedOptions.on12th ||
        castedOptions.on13th ||
        castedOptions.on14th ||
        castedOptions.on15th ||
        castedOptions.on16th ||
        castedOptions.on17th ||
        castedOptions.on18th ||
        castedOptions.on19th ||
        castedOptions.on20th ||
        castedOptions.on21st ||
        castedOptions.on22nd ||
        castedOptions.on23rd ||
        castedOptions.on24th ||
        castedOptions.on25th ||
        castedOptions.on26th ||
        castedOptions.on27th ||
        castedOptions.on28th ||
        castedOptions.onLastDOM
      ) {
        return null;
      } else {
        return { monthDaysSpecified: true };
      }
    }
  }

  updateScheduleDaysSpecified() {
    // the validator function needs re-bound because for some reason upon destroying and re-initting this component
    // this doesn't stay bound to the validator function and will return validation errors on updateValueAndValidity
    this.form
      .get('scheduleDaysSpecified')
      .setValidators(this.scheduleDaysSpecified.bind(this));
    this.form.get('scheduleDaysSpecified').updateValueAndValidity();
    this.form.get('scheduleDaysSpecified').markAsTouched();

    this.refreshNextExportOn();
  }

  getOptions(): ScheduleFrequencyOptions {
    this.setStartEndDates();

    return this.options;
  }

  setStartEndDates() {
    const startDate = new Date(this.options.startDateString);

    startDate.setFullYear(
      this.startDate.year,
      this.startDate.month - 1,
      this.startDate.day
    );
    startDate.setHours(this.sendTime.hour, this.sendTime.minute, 0);

    this.options.startDateString = Utilities.getDateTimeString(startDate);

    if (this.endDate && Object.keys(this.endDate).length > 0) {
      const endDate = new Date();

      endDate.setFullYear(
        this.endDate.year,
        this.endDate.month - 1,
        this.endDate.day
      );
      endDate.setHours(0, 0, 0);

      this.options.endDateString = Utilities.getDateTimeString(endDate);
    }
    else {
      this.options.endDateString = null;
    }
  }

  convertFrequencyOptionsToWeekly() {
    this.type = ScheduleFrequencyType.Weekly;

    this.options = new WeeklyFrequencyOptions({
      startDateString: this.options.startDateString,
      endDateString: this.options.endDateString,
      frequency: this.options.frequency
    });

    this.form.get('scheduleDaysSpecified').updateValueAndValidity();
  }

  convertFrequencyOptionsToMonthly() {
    this.type = ScheduleFrequencyType.Monthly;

    this.options = new MonthlyFrequencyOptions({
      startDateString: this.options.startDateString,
      endDateString: this.options.endDateString,
      frequency: this.options.frequency
    });

    this.form.get('scheduleDaysSpecified').updateValueAndValidity();
  }

  onFocusStartDate() {
    this.startDatePicker.open();
    return true;
  }

  onFocusEndDate() {
    this.endDatePicker.open();
    return true;
  }

  refreshNextExportOn() {
    if (this.form.valid) {
      this.startDate = this.form.controls['startDate'].value;
      this.endDate = this.form.controls['endDate'].value;
      this.calculateNextExportOn();
    }
  }

  calculateNextExportOn() {
    this.gettingNextExport = true;

    const options = this.getOptions();

    this._dataSvc.calculateNextExportOn(options).subscribe(neo => {
      this.nextExportOn = neo;

      this.gettingNextExport = false;
    });
  }
}
