import {
  DataSetField,
  FilterConfig,
  WorkflowFilter,
  ReportDataSetFieldDataType
} from './custom-report';
import {
  DataEntity,
  DateDataEntity,
  FeeDataEntity,
  FreeFormTextDataEntity,
  NumericDataEntity
} from './data-entities';
import { SystemDataEntity } from './data-entities/system-data-entity';
import { TodayDataEntity } from './data-entities/today-data-entity';

export enum SortDirection {
  Ascending = 1,
  Descending = 2
}

export class ReportDataSetOrder {
  fieldName: string;
  direction: SortDirection;
  ordinal: number;
  constructor(options?: Partial<ReportDataSetOrder>) {
    if (options) {
      Object.assign(this, options);
    }
  }
}

export class ReportDataSet {
  id: string;
  fields: DataSetField[];
  fieldOrder: ReportDataSetOrder[];
  type: string;

  constructor() {
    this.fields = [];
    this.fieldOrder = [];
  }
}

export class WorkflowApplicationReportDataSet extends ReportDataSet {
  workflowIds: string[];
  clientIds: string[];
  workflowFilters: WorkflowFilter[];
  constructor() {
    super();
    this.type = 'workflow-application-dataset';
    this.workflowIds = [];
    this.workflowFilters = [];
  }
}

export class DataSet {
  id: string;
  description: string;
  clientId: string;
  name: string;
  publishedOnString: string;
  dataSetConfig: WorkflowApplicationReportDataSet;
  filterConfig: FilterConfig;
  publishedDataSetConfig: WorkflowApplicationReportDataSet;
  publishedFilterConfig: FilterConfig;

  static resolveInputType(entity: DataEntity): string {
    if (entity instanceof DateDataEntity || entity instanceof TodayDataEntity) {
      return 'date';
    } else if (
      entity instanceof NumericDataEntity ||
      entity instanceof FeeDataEntity
    ) {
      return 'number';
    } else {
      return 'text';
    }
  }

  static resolveOptions(entity: DataEntity): string[] {
    const pv = entity.getPossibleValues();
    if ((pv || []).length > 0) {
      return pv.map(mpv => mpv.value);
    } else {
      return null;
    }
  }

  static resolveInputTypeForField(field: DataSetField): string {
    switch (field.dataType) {
      case ReportDataSetFieldDataType.Date:
        return 'date';
      case ReportDataSetFieldDataType.Decimal:
      case ReportDataSetFieldDataType.Integer:
        return 'number';
      default:
        return 'text';
    }
  }

  static resolveOptionsForField(field: DataSetField): string[] {
    if (field.availableFilterItems) {
      return field.availableFilterItems.map(i => i.value);
    } else {
      return null;
    }
  }

  static resolveTypesForField(field: DataSetField): string[] {
    const types = ['is', 'is not', 'exists', 'not exists'];

    const pv = DataSet.resolveOptionsForField(field);

    if ((pv || []).length > 0) {
      types.push('in');
    }

    if (field.dataType === ReportDataSetFieldDataType.String) {
      types.push('contains');
      types.push('not contains');
    }

    if (field.dataType !== ReportDataSetFieldDataType.String) {
      types.push('range');
      types.push('<');
      types.push('>');
      types.push('<=');
      types.push('>=');
    }

    return types;
  }

  static resolveTypes(entity: DataEntity): string[] {
    const types = ['is', 'is not', 'exists', 'not exists'];

    const pv = DataSet.resolveOptions(entity);

    if ((pv || []).length > 0) {
      types.push('in');
    }

    if (
      entity instanceof FreeFormTextDataEntity ||
      (entity instanceof SystemDataEntity &&
        (<SystemDataEntity>entity).dataType.indexOf('String') > -1)
    ) {
      types.push('contains');
      types.push('not contains');
    }

    if (
      entity instanceof NumericDataEntity ||
      entity instanceof FeeDataEntity ||
      entity instanceof DateDataEntity ||
      entity instanceof TodayDataEntity
    ) {
      types.push('range');
      types.push('<');
      types.push('>');
      types.push('<=');
      types.push('>=');
    }

    return types;
  }
}
