<ng-container *ngIf="ds">
  <div *ngIf="(ds.id || '') == ''" class="mb-4">
    <h4>New Data Set</h4>
    <p class="small">
      A Data Set allows you to combine data entities from multiple workflows
      into a list of columns that you define. Once created and published, you
      can export this Data Set with appropriate filters. If you map multiple
      data entities from a workflow into a column, those values will be
      concatenated with a comma.
    </p>
    <ol class="small">
      <li>Name the Data Set and give it a description.</li>
      <li>
        Add columns to your Data Set in the order you want them displayed.
      </li>
      <li>Choose at least one column to use as the default sort order.</li>
      <li>
        Optional: Add workflows to the Data Set if you want to restrict the
        results to specific workflows.
      </li>
      <li>
        Optional: Add filtering to any workflows if you want to filter the data
        from that workflow that will be included in the Data Set.
      </li>
      <li>
        Optional: Add column(s) to the filter input to allow the user(s) that
        are using the Data Set to export data to filter the Data Set even
        further.
      </li>
      <li>Save the Data Set.</li>
      <li>
        Publish the Data Set when you are ready for it to be used by others.
      </li>
      <li>
        Once configured, utilize the Export Data Set tab in the Data Export
        Report to run reports from the Data Set.
      </li>
    </ol>
    <p class="small">
      To view more details about this feature, please see our User Guide.
    </p>
  </div>
  <div class="row" [formGroup]="form">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h4>Data Set Information</h4>
          </div>
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="dataSetName"
                      >Name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="ds.name"
                      name="dataSetName"
                      formControlName="dataSetName"
                    />
                    <wm-control-message
                      [control]="form.controls['dataSetName']"
                    ></wm-control-message>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="bmd-label-floating" for="dataSetDesc"
                      >Description</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      [(ngModel)]="ds.description"
                      name="dataSetDesc"
                      formControlName="dataSetDesc"
                    />
                    <wm-control-message
                      [control]="form.controls['dataSetDesc']"
                    ></wm-control-message>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div *ngIf="dataSet">
  <div class="row mt-3">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h4 class="float-left">
              Filter Input Configuration
            </h4>
            <div class="switch float-right">
              <label
                title="This will allow you to specify any columns from the Data Set for which the executer can apply filters."
              >
                <input
                  id="filterInputAllowFiltering"
                  type="checkbox"
                  [(ngModel)]="allowFiltering"
                  (ngModelChange)="allowFilteringChanged($event)"
                  [disabled]="dataSet.fields.length == 0"
                />
                Allow User filtering
              </label>
            </div>
          </div>

          <table class="table" *ngIf="allowFiltering">
            <thead>
              <tr>
                <th></th>
                <th>Column</th>
                <th>Label</th>
                <th>Required</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="(ds.filterConfig.fields || []).length > 0">
                <tr *ngFor="let sf of ds.filterConfig.fields">
                  <td>
                    <button
                      class="btn btn-sm"
                      (click)="editFilterInput(sf)"
                      title="Edit Filter Input"
                    >
                      <fa-icon [icon]="faCog" class="pl-2 pr-2"></fa-icon>
                    </button>
                    <wm-modal-confirm
                      (accepted)="removeFilterInput(sf)"
                      [btnIcon]="'delete'"
                      [showButton]="true"
                      [showButtonText]="true"
                      [commandText]="'Yes'"
                      title="Delete Filter Input"
                      tooltip="Delete Filter Input"
                      [buttonClass]="'btn btn-sm'"
                      [iconStyle]="{ fontSize: '18px' }"
                    >
                      Are you sure you want to remove this Filter Input?
                    </wm-modal-confirm>
                  </td>
                  <td>{{ sf.name }}</td>
                  <td>{{ sf.label }}</td>
                  <td>{{ sf.requireInput ? 'Yes' : 'No' }}</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="(ds.filterConfig.fields || []).length == 0">
                <tr>
                  <td colspan="3">
                    You have not configured any columns to allow the user to
                    filter this data set.
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3">
                  <a
                    href="javascript:void(0)"
                    (click)="addFilterInput()"
                    title="Add Filter Input"
                    ><fa-icon [icon]="faPlus" class="pl-2 pr-2"></fa-icon
                  ></a>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="card-title">
            <h4>Sorting Configuration</h4>
          </div>
          <div class="row">
            <table class="table">
              <tr>
                <th></th>
                <th>Order By <span class="text-danger">*</span></th>
                <th>Direction <span class="text-danger">*</span></th>
                <th></th>
              </tr>
              <tbody
                *ngIf="form.controls['fieldOrder']"
                dragula="sortFields"
                [(dragulaModel)]="dataSet.fieldOrder"
                (dragulaModelChange)="orderChange()"
              >
                <tr *ngFor="let fo of dataSet.fieldOrder; index as idx">
                  <td scope="row" style="text-align: center;" class="pt-4">
                    <fa-icon
                      [icon]="faBars"
                      class="pt-1"
                      style="cursor: grab;"
                      title="Order the sorting priority"
                      *ngIf="dataSet.fieldOrder.length > 1"
                    ></fa-icon>
                  </td>
                  <td>
                    <select
                      class="form-control"
                      [(ngModel)]="fo.fieldName"
                      [formControl]="
                        form.controls['fieldOrder'].controls[
                          'field' + idx.toString()
                        ]
                      "
                    >
                      <option></option>
                      <option *ngIf="dataSet.fields.length == 0"
                        >(Add Column to Data Set)</option
                      >
                      <option
                        *ngFor="let f of dataSet.fields"
                        [value]="f.name"
                        >{{ f.name }}</option
                      >
                    </select>
                    <wm-control-message
                      [control]="
                        form.controls['fieldOrder'].controls[
                          'field' + idx.toString()
                        ]
                      "
                    ></wm-control-message>
                  </td>
                  <td>
                    <select
                      class="form-control"
                      [(ngModel)]="fo.direction"
                      [formControl]="
                        form.controls['fieldOrder'].controls[
                          'order' + idx.toString()
                        ]
                      "
                    >
                      <option value="1">Ascending</option>
                      <option value="2">Descending</option>
                    </select>
                    <wm-control-message
                      [control]="
                        form.controls['fieldOrder'].controls[
                          'order' + idx.toString()
                        ]
                      "
                    ></wm-control-message>
                  </td>
                  <td>
                    <wm-modal-confirm
                      (accepted)="removeSortOrder(fo)"
                      [btnIcon]="'delete'"
                      [showButton]="true"
                      [showButtonText]="true"
                      [commandText]="'Yes'"
                      title="Remove Sorting Column"
                      tooltip="Remove Sorting Column"
                      [buttonClass]="'btn btn-sm'"
                      [iconStyle]="{ fontSize: '18px' }"
                      *ngIf="idx != 0"
                    >
                      Are you sure you want to remove this Sorting Column?
                    </wm-modal-confirm>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <a
              href="javascript:void(0)"
              (click)="addSortOrder()"
              title="Add Sorting Column"
              ><fa-icon [icon]="faPlus" class="pl-2 pr-2"></fa-icon
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-3" style="position: relative; height: 40px">
  <div
    [ngStyle]="{
      visibility: hoverColumn ? 'visible' : 'hidden',
      left: hoverLeft + 'px'
    }"
    style="position: absolute; border: 1px solid black"
    (mouseover)="hoveringOnAction = true"
    (mouseout)="hoveringOnAction = false; hideColumnActions()"
    #columnActions
  >
    <button
      class="btn btn-sm"
      (click)="moveFieldLeft(hoverColumn)"
      [ngStyle]="{ visibility: canMoveLeft() ? 'visible' : 'hidden' }"
      title="Move Column Left"
    >
      <fa-icon [icon]="faArrowLeft" class="pl-2 pr-2"></fa-icon>
    </button>
    <button
      class="btn btn-sm"
      (click)="configureField(hoverColumn)"
      title="Configure Column"
    >
      <fa-icon [icon]="faCog" class="pl-2 pr-2"></fa-icon>
    </button>
    <div style="display: inline-block" (mouseover)="actionColumn = hoverColumn">
      <wm-modal-confirm
        (accepted)="removeColumn(actionColumn)"
        [btnIcon]="'delete'"
        [showButton]="true"
        [showButtonText]="true"
        [commandText]="'Yes'"
        title="Remove Column"
        tooltip="Remove Column"
        [buttonClass]="'btn btn-sm'"
        [iconStyle]="{ fontSize: '18px' }"
        [buttonStyling]="{ paddingTop: '7px', paddingBottom: '5px' }"
      >
        Are you sure you want to remove this Column?
      </wm-modal-confirm>
    </div>
    <button
      class="btn btn-sm"
      (click)="moveFieldRight(hoverColumn)"
      [ngStyle]="{ visibility: canMoveRight() ? 'visible' : 'hidden' }"
      title="Move Column Right"
    >
      <fa-icon [icon]="faArrowRight" class="pl-2 pr-2"></fa-icon>
    </button>

    <wm-modal-confirm
      [title]="fieldSettingsTitle + ' Column Settings'"
      [showButton]="false"
      commandText="Save"
      [form]="fieldSettingsForm"
      (accepted)="updateColumn()"
      (canceled)="cancelColumn()"
      #fieldSettings
    >
      <div *ngIf="actionField" [formGroup]="fieldSettingsForm">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label
                class="bmd-label-floating"
                style="display: contents"
                for="fieldName"
                >Name <span class="text-danger">*</span></label
              >
              <wm-help-text
                class="ml-1 float-clear"
                placement="right"
                helpText="This is the name of the column. This can be a template code that you use in your workflows or it can be an arbitrary name that is unique in the data set. If you have set the name to an arbitrary value, you will need to map data entities for each workflow to this column."
              ></wm-help-text>
              <input
                type="text"
                class="form-control"
                style="width: 94%;display: inline-block"
                [(ngModel)]="actionField.name"
                name="fieldName"
                formControlName="fieldName"
              />
              <wm-modal-confirm
                title="Global Entities"
                [showButtonText]="true"
                [showButton]="true"
                commandText="Select"
                #globalEntitySearch
                btnIcon="search"
                [buttonClass]="'btn btn-sm'"
              >
                <wm-data-entity-autocomplete
                  *ngIf="actionField"
                  [types]=""
                  [form]="form"
                  [model]="actionField.name"
                  [clearOnSelect]="true"
                  id="globalEntitySearch"
                  [showFormulaEditor]="false"
                  controlName="globalEntitySearch"
                  [onlyGlobalDataEntities]="true"
                  (valueUpdate)="actionField.name = $event"
                  title="Global Entity Search"
                ></wm-data-entity-autocomplete>
              </wm-modal-confirm>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label
                class="bmd-label-floating"
                style="display: contents"
                for="fieldHeader"
                >Header <span class="text-danger">*</span>
              </label>
              <wm-help-text
                class="ml-1"
                placement="right"
                helpText="This is the column header in the export file."
              ></wm-help-text>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="actionField.resultHeader"
                name="fieldHeader"
                formControlName="fieldHeader"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="bmd-label-floating" for="fieldDataType"
                >Data Type <span class="text-danger">*</span></label
              >
              <select
                class="form-control"
                [(ngModel)]="actionField.dataType"
                name="fieldDataType"
                formControlName="fieldDataType"
              >
                <option value="1">Text</option>
                <option value="2">Date</option>
                <option value="3">Number with Decimals</option>
                <option value="4">Number without Decimals</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </wm-modal-confirm>
  </div>
</div>
<ngx-datatable
  class="material mt-3"
  [columns]="columns"
  [rows]="rows"
  [columnMode]="ColumnMode.force"
  [headerHeight]="50"
  [footerHeight]="50"
  [messages]="{ emptyMessage: 'All Workflows' }"
  (reorder)="columnsReordered($event)"
  rowHeight="auto"
  [scrollbarH]="true"
  #dsGrid
  *ngIf="columns && rows"
>
  <ngx-datatable-footer *ngIf="true">
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <button
        class="btn btn-sm btn-primary"
        *ngIf="!value || !value.prop"
        (click)="addWorkflow()"
      >
        Add Workflow
      </button>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<div class="mt-3">
  <button
    class="btn btn-raised btn-primary mr-2"
    (click)="testDataSet()"
    [disabled]="!canTest()"
  >
    Test Data Set
  </button>

  <button class="btn btn-raised btn-primary mr-2" (click)="saveDataSet()">
    Save Data Set
  </button>

  <wm-modal-confirm
    title="Publish Data Set Confirmation"
    [showButtonText]="true"
    buttonText="Publish Data Set"
    commandText="Yes"
    cancelText="No"
    (accepted)="publishDataSet()"
    [enabled]="canPublish"
  >
    Are you sure you want to publish the changes to this Data Set?
  </wm-modal-confirm>

  <!-- <button class="btn btn-raised btn-primary" (click)="publishDataSet()"
  [disabled]="!canTest()">
    Publish Data Set
  </button> -->
</div>

<app-loader *ngIf="executingTest"></app-loader>

<ngx-datatable
  class="material mt-3"
  [columns]="resultColumns"
  [rows]="resultRows"
  [columnMode]="ColumnMode.force"
  [headerHeight]="50"
  [footerHeight]="50"
  rowHeight="auto"
  *ngIf="resultColumns && resultRows"
  [scrollbarH]="true"
>
</ngx-datatable>

<ng-template
  #workflow
  let-row="row"
  let-value="value"
  let-column="column"
  let-rowIndex="rowIndex"
>
  <div *ngIf="row['empty']">All workflows</div>
  <ng-container *ngIf="!row['empty']">
    <select
      class="form-control"
      (change)="updateWorkflow(row)"
      [formControl]="form.controls['workflows'].controls[rowIndex]"
    >
      <option value>(Select Workflow)</option>
      <option *ngFor="let w of availbleWorkflows" [value]="w.id">{{
        w.version.name
      }}</option>
      <span class="text-danger">*</span>
    </select>
    <wm-control-message
      [control]="form.controls['workflows'].controls[rowIndex]"
    ></wm-control-message>
    <wm-modal-confirm
      (accepted)="removeWorkflow(value)"
      [btnIcon]="'delete'"
      [showButton]="true"
      [showButtonText]="true"
      [commandText]="'Yes'"
      title="Remove Workflow"
      tooltip="Remove Workflow"
      [buttonClass]="'btn btn-sm'"
      [iconStyle]="{ fontSize: '18px' }"
    >
      Are you sure you want to remove this Workflow?
    </wm-modal-confirm>
  </ng-container>
  <button
    class="btn btn-sm"
    (click)="filterWorkflow(value || ALL_WORKFLOWS_ID)"
    title="Filter Workflow Applications"
  >
    <!-- *ngIf="row['workflowId']" -->
    <fa-icon [icon]="faFilter" class="pl-2 pr-2"></fa-icon>
  </button>
  <span *ngIf="isFiltered(value)">Filtered</span>
</ng-template>

<ng-template #cell let-row="row" let-value="value" let-column="column">
  <ng-container *ngIf="row['workflowId']">
    <ng-container *ngIf="value && value.length">
      <ng-container *ngFor="let m of value; let idx = index">
        {{ m.templateCode }}
        <wm-modal-confirm
          (accepted)="removeMapping(column, value, idx)"
          [btnIcon]="'delete'"
          [showButton]="true"
          [showButtonText]="true"
          [commandText]="'Yes'"
          title="Remove Data Entity Mapping"
          tooltip="Remove Data Entity"
          [buttonClass]="'btn btn-sm'"
          [iconStyle]="{ fontSize: '18px' }"
        >
          Are you sure you want to remove this mapping?
        </wm-modal-confirm>
        <!-- <button
        class="btn btn-sm"
        (click)="removeMapping(column, value, idx)"
        title="Remove Data Entity"
      >
        <fa-icon [icon]="faTrash" class="pl-2 pr-2"></fa-icon>
      </button> -->
      </ng-container>
    </ng-container>
    <button
      class="btn btn-sm"
      (click)="addMapping(row, column)"
      title="Add Data Entity"
    >
      <fa-icon [icon]="faPlus" class="pl-2 pr-2"></fa-icon>
    </button>
  </ng-container>
</ng-template>

<wm-modal-confirm
  title="Column/Entity Mapping"
  [showButton]="false"
  commandText="Save"
  (accepted)="updateMapping()"
  (canceled)="cancelMapping()"
  [form]="mappingForm"
  #mappingConfig
>
  <wm-data-entity-autocomplete
    *ngIf="activeMapping"
    [types]=""
    [form]="mappingForm"
    [model]="activeMapping.templateCode"
    id="columnEntity"
    [required]="true"
    [showFormulaEditor]="false"
    [clearOnSelect]="true"
    [workflow]="
      workflowIdx &&
      activeMapping.workflowId &&
      workflowIdx[activeMapping.workflowId]
    "
    (valueUpdate)="
      activeMapping.templateCode = $event;
      mappingForm.controls['columnEntity'].setValue($event)
    "
    title="Mapping Entity"
  ></wm-data-entity-autocomplete>
</wm-modal-confirm>

<wm-modal-confirm
  title="Filter Input Column Settings"
  [showButton]="false"
  commandText="Save"
  (accepted)="updateFilterInput()"
  #filterInputConfig
  [form]="filterInputForm"
>
  <div class="row" *ngIf="filterInputField" [formGroup]="filterInputForm">
    <div class="col">
      <div class="form-group">
        <label class="bmd-label-floating" for="filterInputField"
          >Column <span class="text-danger">*</span></label
        >
        <select
          class="form-control"
          [(ngModel)]="filterInputField.name"
          formControlName="field"
        >
          <option *ngIf="dataSet.fields.length == 0"
            >(Add Column to Data Set)</option
          >
          <option *ngFor="let f of dataSet.fields" [value]="f.name">{{
            f.name
          }}</option>
        </select>
        <wm-control-message
          [control]="form.controls['field']"
        ></wm-control-message>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label class="bmd-label-floating" for="filterInputLabel"
          >Label <span class="text-danger">*</span></label
        >
        <input
          type="text"
          class="form-control"
          [(ngModel)]="filterInputField.label"
          name="filterInputLabel"
          formControlName="label"
        />
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <div class="switch">
          <label>
            <input
              id="filterInputRequired"
              type="checkbox"
              (change)="
                (filterInputField.requireInput != filterInputField.requireInput)
              "
              formControlName="isRequired"
            />
            Required
          </label>
        </div>
      </div>
    </div>
  </div>
</wm-modal-confirm>

<wm-modal-confirm
  title="Column Settings"
  [showButton]="false"
  commandText="Save"
  (accepted)="updateWorkflowFilter(workflowFilterConfig)"
  #workflowStaticFilter
>
  <wm-filter-builder
    #workflowFilterCtrl
    *ngIf="workflowFilterParams"
    [id]="
      ds.id + '|' + workflowFilterConfig.workflowId + '|workflowStaticFilter'
    "
    [params]="workflowFilterParams"
    [defaultFilters]="workflowFilters"
    [showFilterBuilder]="true"
    [showSimpleSearch]="false"
    [waitForParent]="false"
    [exportingEnabled]="false"
    (filtersChange)="workflowFilterChanged($event)"
    [shapefileValidationCriteria]="shapefileValidationCriteria"
  ></wm-filter-builder>
</wm-modal-confirm>

<ng-template
  #columnHeader
  let-row="row"
  let-value="value"
  let-column="column"
  ngx-datatable-header-template
>
  <button
    class="btn btn-sm btn-primary"
    (click)="addColumn()"
    *ngIf="
      (!column || !column.prop) && columns.indexOf(column) == columns.length - 1
    "
    title="Add Column"
  >
    Add Column
  </button>
  <div class="datatable-header-cell-wrapper">
    <span
      class="datatable-header-cell-label draggable"
      (mouseover)="columnHover(column, $event)"
      (mouseout)="hideColumnActions()"
    >
      {{ column.name }}
    </span>
  </div>
</ng-template>
