<form [formGroup]="form" novalidate>
  <h3>Data Export</h3>
  <ul ngbNav #tabset="ngbNav" [destroyOnHide]="false" class="nav-tabs">
    <li ngbNavItem="exportWorkflow" id="exportWorkflow">
      <a ngbNavLink>Export Workflow</a>
      <ng-template ngbNavContent>
        <div class="form-group">
          <label class="bmd-label-floating" for="sel1"
            >Choose Your Workflow:</label
          >
          <select
            class="form-control"
            id="sel1"
            name="sel1"
            [formControl]="name"
            (change)="switchWorkflowOption()"
          >
            <option></option>
            <option
              [selected]="s.id === exportId"
              *ngFor="let s of exportOptions"
              [ngValue]="s"
              >{{ s.name }}</option
            >
          </select>
        </div>

        <div *ngIf="exportEntityOptions && exportEntityOptions.length > 0">
          <wm-filter-builder
            *ngIf="exportEntityOptions && exportEntityOptions.length > 0"
            [id]="id"
            parentId="workflow-export"
            [params]="params"
            (filtersChange)="filtersChanged($event)"
            (exportColumnsChange)="columnsChanged($event)"
            [columnOptions]="exportEntityOptions"
            [savedFilters]="true"
            [onlyExportOptions]="true"
            [shapefileValidationCriteria]="shapefileValidationCriteria"
          ></wm-filter-builder>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="exportDataSet" id="exportDataSet">
      <a ngbNavLink>Export Data Set</a>
      <ng-template ngbNavContent>
        <div class="form-group" *ngIf="dataSets">
          <label class="bmd-label-floating" for="sel1"
            >Choose Your Data Set:</label
          >
          <select
            class="form-control"
            id="selDS"
            name="selDS"
            [formControl]="selDS"
            (change)="switchDataSet()"
          >
            <option></option>

            <option
              [selected]="ds.id === dataSetId"
              *ngFor="let ds of dataSets"
              [ngValue]="ds.id"
              >{{ ds.name }}</option
            >
            <option value="create" *ngIf="canCreateDataSet"
              >Create Data Set</option
            >
          </select>
        </div>

        <wm-dataset-input
          *ngIf="dataSetId"
          [dataSetId]="dataSetId"
          [form]="form"
          (filterFormChanged)="filterFormChanged($event)"
          (filtersChanged)="dataSetFiltersChanged($event)"
          (dataSetLoaded)="dataSetLoaded($event)"
          (filterParamsChanged)="dataSetParamsChanged($event)"
          (columnsChanged)="dataSetColumnsChanged($event)"
          [shapefileValidationCriteria]="shapefileValidationCriteria"
        ></wm-dataset-input>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="tabset"></div>

  <app-loader *ngIf="exportId && !exportEntityOptions"></app-loader>

  <ng-container *ngIf="dataSetId || (exportId && exportEntityOptions)">
    <br />

    <span class="ml-2 text-danger">
      Note: Depending on the criteria you choose above, the export process may
      take several minutes to complete.
    </span>
    <br />
    <span
      class="ml-2 text-danger"
      [hidden]="!shapefileValidationCriteria.showShapeFieldError"
    >
      {{
        shapefileValidationCriteria.tooltipErrors.shapefileExcessFieldsWarning
      }}
    </span>
    <div class="row mt-2 mb-2 ml-0 p-0">
      <div class="col ml-0">
        <wm-exports-view
          *ngIf="
            (exportEntityOptions &&
              exportEntityOptions.length > 0 &&
              staticFilters &&
              staticFilters.length > 0) ||
            (dataSetId && dsParams && filterForm)
          "
          [id]="dataSetId ? dsId + '-' + dataSetId : id"
          [title]="title"
          [params]="dsParams || params"
          [currentFilters]="dsFilters || filters"
          [staticFilters]="dsStaticFilters || staticFilters"
          [currentColumnOptions]="dsColumnOptions || exportEntityOptions"
          (exptExcel)="exportExcel()"
          (exptCsv)="exportCsv()"
          (exptShapefile)="exportShapefile()"
          [defaultTemplate]="tabset.activeId == 'exportDataSet'"
          [shapefileValidationCriteria]="shapefileValidationCriteria"
          [form]="form"
          [filterForm]="filterForm"
        ></wm-exports-view>
      </div>
    </div>

    <div class="row mt-2 ml-2 p-0">
      <div class="col m-0 p-0">
        <div [hidden]="!exporting">
          <div class="spinner-border"></div>
          Exporting...
        </div>
        <div [hidden]="!showDownloadLink">
          <div>
            <a
              [attr.href]="sanitize(link.href)"
              [attr.download]="link.download"
            >
              Download {{ link.download }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</form>
