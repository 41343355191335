import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services';

@Component({
  selector: 'wm-schedling-engine-tester',
  templateUrl: './schedling-engine-tester.component.html',
  styleUrls: ['./schedling-engine-tester.component.css']
})
export class SchedlingEngineTesterComponent implements OnInit {
  durationMinutes: number;
  engineRunning = true;
  noUserId = false;

  constructor(private _route: ActivatedRoute, private _dataSvc: DataService) {}

  ngOnInit() {
    this._route.params.subscribe(params => {
      this.durationMinutes = params['durationMinutes'];
    });

    this.runEngine();
  }

  runEngine() {
    this._dataSvc
      .runSchedulingEngine(this.durationMinutes)
      .subscribe(result => {
        this.engineRunning = false;

        if (!result) {
          this.noUserId = true;
        }
      });
  }
}
