<div *ngIf="!contractor">Not in list selected</div>
<address *ngIf="contractor">
  <strong>{{ contractor.contactTitle }}</strong
  ><br />
  {{ contractor.address.address1 }}<br />
  {{ contractor.address.address2 }}<br *ngIf="contractor.address.address2" />
  {{ contractor.address.city }}, {{ contractor.address.state }}
  {{ contractor.address.zip }}<br /><br />
  <abbr title="Business Phone">Bus. Ph:</abbr>
  {{ contractor.businessPhone?.number }}<br />
  <abbr title="Fax Number">Fax:</abbr> {{ contractor.contactFaxNumber?.number
  }}<br />
  <abbr title="Business Cell">Cell:</abbr> {{ contractor.businessCell?.number }}
</address>

<address *ngIf="contractor">
  <a href="mailto:#">{{ contractor.contactEmail?.email }}</a>
</address>
