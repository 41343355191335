import { GeoJSONDataEntity } from './../../../models/data-entities/geojson-data-entity';
import {
  ExportEntityOption,
  ItemSearchOptionField,
  ItemSearchOptionFieldOption
} from './../../filter-list/models/filterClasses';
import {
  ContractorService,
  SecurityService,
  Utilities,
  ExportFormat
} from 'src/app/services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Actions, Workflow } from '../../../models';
import { WorkflowService, WorkflowContextService } from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import {
  FilterBuilderParam,
  FilterBuilderOutput,
  translateFilterBuilderToLegacy
} from '../../../models/filter-builder';
import { SavedColumn } from '../../../models/saved-filter';
import { ComponentIds } from 'src/app/models/component-ids';
import { ExportColumn } from 'src/app/models/activities';
import { toJSON } from 'esri/identity/IdentityManager';
import { DataEntitiesExportParameters } from 'src/app/models/exportOption';
import {
  ShapefileValidationCriteria
} from 'src/app/models/scheduled-export';
import { DataSet } from 'src/app/models/report-data-set';
import { ReportService } from 'src/app/services/report.service';
import { ReportDataSetFieldDataType } from 'src/app/models/custom-report';

@Component({
  selector: 'wm-workflow-export',
  templateUrl: './workflow-export.component.html',
  styleUrls: ['./workflow-export.component.css']
})
export class WorkflowExportComponent implements OnInit, OnDestroy {

@ViewChild('tabset', { static: true }) tabset: any;


  id = ComponentIds.DATA_EXPORT;
  dsId = ComponentIds.DATA_SET_EXPORT;
  name = new UntypedFormControl('');
  selDS = new UntypedFormControl('');
  form: any;
  exportId: string;
  SelectedWorkflow: Workflow;
  graph: mxGraph;
  exportOptions: { id: string; name: string }[];
  showArchived: false;
  workflows: Workflow[];
  selectedEntityValues: string[]; // DataEntity[];
  s: string[];
  exporting = false;
  exportDisabled = true;
  showDownloadLink = false;
  title: string;
  shapefileValidationCriteria = new ShapefileValidationCriteria();

  public dsParams: FilterBuilderParam[];
  public dsExportColumns: string[];

  CONTRACTORS_EXPORT_ITEM = 'contractors';
  CONTRACTOR_TYPE_PARAM_ID = 'contractorType';

  public params: FilterBuilderParam[] = [
    {
      name: 'Application Number',
      id: 'applicationNumber'
    },
    {
      name: 'Description',
      id: 'description'
    },
    {
      name: 'Applicant Name',
      id: 'applicantsName'
    },
    {
      name: 'Date Created',
      id: 'dateCreated',
      inputType: 'date',
      types: ['is', 'range']
    },
    {
      name: 'Submitter',
      id: 'submitterId',
      types: ['is']
    },
    {
      name: 'Status',
      id: 'status',
      types: ['is', 'in'],
      options: [
        {
          name: 'Not Started',
          value: '0'
        },
        {
          name: 'In Progress',
          value: '1'
        },
        {
          name: 'Completed',
          value: '2'
        },
        {
          name: 'Voided',
          value: '3'
        },
        {
          name: 'Rejected',
          value: '4'
        },
        {
          name: 'Discarded',
          value: '5'
        }
      ]
    },
    {
      name: 'Parcel Address',
      id: 'parcelAddress'
    },
    {
      name: 'Parcel City',
      id: 'parcelCity'
    },
    {
      name: 'Parcel Id',
      id: 'parcelId'
    },
    {
      name: 'Parcel Owner',
      id: 'parcelOwner'
    },
    {
      name: 'Current Activity',
      id: 'currentActivity'
    }
  ];
  public filters: ItemSearchOptionField[];
  public dsFilters: ItemSearchOptionFieldOption[];
  dsStaticFilters: ItemSearchOptionField[];
  staticFilters: ItemSearchOptionField[] = [];

  private contractorTypeParam: FilterBuilderParam;

  private clientSubscription: Subscription;

  public exportFormat: ExportFormat;
  public useExcelFormat: boolean;

  public dataSets: DataSet[];
  public dataSetId: string;

  private filterForm: UntypedFormGroup;
  private canCreateDataSet: boolean;


  constructor(
    private sanitizer: DomSanitizer,
    private _workflowSvc: WorkflowService,
    private _context: WorkflowContextService,
    private route: ActivatedRoute,
    private _fb: UntypedFormBuilder,
    private _contractorSvc: ContractorService,
    private _router: Router,
    private _ref: ChangeDetectorRef,
    private _securitySvc: SecurityService
  ) {}

  exportEntityOptions: {
    name: string;
    label: string;
    includeInExport: boolean;
  }[];

  dsColumnOptions: {
    name: string;
    label: string;
    includeInExport: boolean;
  }[];

  columns: SavedColumn[];

  link = document.createElement('a');

  async loadWorkflows() {
    this.showArchived = false;
    const workflows = await this._workflowSvc.getExportWorkflows().toPromise();

    const options = workflows.map(w => {
      return {
        id: w.id,
        name: w.name
      };
    });

    options.push({
      id: this.CONTRACTORS_EXPORT_ITEM,
      name: 'Contractors'
    });
    options.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
    this.exportOptions = options;

    const contractorTypes = await this._contractorSvc
      .getContractorRegistrationTypes(this._context.client)
      .toPromise();

    this.contractorTypeParam = {
      name: 'Contractor Type',
      id: this.CONTRACTOR_TYPE_PARAM_ID,
      types: ['is'],
      options: contractorTypes.map(ct => ({ name: ct.name, value: ct.id }))
    };
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  exportExcel() {
    this.useExcelFormat = true;
    this.exportFormat = ExportFormat.Excel;
    this.exportData();
  }

  exportCsv() {
    this.useExcelFormat = false;

    this.exportFormat = ExportFormat.CSV;
    this.exportData();
  }

  exportShapefile() {
    this.useExcelFormat = false;
    this.exportFormat = ExportFormat.ShapeFile;
    this.exportData();
  }

  exportData() {
    this.showDownloadLink = false;
    this.exporting = true;
    this.exportDisabled = true;

    if (!this.dataSetId) {
      const filtersWithWorkflowId = [...this.filters, ...this.staticFilters];

      // if any of these are geoJSON filters then mark them as such now... this will save me from having to check
      // when I get back up to the server.
      // oh wait, I can't yet not until I recognize the columns as a DataExportDataEntity... that will cause lots of testing in the filter builder component.  hold off for now.

      // this.columns.forEach(selectedDE => {
      //   this.geoJSONEntities.forEach(gde => {
      //     if (gde.name === selectedDE.name) {
      //       selectedDE.isGeoJSON = 'true';
      //     }
      //   });
      // });

      //1166
      const exportParameters: DataEntitiesExportParameters = {
        dataEntities: this.columns,
        additionalFilters: filtersWithWorkflowId,
        exportFormat: this.exportFormat,
        useExcelFormat: this.useExcelFormat
      };

      this._workflowSvc
        .exportEntities(this.exportId, exportParameters)
        .subscribe(response => {
          if (this.useExcelFormat) {
            const blob = new Blob([response], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            // const url = window.URL.createObjectURL(blob);
            this.link.href = window.URL.createObjectURL(blob);
            this.link.download = this.name.value.name + '.xlsx';
          } else {
            if (this.exportFormat === ExportFormat.ShapeFile) {
              const blob = new Blob([response], { type: 'application/zip' });
              this.link.href = window.URL.createObjectURL(blob);
              this.link.download = this.name.value.name + '.zip';
            } else {
              const blob = new Blob([response], { type: 'text/csv' });
              this.link.href = window.URL.createObjectURL(blob);
              this.link.download = this.name.value.name + '.csv';
            }
          }
          // left these outside the if/else because they should be set
          // the same regardless of export format
          this.exporting = false;
          this.exportDisabled = false;
          this.showDownloadLink = true;
        });
    } else {

      //master!!!
      //This is going to break something, although I don't know when or how.

      this._workflowSvc
        .exportDataSet({
          dataSetId: this.dataSetId,
          exportFormat: this.useExcelFormat
            ? ExportFormat.Excel
            : ExportFormat.CSV,
          columnsToInclude: this.dsExportColumns,
          filterInput: {
            inputValues: this.dsFilters
          }
        })
        .subscribe(response => {
          const ds = this.dataSets.find(ds => ds.id == this.dataSetId);
          if (this.useExcelFormat) {
            const blob = new Blob([response], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            // const url = window.URL.createObjectURL(blob);
            this.link.href = window.URL.createObjectURL(blob);
            this.link.download = ds.name + '.xlsx';
          } else {
            const blob = new Blob([response], { type: 'text/csv' });
            // const link = document.createElement('a');
            this.link.href = window.URL.createObjectURL(blob);
            // this.link.download = `daily-fee-report-export-${new Date().getTime()}.csv`;
            this.link.download = ds.name + '.csv';
            // link.click();
          }
          // left these outside the if/else because they should be set
          // the same regardless of export format
          this.exporting = false;
          this.exportDisabled = false;
          this.showDownloadLink = true;
        });
    }
  }

  ngOnInit() {

    if (this._context.client) {
      this.loadWorkflows();
      this.loadDataSets();
    } else {
      this.clientSubscription = this._context.client$.subscribe(() => {
        this.loadWorkflows();
        this.loadDataSets();
      });
    }

    this._securitySvc
      .isLoginEntitled(Actions.MANAGE_DATASETS)
      .subscribe(result => {
        this.canCreateDataSet = result;
      });

    this.form = new UntypedFormGroup({
      firstName: new UntypedFormControl()
    });
    this.form.addControl(
      'controlType',
      this._fb.control(false, [Validators.nullValidator])
    );
    this.form.addControl('selDS', this.selDS);
    this.route.params.subscribe(params => {
      if (params['workflowId']) {
        this.exportId = params['workflowId'];
      }
    });
  }

  ngOnDestroy() {
    if (this.clientSubscription) {
      this.clientSubscription.unsubscribe();
      this.clientSubscription = null;
    }
  }

  loadDataSets() {
    this._workflowSvc
      .getPublishedDataSets(this._context.client.id)
      .subscribe(dataSets => {
        this.dataSets = dataSets;
      });
  }


  filterFormChanged(e) {
    this.filterForm = null;
    this.filterForm = e;
  }

  switchDataSet() {
    if (this.selDS.value == 'create') {
      this._router.navigate([
        '/admin/jurisdiction',
        this._context.client.id,
        'reports',
        'data-set',
        Utilities.EMPTY_GUID
      ]);
    } else {
      this.dataSetId = this.selDS.value;
    }
  }


  switchWorkflowOption(): void {
    this.exportId = this.name.value.id;

    if (this.exportId) {
      // clear out the dataset fields
      this.dataSetId = null;
      this.dsColumnOptions = null;
      this.dsExportColumns = null;
      this.dsFilters = null;
      this.dsParams = null;
      this.dsStaticFilters = null;

      this._ref.detectChanges();

      const newExportIdFilter = new ItemSearchOptionField({
        title: 'exportId',
        options: [
          new ItemSearchOptionFieldOption({
            title: '',
            selected: true,
            filterText: '',
            searchText: this.exportId,
            strOperator: 'is',
            searchOptionMethod: ''
          })
        ]
      });

      let existingExportIdFilter: ItemSearchOptionField;

      if (this.staticFilters && this.staticFilters.length > 0) {
        existingExportIdFilter = this.staticFilters.find(
          f => f.title === 'exportId'
        );
        if (existingExportIdFilter) {
          Object.assign(existingExportIdFilter, newExportIdFilter);
        }
      } else {
        this.staticFilters.push(newExportIdFilter);
      }

      // get the list of entities
      let entityObs: Observable<ExportEntityOption[]> = null;

      if (this.exportId !== this.CONTRACTORS_EXPORT_ITEM) {
        entityObs = this._workflowSvc.getExportEntityOptions(this.exportId);

        // remove the contractor type filter param if the export type is not contractors
        this.params = this.params.filter(
          param => param.id !== this.CONTRACTOR_TYPE_PARAM_ID
        );
      } else {
        entityObs = this._contractorSvc.getExportEntityOptions();


      // entityObs = this._contractorSvc.getExportEntities();
      // // add the contractor type filter param if the export type is contractors
      // this.params.push(this.contractorTypeParam);

      entityObs = this._contractorSvc.getExportEntityOptions();
      // add the contractor type filter param if the export type is contractors
      this.params.push(this.contractorTypeParam);
    }

      this.exportEntityOptions = [];



    this.exportEntityOptions = [];

      entityObs.subscribe(e => {
        //  clear any currently selected options.
        this.exportDisabled = false;

      // exportEntityOptions is an object array here, but the filter builder component takes keyvalue pair.
      // there is a ticket to pass the exportEntityOption array to the filterbuilder, but it was considered out
      // of scope for the current ticket.
      this.exportEntityOptions = e.map(v => {
        return {
          name: v.name,
          label: v.header,
          includeInExport: v.isGeoJSON === true ? false : true
        };
      });

      this.shapefileValidationCriteria.geoJSONEntities = Object.assign(
        {},
        ...e.filter(v => v.isGeoJSON === true).map(v => ({ [v.name]: v }))
      );

        // set columns with exportEntityOptions' config, which defaluts to all entities selected
        // when you choose a workflow
        this.columns = e
        .filter(eeo => eeo.includeInExport)
        .map(eeo => ({
          header: eeo.header,
          name: eeo.name,
          isGeoJSON: eeo.isGeoJSON
          }));

        this.showDownloadLink = false;
      });

      this.title =
        this.exportOptions.find(eo => eo.id === this.exportId).name || '';
    } else {
      this.clearWorkflowParameters();
    }
  }

  filtersChanged(filters: FilterBuilderOutput) {
    this.filters = translateFilterBuilderToLegacy(filters);
  }

  dataSetParamsChanged(params: FilterBuilderParam[]) {
    this.dsParams = params;
  }


  clearWorkflowParameters() {
    this.exportId = null;
    this.staticFilters = [];
    this.filters = null;
    this.exportEntityOptions = null;
    this.title = null;
  }

  dataSetLoaded(ds: DataSet) {
    if (ds) {
      this.clearWorkflowParameters();

      this.dsColumnOptions = ds.publishedDataSetConfig.fields.map(f => {

        return {
          name: f.name,
          label: f.resultHeader || f.name,
          includeInExport: true
        };


      });

        this.shapefileValidationCriteria.geoJSONEntities = Object.assign(
          {},
          ...ds.publishedDataSetConfig.fields.filter(v => v.dataType ===  ReportDataSetFieldDataType.GISData).map(v => ({ [v.name]: v }))
        );
      this.dsStaticFilters = [
        {
          title: 'dataSetId',
          options: [
            {
              title: '',
              filterText: ds.id,
              searchText: ds.id,
              strOperator: 'is',
              selected: true,
              searchOptionMethod: ''
            }
          ]
        }
      ];


    }
  }

  dataSetFiltersChanged(filters: FilterBuilderOutput) {
    const filterKeys = Object.keys(filters);

    this.dsFilters = filterKeys.map(f => {
      const filter = filters[f];
      return new ItemSearchOptionFieldOption({
        title: filter.id,
        strOperator: filter.type,
        searchText: filter.value,
        filterText: filter.value
      });
    });
  }
  dataSetColumnsChanged(columns: string[]) {
    this.dsExportColumns = columns;
    //we aren't allowing datasets to have shapefile export yet.
    this.shapefileValidationCriteria.isGeoJSONSelected = false;
    this.shapefileValidationCriteria.showShapeFieldError = false;

  }

  columnsChanged(exportColumns: string[]) {

    this.shapefileValidationCriteria.isGeoJSONSelected = false;
    // colums are what is going to get sent to the items that gets exported.
    this.columns = this.exportEntityOptions
      .filter(eeo => exportColumns.includes(eeo.name))
      .map(eeo => {
        if (
          exportColumns.includes(eeo.name) &&
          this.shapefileValidationCriteria.geoJSONEntities[eeo.name]
        ) {
          this.shapefileValidationCriteria.isGeoJSONSelected = true;
        }

        return {
          header: eeo.label,
          name: eeo.name
        };
      });
    let geoDataEntityCount = 0;

    this.exportEntityOptions.forEach(eeo => {
      if (
        exportColumns.includes(eeo.name) &&
        this.shapefileValidationCriteria.geoJSONEntities[eeo.name]
      ) {
        geoDataEntityCount += 1;
        this.shapefileValidationCriteria.isGeoJSONSelected = true;
      }

      eeo.includeInExport = exportColumns.includes(eeo.name);
    });

    // child components don't detect changes when you just push it, you need to reassign the array.
    this.exportEntityOptions = this.exportEntityOptions.slice();

    if (
      this.columns.length - geoDataEntityCount >
        this.shapefileValidationCriteria.allowedExportFieldCount &&
      this.shapefileValidationCriteria.isGeoJSONSelected
    ) {
      this.shapefileValidationCriteria.showShapeFieldError = true;
    } else {
      this.shapefileValidationCriteria.showShapeFieldError = false;
    }
  }
}
