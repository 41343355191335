import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ViewChildren,
  QueryList,
  Input
} from '@angular/core';
import { DataEntityViewComponent } from '../../data-entity-view/data-entity-view.component';
import { SignatureFieldComponent } from '../signature-field/signature-field.component';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { SignatureDataEntity } from 'src/app/models/data-entities/signature-data-entity';
import { Point } from 'signature_pad/dist/types/point';

@Component({
  selector: 'wm-signature-data-entity-view',
  templateUrl: './signature-data-entity-view.component.html',
  styleUrls: ['./signature-data-entity-view.component.css']
})
export class SignatureDataEntityViewComponent extends DataEntityViewComponent
  implements OnInit {
  @ViewChildren(SignatureFieldComponent) public sigs: QueryList<
    SignatureFieldComponent
  >;
  @Input() form: UntypedFormGroup;
  imageDataPath: string;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    private _fb: UntypedFormBuilder
  ) {
    super(componentFactoryResolver);
  }

  ngOnInit() {
    let s: SignatureDataEntity;
    s = <SignatureDataEntity>this.entity;

    let value: { data: Array<Point[]>; image: string };

    if (typeof (this.entity.value) === 'string') {
      if ((this.entity.value || '') !== '') {
        value = JSON.parse(this.entity.value as string);
      }
    } else {
      value = this.entity.value;
    }

    if (value) {
      this.imageDataPath = value.image;
    }

  }

}
