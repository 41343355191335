import { UserApplicationsComponent } from './../views/user-applications/user-applications.component';
import { Injectable, Inject, forwardRef, Pipe } from '@angular/core';
import { Observable, from, throwError, of, forkJoin } from 'rxjs';
import { map, concatMap, take, catchError, flatMap } from 'rxjs/operators';
import * as _ from 'underscore';

import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { DataService } from './data.service';
import { WorkflowContextService } from './workflow-context.service';
import {
  Client,
  Role,
  User,
  Actions,
  Securable,
  Workflow,
  Permission,
  Action,
  LoginResponse,
  UserSearchOptions,
  ContractorType,
  SaveUserResponse
} from '../models';
import { ScreenActivity, ActivityModel } from '../models/activities';
import { ClientService } from './client.service';
import { AuthenticationService } from './authentication.service';
import { JsonNetDecycle } from './utilities/json-net-decycle';
import { Utilities } from '.';
import {
  InviteUserRequest,
  InviteUserResponse,
  InvitedUser
} from '../models/invited-user';
import { CanDeleteRoleResponse } from '../models/can-delete-role-response';

@Injectable()
export class SecurityService {
  getUserInvitation(invitationId: string): Observable<InvitedUser> {
    return this._dataService.getUserInvitation(invitationId);
  }
  inviteUser(request: InviteUserRequest): Observable<InviteUserResponse> {
    return this._dataService.inviteUser(request);
  }
  public static APPLICANT_ROLE: Role = {
    id: '46F061D0-CA05-4526-87AD-BAE6256F3E47',
    name: 'Applicant',
    client: null,
    members: null,
    actions: null
  };

  userActions: { [key: string]: string[] };
  viewAsActions: string[];
  // _dataService: DataService;

  static isEntitled(
    securable: Securable,
    roleId: string,
    actionId: string
  ): boolean {
    return true;
  }

  // static APPLICANT_ROLE_ID: string = '46F061D0-CA05-4526-87AD-BAE6256F3E47';
  clearPermissionCache() {
    // throw new Error('Method not implemented.');
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    // return this.permissions.canActivate(this.currentUser, route.params.id);

    if (!this._context.isLoggedIn) {
      this.router.navigate(['/admin/global/unauthorized']);
      return false;
    }

    return true;
  }

  constructor(
    @Inject(forwardRef(() => DataService)) private _dataService: DataService,
    @Inject(forwardRef(() => WorkflowContextService))
    private _context: WorkflowContextService,
    @Inject(forwardRef(() => ClientService)) private _clientSvc: ClientService,
    @Inject(forwardRef(() => AuthenticationService))
    private _authSvc: AuthenticationService,
    private router: Router
  ) {}

  isRoleNameAvailable(
    clientId: string,
    templateCode: string,
    roleId: string
  ): Observable<any> {
    return this._dataService.isRoleNameAvailable(
      clientId,
      templateCode,
      roleId
    );
  }
  getSecurableActions(securable: Securable): Observable<string[]> {
    if (securable instanceof Workflow) {
      return this._dataService.getAvailableWorkflowActions(securable); // securable.availableActions;
    } else if (securable instanceof ContractorType) {
      return this._dataService.getAvailableContractorTypeActions(securable); // securable.availableActions;
    }
  }

  getPermissionsForWorkflow(
    securable: Securable
  ): Observable<{ [key: string]: { [key: string]: Partial<Permission> } }> {
    return this._dataService.getPermissionsForWorkflow(securable);
  }

  getPermissionsForContractorType(
    securable: Securable
  ): Observable<{ [key: string]: { [key: string]: Partial<Permission> } }> {
    return this._dataService.getPermissionsForContractorType(securable);
  }

  getActionInformation(action: string): Partial<Action> {
    return Actions.ActionInformation[action];
  }

  getAvailableActions(role: Partial<Role>): Observable<Action[]> {
    let actions: Action[];
    let userActions: { [key: string]: string[] };

    const actionObs = this._dataService
      .getAllActions(this._context.client ? this._context.client.id : null)
      .pipe(map(a => (actions = a)));
    const userActionObs = this._dataService
      .getActionsForUser(this._context.user)
      .pipe(map(a => (userActions = a)));

    return forkJoin([actionObs, userActionObs]).pipe(
      map(() => {
        let groupActionIds: string[];
        if (role.actions) {
          groupActionIds = role.actions.map((permission: Permission) => {
            return permission.actionId;
          });
        }

        return actions.filter(
          // start with all actions
          a =>
            (userActions[Actions.DO_ANYTHING.toLowerCase()] ||
              userActions[`${a.id}|${role.clientId}`]) && // if user is global admin, don't remove anything.  if not global admin, remove any actions that are not in userActions
            groupActionIds.indexOf(a.id) === -1 // remove actions that are already granted to role
        );
      })
    );
  }

  loadUserActions(user: User): Observable<{ [key: string]: string[] }> {
    if (user && this.userActions == null) {
      return this._dataService.getActionsForUser(user).pipe(
        map(
          _.bind(actions => {
            this.userActions = actions;
            return actions;
          }, this)
        )
      );
    } else {
      return of(this.userActions);
    }
  }

  addPermission(
    role: Partial<Role>,
    actionId: string,
    securableId?: string
  ): Observable<Permission> {
    return this._dataService.addPermission(role.id, actionId, securableId);
  }

  removePermission(
    role: Partial<Role>,
    actionId: string,
    securableId?: string
  ): Observable<boolean> {
    return this._dataService.removePermission(role.id, actionId, securableId);
  }

  getSecurablePermissions(
    securable: Securable
  ): Observable<{ [key: string]: { [key: string]: Partial<Permission> } }> {
    if (securable instanceof Workflow) {
      return this._dataService.getPermissionsForWorkflow(securable);
    } else if (securable instanceof ContractorType) {
      return this._dataService.getPermissionsForContractorType(securable);
    }
  }

  getInheritedPermissions(
    securable: Securable
  ): { [roleGroupId: string]: Partial<Permission> } {
    const permissions: { [roleGroupId: string]: Partial<Permission> } = {};

    if (securable) {
      if (securable instanceof Workflow) {
        // || ActivityFactory.isScreenActivity(securable as Activity<ActivityModel>)) {
        const that = this;

        securable.availableActions.forEach((action: string) => {
          // set all available Actions to enabled as inherited by the Workflow Admin Role
          if (that._context.workflow.version.graph.adminRole) {
            if (
              !permissions[that._context.workflow.version.graph.adminRole.id]
            ) {
              permissions[that._context.workflow.version.graph.adminRole.id] = {
                role: that._context.workflow.version.graph.adminRole,
                actions: [],
                isInherited: true,
                inheritanceMessage:
                  'This permission is inherited by the role being the Client Administrator.'
              };
            }

            permissions[
              that._context.workflow.version.graph.adminRole.id
            ].actions.push(action);
          }

          if (that._context.client.adminRole) {
            // set all available Actions to enabled as inherited by the Client Admin Role
            if (!permissions[that._context.client.adminRole.id]) {
              permissions[that._context.client.adminRole.id] = {
                role: that._context.client.adminRole,
                actions: [],
                isInherited: true,
                inheritanceMessage:
                  'This permission is inherited by the role being the Workflow Administrator.'
              };
            }

            permissions[that._context.client.adminRole.id].actions.push(action);
          }

          if (
            that._context.activeActivity &&
            (that._context.activeActivity as ScreenActivity<ActivityModel>)
              .responsibleRole
          ) {
            // set all available Actions to enabled as inherited by the Client Admin Role
            if (
              !permissions[
                (that._context.activeActivity as ScreenActivity<ActivityModel>)
                  .responsibleRole.id
              ]
            ) {
              permissions[
                (that._context.activeActivity as ScreenActivity<
                  ActivityModel
                >).responsibleRole.id
              ] = {
                role: (that._context.activeActivity as ScreenActivity<
                  ActivityModel
                >).responsibleRole,
                actions: [],
                isInherited: true,
                inheritanceMessage:
                  'This permission is inherited by the role being responsible for the Activity.'
              };
            }

            permissions[
              (that._context.activeActivity as ScreenActivity<ActivityModel>)
                .responsibleRole.id
            ].actions.push(action);
          }
        });
      }
    }

    return permissions;
  }

  isSystemAdministrator(): Observable<boolean> {
    const actions = from([Actions.DO_ANYTHING]);

    const result = Observable.create(o => {
      if (this._context.user) {
        actions
          .pipe(
            concatMap((action: string) => {
              return this.isLoginEntitled(action);
            })
          )
          .pipe(take<boolean>(1))
          .pipe(
            map((a: boolean) => {
              return a === true;
            })
          )
          .subscribe(
            actionResults => {
              o.next(actionResults);
            },
            e => {
              console.error(e);
            }
          );
      } else {
        o.next(false);
      }
    });

    return result;
  }

  isAnAdministrator(): Observable<boolean> {
    const actions = from(Actions.AdministratorActions);

    return actions
      .pipe(
        concatMap((action: string) => {
          // TODO: 5/7/2019 - Need to figure out a better solution for this
          if (this._context.viewAsRole) {
            return this.isRoleEntitled(action, this._context.viewAsRole);
          } else {
            return this.isLoginEntitled(action);
          }
        })
      )
      .pipe(take<boolean>(1))
      .pipe(
        map((a: boolean) => {
          return a === true;
        })
      );
  }

  // This checks to see if the currently logged in user is entitied to perform the requested function on the specified securable
  isLoginEntitled(
    action: string,
    securable: Securable = null,
    actions: string[] = null,
    global = false
  ): Observable<boolean> {
    const func = map(() => {
      if (action) {
        return this.isEntitled(
          action,
          this.userActions,
          securable ? securable.id : null
        );
      } else if (actions) {
        let entitledAtleastOne = false;

        for (let idx = 0; idx < actions.length; idx++) {
          if (
            this.isEntitled(
              actions[idx],
              this.userActions,
              securable ? securable.id : null
            )
          ) {
            entitledAtleastOne = true;
            break;
          }
        }

        return entitledAtleastOne;
      }
    });

    // there are certain actions and situations where we don't want to wait on a client.
    const isAdminAction =
      Actions.AdministratorActions.indexOf(action) > -1 ||
      action === Actions.DO_ANYTHING ||
      global;

    // this is here to handle the delayed client set when switching clients
    const obs =
      !this._context.client && !isAdminAction
        ? this._context.client$
        : of(this._context.client);

    if (!this.userActions) {
      return this._dataService.getActionsForUser(this._context.user).pipe(
        map(userActions => {
          this.userActions = userActions;
          return userActions;
        }),
        func
      );
    } else {
      return obs.pipe(func);
    }
  }

  // This checks tos ee if the specified role is entitiled to perform the requested function on the specified securable
  isRoleEntitled(
    action: string,
    role: Role,
    securable: Securable = null
  ): Observable<boolean> {
    const func = map((a: { [key: string]: string[] }) => {
      return this.isEntitled(action, a);
    });

    const obs = Observable.create(o => {
      o.next(func);
    });

    return this._dataService
      .getActionsForRole(this._context.viewAsRole.id)
      .pipe(func);
  }

  private isEntitled(
    action: string,
    actions: { [key: string]: string[] },
    securableId?: string
  ): boolean {
    // If the user is in a role that has the action DO_ANYTHING associated with it then no need to check the other permissions
    let key = Actions.DO_ANYTHING.toLowerCase();
    if (actions && actions[key]) {
      return true;
    }

    // check to see if the user is in a role that has permission to the action requested
    key =
      action.toLowerCase() +
      '|' +
      (this._context.client ? this._context.client.id : '');
    const globalKey = action.toLowerCase();
    let result =
      actions && (actions[globalKey] != null || actions[key] != null);

    if (result && securableId) {
      result =
        actions[key].length === 0 || actions[key].indexOf(securableId) > -1;
    }
    return result;
  }

  changePwd(
    username: string,
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
  ): Observable<Partial<User>> {
    return this._authSvc
      .changePwd(username, oldPassword, newPassword, confirmPassword)
      .pipe(
        map(user => {
          if (user != null) {
            if (user) {
              this._context.isLoggedIn$.next(true);
            } else {
              this._context.isLoggedIn$.next(false);
            }
            this._context.user$.next(user);
          } else {
            return null;
          }
        })
      );
  }

  clearSession() {
    this._context.isLoggedIn$.next(false);
    this._context.authToken$.next(null);
    this._context.authTokenExpiration$.next(null);
    this._context.refreshToken$.next(null);
    this._context.user$.next(null);
    this.userActions = null;
    this.router.navigate(['/']);
    this.userActions = null;
  }

  authenticateByToken(token: string): Observable<LoginResponse> {
    return this._authSvc.authenticateByToken(token).pipe(
      concatMap((value: LoginResponse, index: number) => {
        if (value.access_Token) {
          this._context.authToken$.emit(value.access_Token);
          const expiresAt = value.expires_in * 1000 + Date.now();
          this._context.authTokenExpiration$.next(expiresAt);
          this._context.refreshToken$.emit(value.refresh_Token);
        }

        return of(value);
      }),
      concatMap(res => {
        // Get User details
        return this._authSvc.getUserByLogin(res.user.userName).pipe(
          map(userDetails => {
            return { userDetails, res };
          })
        );
      }),
      concatMap((res: { userDetails: User; res: LoginResponse }) => {
        return this._dataService.getActionsForUser(res.userDetails).pipe(
          map(result => {
            this.userActions = result;
            this._context.user$.emit(res.userDetails);
            res.res.loginValid = true;
            this._context.isLoggedIn$.emit(true);
            return res.res;
          })
        );
      })
    );
  }

  authenticate(): Observable<LoginResponse> {
    return this._authSvc.authenticate().pipe(
      map((res: LoginResponse) => {
        let msg: string;

        if (res.access_Token) {
          this._context.isLoggedIn$.next(true);
          this._context.authToken$.emit(res.access_Token);
          const expiresAt = res.expires_in * 1000 + Date.now();
          this._context.authTokenExpiration$.next(expiresAt);
          this._context.refreshToken$.emit(res.refresh_Token);
        } else {
          msg = res.errorMessage; // "Username or password is invalid.";
          this.clearSession();
        }

        return res;
      }),
      catchError((err: any) => {
        this.clearSession();
        return throwError(err);
      })
    );
  }
  login(user: Partial<User>): Observable<LoginResponse> {
    const encodedUserName = encodeURIComponent(user.userName);
    const encodedPass = encodeURIComponent(user.password);

    return this._authSvc.login(encodedUserName, encodedPass).pipe(
      concatMap((value: LoginResponse, index: number) => {
        if (value.access_Token) {
          this._context.authToken$.emit(value.access_Token);
          const expiresAt = value.expires_in * 1000 + Date.now();
          this._context.authTokenExpiration$.next(expiresAt);
          this._context.refreshToken$.emit(value.refresh_Token);
        }

        return of(value);
      }),
      concatMap(res => {
        // Get User details
        return this._authSvc.getUserByLogin(encodedUserName).pipe(
          map(userDetails => {
            return { userDetails, res };
          })
        );
      }),
      concatMap((res: { userDetails: User; res: LoginResponse }) => {
        return this._dataService.getActionsForUser(res.userDetails).pipe(
          map(result => {
            this.userActions = result;
            this._context.user$.emit(res.userDetails);
            res.res.loginValid = true;
            this._context.isLoggedIn$.emit(true);
            return res.res;
          })
        );
      })
    );
  }
  logout(): Observable<boolean> {
    return this._authSvc.logOut(this._context.user).pipe(
      map(result => {
        this.clearSession();
        return result;
      })
    );
  }
  getRolesWithAnyActions(
    clientId: string,
    actions: string[]
  ): Observable<Role[]> {
    return this._dataService.getRolesWithAnyActions(clientId, actions);
  }
  getRolesWithAllActions(
    clientId: string,
    actions: string[]
  ): Observable<Role[]> {
    return this._dataService.getRolesWithAllActions(clientId, actions);
  }
  getRoles(client: Client): Observable<Role[]> {
    return this._dataService.getRoles(client);
  }
  getAllRoles(client: Client): Observable<Role[]> {
    return this._dataService.getAllRoles();
  }
  searchRoles(client: Client, searchText: string): Observable<Role[]> {
    return this._dataService.searchRoles(client, searchText);
  }
  searchUsers(client: Client, options: UserSearchOptions): Observable<User[]> {
    return this._dataService.searchUsers(client, options);
  }
  getUsers(): Observable<User[]> {
    return this._authSvc.getUsers();
  }
  getAdminUsers(): Observable<User[]> {
    return this._authSvc.getAdminUsers();
  }
  getUser(userId: string): Observable<User> {
    return this._authSvc.getUser(userId);
  }
  saveUser(user: Partial<User>): Observable<SaveUserResponse> {
    return this._authSvc.saveUser(user);
  }
  deleteUser(user: Partial<User>): Observable<User> {
    return this._authSvc.deleteUser(user);
  }
  enableUser(user: User): Observable<SaveUserResponse> {
    user.isEnabled = true;
    user.disabledOn = null;
    return this._authSvc.saveUser(user);
  }
  disableUser(user: User): Observable<SaveUserResponse> {
    user.isEnabled = false;
    user.disabledOn = new Date().toUTCString();
    return this._authSvc.saveUser(user);
  }
  getRole(id: string): Observable<Role> {
    return this._dataService.getRole(id).pipe(
      map(r => {
        if (id.toUpperCase() === SecurityService.APPLICANT_ROLE.id) {
          return SecurityService.APPLICANT_ROLE;
        } else {
          return r;
        }
      })
    );
  }
  saveRole(role: Partial<Role>): Observable<Role> {
    return this._dataService.saveRole(role);
  }
  canDeleteRole(role: Partial<Role>): Observable<CanDeleteRoleResponse> {
    return this._dataService.canDeleteRole(role);
  }
  deleteRole(role: Partial<Role>): Observable<Role> {
    return this._dataService.deleteRole(role);
  }
  getUserRoles(userId: string): Observable<Role[]> {
    return this._dataService.getUserRoles(userId);
  }
  resetPassword(userName: string, redirectURL?: string): Observable<boolean> {
    // perform logic to reset password
    return this._authSvc.resetPassword(userName, redirectURL);
  }
  getUserListByEmail(email: string): Observable<User[]> {
    return this._authSvc.getUserListByEmail(email);
  }
  getUsersByEmail(email: string, redirectURL?: string): Observable<boolean> {
    // perform logic to get usernames by email
    return this._authSvc.getUsersByEmail(email, redirectURL);
  }
  associateRole(user: User, role: Partial<Role>): Observable<Role> {
    return this._dataService.associateRole(user, role);
  }
  removeUserRole(userId: string, roleId: string): Observable<boolean> {
    return this._dataService.removeUserRole(userId, roleId);
  }
  verifyUser(token: string): Observable<User> {
    return this._authSvc.verifyUser(token);
  }
  registerUser(
    user: User,
    returnUrl?: string,
    generatePassword: boolean = false,
    invitationId?: string
  ): Observable<LoginResponse> {
    return this._authSvc.registerUser(
      user,
      returnUrl,
      generatePassword,
      invitationId
    );
  }
  // registerUserFromApplication(
  //   user: User,
  //   applicationId: string,
  //   returnUrl?: string
  // ): Observable<LoginResponse> {
  //   return this._authSvc.registerUserFromApplication(user, applicationId, returnUrl);
  // }
  saveResetPassword(
    userName: string,
    token: string,
    newPassword: string
  ): Observable<LoginResponse> {
    return this._authSvc.saveResetPassword(userName, token, newPassword).pipe(
      concatMap(result => {
        if (result.loginValid) {
          if (!this._context.user || userName !== this._context.user.userName) {
            return this.login({ userName, password: newPassword });
          } else {
            return of(result);
          }
        } else {
          return of(result);
        }
      })
    );
  }
  createUser(): User {
    return new User({
      id: Utilities.EMPTY_GUID,
      name: '',
      userName: '',
      password: '',
      isEnabled: true,
      emailAddress: null,
      address: null,
      phoneNumber: null
    });
  }
  createRole(): Role {
    return new Role({
      id: Utilities.EMPTY_GUID,
      client: this._context.client, // this._clientSvc.createClient(),
      name: '',
      actions: [],
      members: []
    });
  }
}
