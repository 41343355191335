import { ReviewActivity } from 'src/app/models/activities/review-activity';
import {
  ParentChildWorkflowNamed,
  ParentChildWorkflowNamedResponse
} from './../../../models/ParentChildWorkflowNamed';
import { ApplicationDiagramInfo } from './../../../services/data.service';
import { ValidationService } from './../../../services/validation.service';
import {
  SingleColumnFormLayoutModel,
  FormActivity
} from './../../../models/activities/form-activity';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmComponent } from './../../system/modal-confirm/modal-confirm.component';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder
} from '@angular/forms';
import { ConditionTarget } from './../../system/condition-builder/condition-builder.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  DiagramControlsService,
  DiagramControlColor
} from './../../../services/diagram-controls.service';
import { WorkflowValidationService } from './../../../services/workflow-validation.service';
import {
  ValidationType,
  ValidationSeverity,
  ValidationResponse
} from 'src/app/models/validation';
import { EventEmitter, HostListener } from '@angular/core';
import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { Workflow, WorkflowType, ApplicationStatus } from '../../../models';
import {
  WorkflowContextService,
  WorkflowService,
  Utilities,
  SecurityService,
  ActivityFactory,
  BaseCellModel
} from '../../../services';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActivityModel,
  Activity,
  RouteDefinition,
  DecisionActivity,
  DecisionActivityModel,
  RouteCriteria,
  OnStartedActivity,
  OnCompletedActivity,
  ActivityStatus
} from '../../../models/activities';
import { of, Observable, Subscription, empty, EMPTY } from 'rxjs';
import { WorkflowGraph } from '../../../models/workflow-graph';
import { PermitWorkflowGraph } from '../../../models/permit-workflow-graph';
import * as _ from 'lodash';
import { WorkflowVersion } from '../../../models/workflow-version';
import { WorkflowActivitySidebarComponent } from '../workflow-activity-sidebar/workflow-activity-sidebar.component';
import { PreviousRouteService } from '../../../services/previous-route';
import {
  WorkflowValidityModalComponent,
  ValidationError
} from '../workflow-validity-modal/workflow-validity-modal.component';

import * as Mousetrap from 'mousetrap';
import { StartApplicationResponse } from 'src/app/models/start-application-response';
import { StopWatch } from '../workflow-activity-preview-modal/workflow-activity-preview-modal.component';
import { SavingChange } from 'src/app/models/saving-change';
// import { stringify } from 'querystring';

export interface ActivityItem {
  name: string;
  description: string;
  type: string;
}

const { ACTIVITIES } = WorkflowService;

@Component({
  selector: 'wm-workflow-diagram',
  templateUrl: './workflow-diagram.component.html',
  styleUrls: ['./workflow-diagram.component.css']
})
export class WorkflowDiagramComponent
  implements OnInit, AfterViewInit, OnDestroy {
  workflowId: string;
  versionId: string;
  applicationId: string;
  graph: mxGraph;
  editor: mxEditor;
  layout: mxHierarchicalLayout;
  layoutMgr: mxLayoutManager;
  outline: mxOutline;
  parent: mxCell;
  availableActivities: Array<ActivityItem> = [];
  contractorActivities: string[] = [
    ACTIVITIES.ContractorInfo,
    ACTIVITIES.ContractorRegistrationDecision,
    ACTIVITIES.ContractorRegistrationInfo,
    ACTIVITIES.ReviewContractorRegistration,
    ACTIVITIES.ContractorRenewalDecision,
    ACTIVITIES.ContractorRegistrationCertificateActivity
  ];
  renewalActivities: string[] = [
    ACTIVITIES.RenewalReview,
    ACTIVITIES.RenewalDecision,
    ACTIVITIES.RenewalCertificateActivity
  ];
  onlyInRegularWorkflowActivities: string[] = [
    ACTIVITIES.ReviewActivity
  ];
  agendaActivities: string[] = [ACTIVITIES.AgendaItemActivity];
  saving = false;
  pretendToBeSaving = false;
  // TODO: Improve detection of unsaved changes!
  saved = true;
  pauseSaves = false;
  loaded = false;
  isValidating = false;
  validity: ValidationResponse[] = [];
  validityErrors: ValidationResponse[] = [];
  sw = new StopWatch(false);
  searchResults: {
    type: string;
    id: string;
    activityId: string;
    activityName: string;
    templateCode: string;
    dataEntityLabel: string;
  }[];
  searchInput: string;
  isSearching = false;

  saveInterval: any;

  activityAdded: EventEmitter<any> = new EventEmitter();
  fallbackScale = 1;
  currentScale = window.devicePixelRatio || this.fallbackScale;
  publishForm: UntypedFormGroup;
  warningsForm: UntypedFormGroup;
  appDebugForm: UntypedFormGroup;
  public appActivities: Activity<ActivityModel>[] = null;

  activityCellIndex: {
    [activityId: string]: { cell: mxCell; activity: Activity<ActivityModel> };
  };

  @ViewChild('diagram', { static: true }) diagramContainer: ElementRef;
  @ViewChild('wfActivitySidebar', { static: true })
  activitySidebar: WorkflowActivitySidebarComponent;
  @ViewChild('publishModal', { static: false }) publishModal: ModalConfirmComponent;
  @ViewChild('debugInfoModal', { static: false }) debugInfoModal: ModalConfirmComponent;
  @ViewChild('appActivityDataModal', { static: false })
  appActivityDataModal: ModalConfirmComponent;

  // listeners
  cellAddedListener: any;
  cellRemovedListener: any;
  cellSelectionChangedListener: any;
  keyupListener: any;
  pointerdownListener: any;
  doubleClickListener: any;
  singleClickListener: any;
  activityAddedSub: Subscription;
  activitySidebarSub: Subscription;
  centerOnActivitySub: Subscription;
  activitySidebarCloseSub: Subscription;
  validationModalClickSub: Subscription;
  changes: SavingChange[] = [];
  savingChanges: SavingChange[] = [];
  graphLoading = false;
  debugInformation: string;

  validatingListener: Subscription;
  validatedListener: Subscription;

  activityClipboard: Activity<ActivityModel>[];
  savingListener: Subscription;
  savedListener: Subscription;
  workflowSearchVisible = false;
  saveEnabled = true;
  public _appDiagramInfo: ApplicationDiagramInfo;
  public viewingIteration = null;

  public entitybob = {
    childWorkflowVersionId: '123',
    parentWorkflowVersionId: '123',
    activityId: '',
    workflowName: 'bob'
  };
  public entitydon = {
    childWorkflowVersionId: '456',
    parentWorkflowVersionId: '456',
    activityId: '',
    workflowName: 'don'
  };
  public entitygeorge = {
    childWorkflowVersionId: '789',
    parentWorkflowVersionId: '789',
    activityId: '',
    workflowName: 'george'
  };
  public parentChildWorkflows: ParentChildWorkflowNamedResponse = {
    parentWorkflows: [],
    childWorkflows: []
  };
  public newMapping = {
    sourceWorkflow: '1',
    targetWorkflow: '1',
    sourceDataEntity: ''
  };
  dataMappingForm!: UntypedFormGroup;
  childEntities: any = [];

  constructor(
    private _workflowSvc: WorkflowService,
    public _context: WorkflowContextService,
    private route: ActivatedRoute,
    private _router: Router,
    private _previousRouteSvc: PreviousRouteService,
    private _validation: WorkflowValidationService,
    public _diagramCtrls: DiagramControlsService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private _fb: UntypedFormBuilder
  ) {
    this._context.activeActivity$.next(null);
  }

  onChangeDiagram(e) {
    this.changeDiagram(parseInt(e.srcElement.value, 10));
  }

  changeDiagram(status) {
    this._context.workflow.designStatus = <ApplicationStatus>status;
    this.resetAndBuildGraph(this._context.workflow, false).subscribe();
  }
  @HostListener('window:beforeunload', ['$event'])
  handleCloseEvent(evt): boolean {
    if (this.saving || (this.changes || []).length > 0) {
      evt = window.event;
      evt.cancelBubble = true;
      if ((evt.type || '') === 'beforeunload') {
        evt.returnValue =
          'Closing the browser while saving will result in lost changes.';
      } else {
        confirm('Leaving this page while saving will result in lost changes.');
      }
      return false;
    }

    return true;
  }

  isReadOnly(): boolean {
    return (this.versionId || '') !== '';
  }
  async getMappingWorkflows() {
    this._workflowSvc
      .getWorkflowVersionDataEntities(
        '090511A8-1746-4861-AD84-5499191D45FD',
        null,
        true
      )
      .toPromise();
  }
  navigateToWorkflow(e) {
    const activityIdList = this.parentChildWorkflows.parentWorkflows.filter(
      w => w.activityId.toUpperCase() === e.target.value.toUpperCase()
    );

    if (activityIdList.length > 0) {
      const activityId = activityIdList[0].activityId;
      const url = this._router.serializeUrl(
        this._router.createUrlTree(
          ['/admin/workflow-builder', activityIdList[0].sourceWorkflowId],
          { queryParams: { activityId: activityId } }
        )
      );
      window.open(url, '_blank');
    } else {
      // nobody asked for or even wanted, but it provides a way to link from parent to child.
      const url = this._router.serializeUrl(
        this._router.createUrlTree([
          '/admin/workflow-builder',
          this.newMapping.targetWorkflow
        ])
      );
      window.open(url, '_blank');
    }
  }
  ngOnInit() {
    this._validation.clearValidationCache();

    this.route.params.subscribe(params => {
      this.workflowId = params['workflowId'];
      this.versionId = params['versionId'];
      this.applicationId = params['applicationId'];
    });

    if (this.versionId) {
      this.saveEnabled = false;
    } else {
      this.saveEnabled = true;
    }
    this.dataMappingForm = new UntypedFormGroup({
      addMappingSource: new UntypedFormControl(this.newMapping.sourceDataEntity, [
        Validators.required
      ])
    });

    this._workflowSvc.getParentChildWorkflows(this.workflowId).subscribe(v => {
      this.parentChildWorkflows = v;
    });

    this.publishForm = new UntypedFormGroup({
      hasErrors: new UntypedFormControl(1, Validators.max(0))
    });
    this.warningsForm = new UntypedFormGroup({
      hasWarnings: new UntypedFormControl(1, Validators.max(0))
    });
    this.appDebugForm = new UntypedFormGroup({});

    // clear the return URL if the page is refreshed.
    if (
      this._previousRouteSvc.getPreviousUrl() === this._router.url ||
      (this._previousRouteSvc.getPreviousUrl() || '') === ''
    ) {
      this._context.buildApplicationReturnURL$.next(null);
    } else if (
      this._previousRouteSvc.getPreviousUrl() &&
      !this._previousRouteSvc
        .getPreviousUrl()
        .includes('application/workflow-application')
    ) {
      this._context.buildApplicationReturnURL$.next(
        this._previousRouteSvc.getPreviousUrl()
      );
    }

    this.activitySidebarSub = this.activitySidebar.saved.subscribe(
      (activity: Activity<ActivityModel>) => {
        this.resizeActivity(activity.id, true);
        this.activitySavedHandler(activity);
      }
    );
    this.activitySidebarCloseSub = this.activitySidebar.closed.subscribe(() => {
      this.focusGraph();
    });

    this.centerOnActivitySub = this._workflowSvc.centerOnActivity$.subscribe(
      activityId => {
        const a = this.getActivityCell(activityId);
        this.centerOnActivity(a.value.activity);
      }
    );

    this.initListeners();

    this._diagramCtrls.init([
      {
        id: 'test',
        name: 'Test Workflow',
        icon: 'open_in_browser',
        click: () => {
          this._workflowSvc
            .startApplication(this.workflowId, true)
            .subscribe((applicationResponse: StartApplicationResponse) => {
              this._router.navigate([
                '/application/workflow-application',
                applicationResponse.applicationId
              ]);
            });
        },
        disabled: () =>
          !this.validity || // validation is running
          (this.validityErrors && this.validityErrors.length > 0) || // there are validation errors (warnings don't matter)
          !this._context.client ||
          this.isReadOnly() ||
          (this._context.workflow &&
            (this._context.workflow.workflowType ===
              WorkflowType.ContractorRegistration ||
              this._context.workflow.workflowType ===
                WorkflowType.ContractorRenewal))
      },
      {
        id: 'layout',
        name: 'Auto Layout',
        icon: 'compare_arrows',
        click: () => this.autoLayout()
      },
      {
        id: 'validation',
        name: 'Validation Info',
        icon: 'check_circle',
        click: () => this.openValidationInfo(),
        disabled: () => !this.validity || this.isValidating
      },
      {
        id: 'publish',
        name: 'Publish',
        icon: 'publish',
        click: () => this.publishModal.open(),
        disabled: () => {
          return !this.canPublish();
        }
      },
      {
        id: 'save',
        name: 'Save',
        icon: this.saveEnabled ? 'save' : 'edit_off',
        position: 5,
        click: () => {
          this.saveWorkflow();
          this.pretendToBeSaving = true;
        },
        disabled: () =>
          this.saved || this.pretendToBeSaving || this.isReadOnly()
      }
    ]);
  }

  public canPublish(): boolean {
    if (this.isReadOnly()) {
      return false;
    }
    const workflow = this._context.workflow;

    return workflow
      ? workflow.isEnabled &&
          workflow.areThereChangesNotPublished &&
          this.saved &&
          !this.saving &&
          !this.isValidating &&
          !this.isContractorWorkflow(workflow.workflowType)
      : false;
  }

  public publishWorkflow() {
    this._workflowSvc
      .publishWorkflow(this._context.workflow.id)
      .subscribe(res => {
        this.toastr.success('Published!');
        this.loadWorkflow();
      });
  }

  public dismissPublish() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll('navigate to builder');
    }
  }

  async openValidationInfo() {
    this.graph.clearSelection();
    if (!this.validity) {
      await this.validateWorkflow();
    }
    const modalRef = this.modalService.open(WorkflowValidityModalComponent, {
      ariaLabelledBy: 'modal-validity-title',
      size: 'lg'
    });
    modalRef.componentInstance.validity = this.validity;
    modalRef.componentInstance.workflowId = this.workflowId;
    modalRef.componentInstance.workflow = this._context.workflow;

    if (this.validationModalClickSub) {
      this.validationModalClickSub.unsubscribe();
    }

    this.validationModalClickSub = modalRef.componentInstance.clickedError.subscribe(
      async (error: ValidationError) => {
        if (error.activity) {
          let changeDiagramObs: Observable<void>;

          // switch to the appropriate activity group before centering on activity
          if (
            this._context.workflow.designStatus !== error.value.activityGroup
          ) {
            this.changeDiagram(error.value.activityGroup);
            // wait for graph loading to be false
            changeDiagramObs = Observable.create(o => {
              setTimeout(() => {
                const intV = setInterval(() => {
                  if (!this.graphLoading) {
                    clearInterval(intV);

                    o.complete();
                  }
                }, 100);
              }, 500);
            });
          } else {
            changeDiagramObs = EMPTY;
          }

          await changeDiagramObs.toPromise();
          this.navigateToItem(error.activity, error.dataEntity);
          modalRef.close();
        }
      }
    );
  }

  navigateToItem(activity, dataEntity) {
    this.centerOnActivity(activity);
    if (dataEntity) {
      const cell = this.activityCellIndex[activity.id].cell;
      this.openActivity(cell);
    }
  }

  setControlBusy(control: string, label: string, color?: DiagramControlColor) {
    this._diagramCtrls.setControl(control, {
      name: label,
      icon: 'hourglass_bottom',
      color: color || DiagramControlColor.primary
    });
  }

  renderValidation() {
    this.graph.getModel().beginUpdate();
    try {
      for (const i in this.activityCellIndex) {
        if (this.activityCellIndex[i]) {
          const cell: mxCell = this.activityCellIndex[i].cell;
          const error = this.validity.find(
            e =>
              e && e.id === i && e.messages.find(m => m.severity === 0) != null
          );
          const warn = this.validity.find(
            e =>
              e && e.id === i && e.messages.find(m => m.severity === 1) != null
          );

          let isDeError = false;
          if (cell.value.activity instanceof FormActivity) {
            const formModel = cell.value.activity.model
              .formLayoutModel as SingleColumnFormLayoutModel;

            for (const de of formModel.columnEntities) {
              const deError = this.validity.find(
                e =>
                  e &&
                  e.id === de.entity.templateCode &&
                  e.activityId === cell.value.activity.id
              );
              if (deError) {
                isDeError = true;
              }
            }
          }

          const isInvalid =
            (error && error.type === ValidationType.Activity) || isDeError;
          cell.value.activity.model.isInvalid = !!isInvalid;

          const isWarning =
            (warn && warn.type === ValidationType.Activity) || isDeError;
          cell.value.activity.model.isWarning = !!isWarning;

          this.graph.model.setValue(cell, cell.value);
        }
      }
    } finally {
      this.graph.getModel().endUpdate();
    }
  }

  setValidating() {
    this.validity = null;
    this.validityErrors = [];
    this.isValidating = true;
    this.publishForm.controls['hasErrors'].setValue(1);
    this.warningsForm.controls['hasWarnings'].setValue(1);

    this.setControlBusy('validation', 'Validating Workflow');
  }

  setValidated() {
    this.isValidating = false;

    const errorValidity: ValidationResponse[] = [];
    const warningValidity: ValidationResponse[] = [];
    if (this.validity) {
      for (const item of this.validity) {
        if (
          item.messages.filter(
            message => message.severity === ValidationSeverity.Error
          ).length > 0
        ) {
          errorValidity.push(item);
        }
      }

      for (const item of this.validity) {
        if (
          item.messages.filter(
            message => message.severity === ValidationSeverity.Warning
          ).length > 0
        ) {
          warningValidity.push(item);
        }
      }
    }

    this.publishForm.controls['hasErrors'].setValue(
      // this.validity && this.validity.length
      errorValidity && errorValidity.length
    );

    this.warningsForm.controls['hasWarnings'].setValue(
      warningValidity && warningValidity.length
    );

    if (this.publishForm.invalid) {
      this._diagramCtrls.setControl('validation', {
        name: `${this.validity.length} problems in this workflow`,
        icon: 'error',
        color: DiagramControlColor.danger
      });
    } else if (!this.publishForm.invalid && this.warningsForm.invalid) {
      this._diagramCtrls.setControl('validation', {
        name: `${this.validity.length} problems in this workflow`,
        icon: 'error',
        color: DiagramControlColor.warn
      });
    } else {
      this._diagramCtrls.setControl('validation', {
        name: `No problems`,
        icon: 'check_circle',
        color: DiagramControlColor.primary
      });
    }
  }

  publishModalOpened() {
    this.validateWorkflow(true, true);
  }

  async validateWorkflow(force?: boolean, runExtendedValidation?: boolean) {
    this.setValidating();

    const validationResponse = await this._validation.validate(
      this._context.workflow.id,
      force,
      runExtendedValidation,
      this.versionId
    );

    this.validity = validationResponse;

    if (this.validity) {
      for (const item of this.validity) {
        if (
          item.messages.filter(
            message => message.severity === ValidationSeverity.Error
          ).length > 0
        ) {
          this.validityErrors.push(item);
        }
      }
    }

    this.setValidated();
    this.renderValidation();
  }

  autoLayout() {
    if (this.layout) {
      this.layout.execute(this.parent);
    }
  }

  /**
   * Detect scale changes and resize cells appropriately
   */
  private scaleChange() {
    if (this.currentScale !== window.devicePixelRatio || this.fallbackScale) {
      this.currentScale = window.devicePixelRatio || this.fallbackScale;

      // if (this.graph) {
      this.graph.getModel().beginUpdate();
      try {
        for (const activityId in this.activityCellIndex) {
          if (activityId) {
            this.resizeActivity(activityId, false);
          }
        }
      } finally {
        this.graph.getModel().endUpdate();
      }
      // }
    }
  }

  /**
   * Resize an activity cell so that it matches the HTML label size
   *
   * @param activityId ID of the activity to resize.
   */
  private resizeActivity(activityId: string, refreshGraph: boolean) {
    const cellCard = document.getElementById(`act-cell-${activityId}`);

    if (cellCard) {
      const cellCardSize = cellCard.getBoundingClientRect();
      const cell = this.activityCellIndex[activityId].cell;

      if (refreshGraph) {
        this.graph.getModel().beginUpdate();
      }
      try {
        // Resize cells
        const geo: mxGeometry = cell.getGeometry().clone();
        geo.setRect(
          geo.x,
          geo.y,
          cellCardSize.width / this.currentScale + 5,
          cellCardSize.height / this.currentScale + 5
        );
        cell.setGeometry(geo);
      } finally {
        if (refreshGraph) {
          this.autoLayout();
          this.graph.getModel().endUpdate();
        }
      }
    }
  }

  backToList() {
    const evt = window.event;
    if (this.handleCloseEvent(evt)) {
      let urlParts: string[] = [];

      if (
        (this._context.buildApplicationReturnURL || '') !== '' &&
        !this._context.buildApplicationReturnURL.endsWith('add')
      ) {
        this._router.navigate([this._context.buildApplicationReturnURL]);
      } else {
        if (this._context.workflow) {
          const isContractor: boolean =
            this._context.workflow.workflowType ===
              WorkflowType.ContractorRegistration ||
            this._context.workflow.workflowType ===
              WorkflowType.ContractorRenewal;
          if (!isContractor) {
            if (this._context.client) {
              urlParts = ['/admin/jurisdiction', this._context.client.id];
            } else {
              urlParts = ['/admin/global/workflows'];
            }
          } else {
            if (this._context.client) {
              // contractor type list
              urlParts = [
                '/admin/jurisdiction',
                this._context.client.id,
                'contractors',
                'types',
                'list'
              ];
            } else {
              urlParts = ['/admin/global/workflows'];
            }
          }
        }
      }

      if (urlParts.length > 0) {
        this._router.navigate(urlParts);
      }
    }
  }

  /**
   * Check if a workflow type is a contractor workflow.
   *
   * @param workflowType The Workflow Type to check
   */
  isContractorWorkflow(workflowType: WorkflowType) {
    return (
      workflowType === WorkflowType.ContractorRegistration ||
      workflowType === WorkflowType.ContractorRenewal
    );
  }

  /**
   * Check if a workflow type is a renewable workflow.
   *
   * @param workflowType The Workflow Type to check
   */
  isRenewableWorkflow(workflowType: WorkflowType) {
    return workflowType === WorkflowType.Renewable;
  }

  canViewAgendaActivity() {
    return (
      !this._context.client || this._context.client.adminRoleHasViewAgendas
    );
  }

  /**
   * Build the available activities sidebar
   */
  buildSidebar() {
    const availActivities = [];

    for (const value in ACTIVITIES) {
      if (ACTIVITIES[value]) {
        const activityType = ACTIVITIES[value];
        const workflowType = this._context.workflow.workflowType;

        // Hide contractor activities if you are not on a Contractor Workflow
        let showActivity = true;
        if (
          !this.isContractorWorkflow(workflowType) &&
          this.contractorActivities.includes(activityType)
        ) {
          showActivity = false;
        } else if (
          this.isContractorWorkflow(workflowType) &&
          activityType === ACTIVITIES.Form
        ) {
          showActivity = false;
        }

        // Hide renewal activities if you are not on a Renewable Workflow
        if (
          !this.isRenewableWorkflow(workflowType) &&
          this.renewalActivities.includes(activityType)
        ) {
          showActivity = false;
        }

        // hide agenda item activity if you don't have VIEW_AGENDAS
        if (this.agendaActivities.includes(activityType)) {
          if (!this.canViewAgendaActivity()) {
            showActivity = false;
          }
        }

        // only show the activity if it is flagged to only show in regular activities
        if(this.onlyInRegularWorkflowActivities.includes(activityType)
          && (this.isContractorWorkflow(workflowType) || this.isRenewableWorkflow(workflowType))) {
          showActivity = false;
        }

        if (showActivity) {
          const activity = ActivityFactory.createActivityByType(activityType);
          const model: BaseCellModel = ActivityFactory.createCellModel(
            activityType,
            activity
          );
          const shape = ActivityFactory.getShape(activityType);

          if (model && shape) {
            availActivities.push({
              name: model.activity.name,
              description: model.activity.description,
              type: model.activity.type
            });

            const cell = new mxCell(
              model,
              new mxGeometry(0, 0, shape.width, shape.height),
              shape.shape
            );
            cell.setVertex(true);

            // this timeout is a hack to make sure that the element exists before accessing it
            setTimeout(() => {
              const item = document.getElementById(
                `activity-item-${model.activity.type}`
              );
              // check to make sure that the item exists because sometimes this happens after the editor is unloaded
              if (item) {
                this.addSidebarItem(cell, item);
              }
            }, 50);
          }
        }
      }
    }

    availActivities.sort((a, b) => a.name.localeCompare(b.name));

    // sort the activities by name to make it easier to find activities
    this.availableActivities = availActivities;
  }

  /**
   * Make an item in the available activities sidebar draggable
   *
   * @param prototype Prototype of the cell to be created when the activity is dropped onto the diagram
   * @param element HTML element to make draggable
   */
  addSidebarItem(prototype: mxCell, element: HTMLElement) {
    const handleDrop = (
      dropGraph: mxGraph,
      evt: mxEventObject,
      cell: mxCell
    ) => {
      dropGraph.stopEditing(false);

      const pt = dropGraph.getPointForEvent(evt, null);
      const vertex = dropGraph.getModel().cloneCell(prototype);
      vertex.geometry.x = pt.x;
      vertex.geometry.y = pt.y;

      const cells = dropGraph.importCells([vertex], 0, 0, cell, evt);
      dropGraph.setSelectionCells(cells);
    };

    const dragElement = element.cloneNode(true) as HTMLElement;
    dragElement.classList.add('dragging');
    dragElement.style['background-color'] = '#ffffff';

    mxUtils.makeDraggable(element, this.graph, handleDrop, dragElement);
  }

  insertActivity(
    activity: Activity<ActivityModel>,
    point?: { x: number; y: number }
  ) {
    if (this.parent) {
      // create diagram cell for activity
      const cellModel: BaseCellModel = ActivityFactory.createCellModel(
        activity.type,
        activity
      );
      const cellShape: any = ActivityFactory.getShape(activity.type);
      const cell = this.graph.insertVertex(
        this.parent,
        null,
        cellModel,
        (point && point.x) || 0,
        (point && point.y) || 0,
        cellShape.width,
        cellShape.height,
        `shape=rectangle;editable=0`,
        0
      );

      this.activityCellIndex[activity.id] = {
        cell,
        activity
      };
    }
  }

  insertRoute(route: RouteDefinition, isInit?: boolean, style?: string) {
    if (
      this.activityCellIndex[route.sourceId] &&
      this.activityCellIndex[route.targetId]
    ) {
      const sourceCell: mxCell = this.activityCellIndex[route.sourceId].cell;
      const targetCell: mxCell = this.activityCellIndex[route.targetId].cell;

      if (sourceCell && targetCell) {
        const edge = this.graph.insertEdge(
          sourceCell.parent,
          Utilities.generateId(),
          new RouteDefinition(route),
          sourceCell,
          targetCell,
          style
        );
        if (!isInit) {
          this.changes.push({
            changeType: 'connection',
            typeOfChange: 'add',
            sourceId: route.sourceId,
            targetId: route.targetId
          });
        }
      }
    }
  }

  insertCondition(cell: mxCell) {
    this.pauseSaves = true;
    const originalRouteModel: RouteDefinition = cell.getValue();

    const sourceActivityId = originalRouteModel.sourceId;
    const sourceActivity: Activity<ActivityModel> = this._workflowSvc.getWorkflowActivity(
      this._context.workflow,
      sourceActivityId
    );
    const destinationActivityId = originalRouteModel.targetId;

    // remove the original route
    this.graph.removeCells([cell], true);

    // add the condition activity
    let conditionActivity: Activity<ActivityModel> = null;

    if (sourceActivity.type === ACTIVITIES.Decision) {
      conditionActivity = sourceActivity;
    } else {
      // Replace the edge with a Decision link to the source and target
      conditionActivity = new DecisionActivity({
        id: Utilities.generateId(),
        model: new DecisionActivityModel({
          responsibleRole: sourceActivity.model.responsibleRole,
          responsibleRoleId: sourceActivity.model.responsibleRoleId
        }),
        previousActivityIds: [sourceActivityId]
      });
    }

    this.insertActivity(conditionActivity);

    // add the route from the original source activity to the condition activity
    const sourceToCondition = new RouteDefinition({
      id: Utilities.generateId(),
      sourceId: sourceActivityId,
      targetId: conditionActivity.id
    });

    // add the route form the condition activity to the original destination activity
    const conditionToDestination = new RouteDefinition({
      id: Utilities.generateId(),
      sourceId: conditionActivity.id,
      targetId: destinationActivityId,
      criteria: null
    });

    this.insertRoute(sourceToCondition);
    this.insertRoute(conditionToDestination);
    this.pauseSaves = false;
    this.saveWorkflow();
  }

  initWorkflowActivities(): Observable<any> {
    const workflow: Workflow = this._context.workflow;

    this.activityCellIndex = {};

    workflow.version.graph = new WorkflowGraph(workflow.version.graph);

    let activities = workflow.version.graph.getActivities(
      workflow.designStatus || ApplicationStatus.NotStarted
    );

    if (!activities) {
      activities = [];
    }

    for (const activity of activities) {
      // default to applicant role
      if ((activity.model.responsibleRoleId || '') === '') {
        activity.model.responsibleRole = SecurityService.APPLICANT_ROLE;
        activity.model.responsibleRoleId = SecurityService.APPLICANT_ROLE.id;
      }

      this.insertActivity(activity);
    }

    // { from: to}
    const routingEdges: { [key: string]: string[] } = {};

    for (const activity of activities) {
      for (const route of activity.routing) {
        this.insertRoute(route, true);
        if (!routingEdges[route.sourceId]) {
          routingEdges[route.sourceId] = [];
        }
        if (!routingEdges[route.sourceId].includes(route.targetId)) {
          routingEdges[route.sourceId].push(route.targetId);
        }
      }
    }

    // this is here so that we see previous activity ids where a route doesn't go from source to target
    if (this.versionId) {
      this._workflowSvc.designerVersionMode = true;

      for (const activity of activities) {
        for (const prevId of activity.previousActivityIds) {
          if (
            routingEdges[prevId] &&
            !routingEdges[prevId].includes(activity.id)
          ) {
            this.insertRoute(
              new RouteDefinition({
                sourceId: prevId,
                targetId: activity.id
              }),
              true,
              'strokeColor=red;'
            );
          }
        }
      }
    } else {
      this._workflowSvc.designerVersionMode = false;
    }

    return of(true);
  }

  setSavingStatus() {
    this.setControlBusy('save', 'Saving Pending Changes');
    this.saved = false;
    this.saving = true;
  }

  setSavedStatus() {
    this._diagramCtrls.setControl('save', {
      name: 'Save',
      icon: 'save',
      color: DiagramControlColor.primary
    });
    if (this.changes.length === 0) {
      this.saved = true;
    }
    this.saving = false;
  }

  /**
   * Save the workflow every two seconds if there are changes and if it isn't already saving
   */
  startSaveInterval() {
    if (!this.versionId) {
      this.saveInterval = setInterval(() => {
        if (!this.saving && !this.pauseSaves && this.changes.length > 0) {
          this.saving = true;
          const changes = this.changes;
          this.changes = [];
          this.savingChanges = changes;

          return this._workflowSvc
            .saveWorkflowChanges(
              this._context.workflow.id,
              changes,
              this._context.workflow.designStatus
            )
            .subscribe(
              () => {
                this.saveComplete();
              },
              err => {
                this.saveComplete(true);
                throw err;
              }
            );
        }
      }, 2000);
    }
  }

  /**
   * Mark the workflow as unsaved
   */
  saveWorkflow() {
    this.saved = false;
  }

  /**
   * @ignore
   */
  private saveComplete(err?: boolean) {
    this._context.workflow.areThereChangesNotPublished = true;
    // only validate once a save completes and we don't have anymore changes to save.
    if (this.changes.length === 0) {
      this.savingChanges = [];
      // clear the validation cache since this is being called after save
      this._validation.clearValidationCache();

      // keep the saving indicator around a little longer so that it doesn't appear and disappear too quickly
      setTimeout(() => {
        this.saving = false;
        this.pretendToBeSaving = false;
        // set the saved to false if we get an error on the save.
        if (err) {
          // put the changes that are being saved back in the changes that need saved if it errors
          this.changes = this.changes.concat(this.savingChanges);
          this.saved = false;
        }
      }, 500);

      this.validateWorkflow(true);
    }
  }

  /**
   * Creates an HTML element
   *
   * @param html String of HTML to render
   * @param id ID of the element
   */
  private renderHtml(html: string, id?: string): HTMLElement {
    const div = document.createElement('div');
    div.id = id;
    div.innerHTML = html;
    return div;
  }

  renderLabel(cell: mxCell) {
    if (cell.isVertex()) {
      if (cell.value && cell.value.getLabel) {
        const activity = cell.value.activity;
        const roleObj = activity.model.responsibleRole;

        const validityCls = activity.model.isInvalid ? 'activity-invalid' : '';

        const warningCls = activity.model.isWarning ? 'activity-warning' : '';
        const selectedCls = activity.model.isSelected ? 'card-selected' : '';

        const statusCls =
          cell.value.status === 'InProgress'
            ? 'activity-inprogress'
            : cell.value.status === 'Completed'
            ? 'activity-completed'
            : cell.value.status === 'Canceled'
            ? 'activity-canceled'
            : '';

        const role = roleObj && roleObj.name ? roleObj.name : '(unset)';

        let colorCls = '';
        if (roleObj && roleObj.color) {
          colorCls = `text-white rc-${roleObj.color}`;
        } else if (
          activity.type === ACTIVITIES.Start ||
          activity.type === ACTIVITIES.CompleteWorkflow
        ) {
          colorCls = 'text-white bg-primary';
        }

        const warningLabel = activity.model.isWarning
          ? '<small>(warning)</small>'
          : '';
        const invalidLabel = activity.model.isInvalid
          ? '<small>(invalid)</small>'
          : '';

        const roleHtml = activity.isResponsibleRoleRequired
          ? `- <span class="font-weight-bold">${role}</span>`
          : '';

        // const offsetBorderCls = validityCls !== '' && statusCls !== '' ? 'app-activity-invalid' : '';

        // console.log('offsetBorderCls', offsetBorderCls);
        // ${offsetBorderCls}
        const warningOrInvalidLabel =
          invalidLabel !== '' ? invalidLabel : warningLabel;
        const warningOrInvalidClass =
          validityCls !== '' ? validityCls : warningCls;
        return this.renderHtml(
          `
            <div class="card card-activity ${statusCls}">
              <div class="card card-activity ${colorCls} ${selectedCls} ${warningOrInvalidClass}">
                <div class="card-body">
                  <h5 class="card-title">${cell.value.getLabel()} ${warningOrInvalidLabel}</h5>
                  <p class="card-text">
                    ${activity.name} ${roleHtml}
                  </p>
                </div>
              </div>
            </div>
            `,
          `act-cell-${activity.id}`
        );
      }
    }

    return cell.value.name;
  }

  renderTooltip(cell: mxCell) {
    let tooltip = '';

    if (cell && cell.value) {
      tooltip = cell.value.getToolTip(this._context);

      if (cell.value.activity && this.validity) {
        const error = this.validity.find(e => e.id === cell.value.activity.id);

        if (error && error.messages && error.messages.length > 0) {
          tooltip += '\n<b>Validation Errors</b>\n<ul>';
          for (const err of error.messages) {
            tooltip += `<li>${ValidationSeverity[err.severity]}: ${
              err.message
            }</li>`;
          }
          tooltip += '</ul>';
        }
      }
    }

    return tooltip;
  }

  setupGraph() {
    if (this.graph) {
      this.graph.destroy();
    }

    this.graph = this.editor.graph;
    this.graph.setAutoSizeCells(true);
    this.graph.setCellsResizable(false);
    this.graph.setConnectable(true);
    this.graph.setHtmlLabels(true);
    this.graph.setAllowDanglingEdges(false);
    this.graph.allowAutoPanning = true;
    this.graph.timerAutoScroll = true;
    this.graph.setPanning(true);
    this.graph.setCellsEditable(false);
    this.graph.panningHandler.useLeftButtonForPanning = true;

    // Adds active border for panning inside the container
    this.graph.createPanningManager = () => {
      const pm = new mxPanningManager(this.graph);
      pm.border = 30;

      return pm;
    };

    this.graph.convertValueToString = this.renderLabel.bind(this);
    this.graph.getTooltipForCell = this.renderTooltip.bind(this);

    this.parent = this.graph.getDefaultParent();
    this.layout = new mxHierarchicalLayout(this.graph);

    if (this.outline) {
      this.outline.source = null;
      this.outline.destroy();
    }

    this.outline = new mxOutline(
      this.graph,
      document.getElementById('outline')
    );
  }

  setupVertexStyles() {
    const vertexStyle = this.graph.getStylesheet().getDefaultVertexStyle();

    // make the SVG cells essentially invisible
    const backgroundColor = '#f6f6f6';
    vertexStyle[mxConstants.STYLE_FILLCOLOR] = backgroundColor;
    vertexStyle[mxConstants.STYLE_STROKECOLOR] = backgroundColor;
    vertexStyle[mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = backgroundColor;
    mxConstants.VERTEX_SELECTION_COLOR = 'none';
  }

  setupEdgeStyles() {
    const edgeStyle = this.graph.getStylesheet().getDefaultEdgeStyle();
    edgeStyle[mxConstants.STYLE_STROKECOLOR] = '#00395E';
    // edgeStyle[mxConstants.STYLE_EDGE] = mxEdgeStyle.ElbowConnector;
    edgeStyle[mxConstants.STYLE_ROUNDED] = true;
  }

  openActivity(cell: mxCell) {
    const activity: Activity<ActivityModel> = this.graph
      .getModel()
      .getValue(cell).activity;
    const activityType: string = activity.type;
    this._context.activeActivity$.emit(activity);

    this.activitySidebar.open({
      activityId: activity.id || '',
      activityType,
      changes: this.savingChanges
    });
  }

  /**
   * Show the properties of a cell (e.g. open the activity details sidebar)
   *
   * @param cell The cell to show properties for
   */
  showProperties(cell: mxCell) {
    if (!cell.isEdge()) {
      // open activity sidebar
      this.openActivity(cell);
    }
  }

  cellClickHandler(cell: mxCell, type: string) {
    if (type === 'double') {
      this.editor.showProperties(cell);
    } else {
      if (cell.isVertex()) {
        // open activity sidebar
        if (this.activitySidebar.isOpen) {
          this.openActivity(cell);
        }
      }
    }
  }

  private getActivityCell(activityId: string): mxCell {
    return (
      this.activityCellIndex &&
      this.activityCellIndex[activityId] &&
      this.activityCellIndex[activityId].cell
    );
  }

  private duplicate(cell: mxCell) {
    this.createDuplicate([cell.value.activity]);
  }

  private copyActivity() {
    this.activityClipboard = [];

    const cells: mxCell[] = this.graph.getSelectionCells();

    for (const cell of cells) {
      if (cell.isVertex() && cell.value.activity) {
        const activity = _.cloneDeep(cell.value.activity);
        this.activityClipboard.push(activity);
      }
    }
  }

  private buildActivityDebugInfo(cell: mxCell) {
    const model = this.graph.getModel().getValue(cell);
    const activity: Activity<ActivityModel> = model.activity;

    return `Activity: ${activity.model.screenName}
            \tid: ${activity.id}
            \tLevel: ${activity.level}`;
  }

  private jumpToSource(edge: mxCell) {
    if (edge && edge.source && edge.source.value) {
      this.centerOnActivity(edge.source.value.activity);
    }
  }

  private jumpToTarget(edge: mxCell) {
    if (edge && edge.target && edge.target.value) {
      this.centerOnActivity(edge.target.value.activity);
    }
  }

  private showActivityDebug(evt?: PointerEvent) {
    const cells: mxCell[] = this.graph.getSelectionCells();

    this.debugInformation = `Selected Cells: ${cells.length}`;

    cells.forEach(c => {
      this.debugInformation += '\n' + this.buildActivityDebugInfo(c);
    });

    this.debugInfoModal.open();
  }

  private showAppActivityInfo() {
    let activityId: string;

    const cells = this.graph.getSelectionCells();
    const model = this.graph.getModel().getValue(cells[0]);

    const activity: Activity<ActivityModel> = model.activity;

    activityId = activity.id;

    this._workflowSvc
      .getApplicationActivityInfo(this.applicationId, activityId)
      .subscribe(appActivities => {
        appActivities.forEach(a => {
          this.appDebugForm.addControl(
            a.activityDataId,
            this._fb.group(ValidationService.createValidationGroup(a))
          );
          a.isReadOnly = true;
        });
        appActivities = appActivities.sort(
          (a, b) => a.applicationIteration - b.applicationIteration
        );
        this.appActivities = appActivities;
        this.appActivityDataModal.open();
      });
  }

  private pasteActivity(evt?: PointerEvent) {
    if (!this.activityClipboard) {
      this.toastr.warning('You have not copied anything yet.');
      return;
    }

    this.createDuplicate(this.activityClipboard, evt);
  }

  private createDuplicate(data: Activity<ActivityModel>[], evt?: PointerEvent) {
    const activityLoadPromises = data.map(m =>
      this._workflowSvc
        .getActivityEditor(this.workflowId, m.id, this.versionId)
        .toPromise()
    );
    Promise.all(activityLoadPromises).then(fullData => {
      const activities = fullData;
      const routesMap: { [activityId: string]: RouteDefinition[] } = {};
      let firstGeometry: mxGeometry;
      this.graph.getModel().beginUpdate();

      try {
        for (const activity of activities) {
          routesMap[activity.id] = JSON.parse(JSON.stringify(activity.routing));
          activity.routing = [];
          activity.previousActivityIds = [];
          activity.model.screenName = `Copy of ${activity.model.screenName}`;
        }
        const myGraph = this.graph;
        const calculatePastePoint = function(
          geometry
        ): { x: number; y: number } {
          let pt: { x: number; y: number } = { x: 200, y: 0 };

          if (evt) {
            // calculate the paste point
            pt = myGraph.getPointForEvent(evt);

            // store one of the geometries for use to calculate offsets
            if (!firstGeometry) {
              firstGeometry = geometry;
            }

            // calculate the x/y offset for this activity
            const offsetX = geometry.x - firstGeometry.x;
            const offsetY = geometry.y - firstGeometry.y;

            // add the offset to the paste point
            pt.x += offsetX;
            pt.y += offsetY;
          } else if (geometry) {
            pt = {
              x: geometry.x + 50 + geometry.width,
              y: geometry.y
            };
          }

          return pt;
        };

        // send activities to server to make template codes unique
        this._workflowSvc
          .duplicateActivities(activities)
          .subscribe(activitiesResults => {
            for (const activity of activitiesResults.activities) {
              const legacyActivityId =
                activitiesResults.newIdToOriginalId[activity.id];
              const originalCell = this.getActivityCell(legacyActivityId);
              this.insertActivity(
                activity,
                calculatePastePoint(originalCell.geometry)
              );
            }

            // insert the routing edges AFTER all of the activities have been inserted
            for (const activityId in routesMap) {
              if (routesMap[activityId]) {
                const updatedId =
                  activitiesResults.originalIdToNewId[activityId];
                const cell = this.getActivityCell(updatedId);
                if (cell) {
                  this.toggleCellSelection(cell, false);
                }

                const routes = routesMap[activityId];

                for (const route of routes) {
                  route.targetId =
                    activitiesResults.originalIdToNewId[route.targetId];
                  route.sourceId =
                    activitiesResults.originalIdToNewId[route.sourceId];
                  if (route.targetId && route.sourceId) {
                    this.insertRoute(route, true);
                  }
                  // else{ don't do anything since the route probably belonged to an activity that wasn't copied.}
                }
              }
            }
          });
      } finally {
        this.graph.getModel().endUpdate();
      }
    });
  }

  renderGraph(buildSidebar = true) {
    // this is just here to satisfy mxGraph...
    mxGraph.prototype.expandedImage = 'assets/images/blank.svg';

    // Enables guides
    mxGraphHandler.prototype.guidesEnabled = true;
    mxGraphHandler.prototype.cloneEnabled = false;
    mxGraphHandler.prototype.dropEnabled = true;

    // Alt disables guides
    mxGuide.isEnabledForEvent = evt => !mxEvent.isAltDown(evt);

    // Enables snapping waypoints to terminals
    mxEdgeHandler.prototype.snapToTerminals = true;
    mxConnectionHandler.prototype.connectImage = new mxImage(
      '/assets/images/connector.svg',
      16,
      16
    );

    mxObjectCodec.allowEval = true;

    // load config value
    const node = mxUtils
      .load('/assets/mxconfig/workfloweditor.xml')
      .getDocumentElement();

    if (node) {
      this.editor = new mxEditor(node);
      this.editor.showProperties = this.showProperties.bind(this);
      this.editor.isVersionDesigner = this.versionId != null;
      this.editor.isAppVersionDesigner = this.applicationId != null;
      this.editor.duplicate = this.duplicate.bind(this);
      this.editor.copy = cell => {
        this.copyActivity();
      };
      this.editor.paste = (evt: PointerEvent) => {
        this.pasteActivity(evt);
      };
      this.editor.showDebug = (evt: PointerEvent) => {
        this.showActivityDebug();
      };
      this.editor.showAppActivities = (evt: PointerEvent) => {
        this.showAppActivityInfo();
      };
      this.editor.jumpToTarget = (edge: mxCell) => {
        this.jumpToTarget(edge);
      };
      this.editor.jumpToSource = (edge: mxCell) => {
        this.jumpToSource(edge);
      };
    }

    this.setupGraph();
    this.setupVertexStyles();
    this.setupEdgeStyles();

    this.build(buildSidebar);
  }

  toggleWorkflowSearch() {
    this.workflowSearchVisible = !this.workflowSearchVisible;
  }

  navigateToSearchItem(searchItem: {
    type: string;
    id: string;
    activityId: string;
    activityName: string;
    templateCode: string;
    dataEntityLabel: string;
  }) {
    const activityCell = this.getActivityCell(searchItem.activityId);
    if (activityCell) {
      const model = this.graph.getModel().getValue(activityCell);
      const activity = model.activity;
      this.navigateToItem(activity, searchItem.templateCode);
      this.toggleWorkflowSearch();
    }
  }

  searchWorkflow(text: string) {
    this.isSearching = true;
    this.searchResults = null;
    this._workflowSvc
      .searchWorkflow(this.workflowId, text)
      .subscribe(results => {
        this.searchResults = results;
        this.isSearching = false;
      });
  }

  routesEqual(a: RouteDefinition, b: RouteDefinition): boolean {
    return a.sourceId === b.sourceId && a.targetId === b.targetId;
  }

  activitySavedHandler(activity: Activity<ActivityModel>) {
    if (!activity) {
      return;
    }

    // update activity
    const activityCell = this.activityCellIndex[activity.id].cell;

    const model = this.graph.getModel().getValue(activityCell);
    model.activity = activity;
    this.graph.getModel().setValue(activityCell, model);

    // if the activity is a decision, we need to add any new routes that were created
    if (activity.type === ACTIVITIES.Decision) {
      const cell = this.activityCellIndex[activity.id].cell;
      const edges: mxCell[] = this.graph.getAllEdges([cell]);

      // remove handlers so that it doesn't duplicate the route
      this.removeHandlers();
      this.graph.getModel().beginUpdate();
      try {
        // loop through the routes to make sure that we have all the edges
        for (const newRoute of activity.routing) {
          const edgeExists = edges.find(edge =>
            this.routesEqual(edge.getValue(), newRoute)
          );

          // if an edge does not exist for the route, we need to add it
          if (!edgeExists) {
            this.insertRoute(newRoute);
          }
        }

        // loop through the edges and remove ones that no longer are needed
        for (const edge of edges) {
          const edgeRoute = edge.getValue();

          // if the edge route's source is not the condition, don't remove it
          if (edgeRoute.sourceId !== activity.id) {
            continue;
          }

          const routeExists = activity.routing.find(route =>
            this.routesEqual(route, edgeRoute)
          );

          if (!routeExists) {
            if (edge.source && edge.target) {
              this.changes.push({
                changeType: 'connection',
                typeOfChange: 'delete',
                sourceId: edge.source.value.activity.id,
                targetId: edge.target.value.activity.id
              });
            }
            this.graph.removeCells([edge], true);
          }
        }

        this.autoLayout();
      } finally {
        this.graph.getModel().endUpdate();
        this.outline.update(false);
        // re-add the handlers
        this.setupHandlers();
      }
    }

    this.addActivityChange({
      changeType: 'activity',
      typeOfChange: 'update',
      changedActivity: JSON.parse(JSON.stringify(activity))
    });

    this.centerOnActivity(activity);
  }

  addActivityChange(change: {
    changeType: string;
    typeOfChange: string;
    changedActivity: any;
  }) {
    this.saved = false;
    const existingObjChange = this.changes.find(
      c =>
        c.typeOfChange === 'update' &&
        c.changedActivity === change.changedActivity
    );

    if (existingObjChange) {
      const idx = this.changes.indexOf(existingObjChange);

      if (idx > -1) {
        this.changes[idx] = change;
      }
    } else {
      this.changes.push(change);
    }
  }

  /**
   * Handle cell additions
   */
  cellAddedHandler(graph: mxGraph, event: mxEventObject) {
    const cell: mxCell = event.getProperty('cells')[0];

    if (cell.isEdge()) {
      const sourceActivityModel: BaseCellModel = graph
        .getModel()
        .getValue(cell.source);
      const targetActivityModel: BaseCellModel = graph
        .getModel()
        .getValue(cell.target);

      const sourceActivity = this._workflowSvc.getWorkflowActivity(
        this._context.workflow,
        sourceActivityModel.activity.id
      );
      const targetActivity = this._workflowSvc.getWorkflowActivity(
        this._context.workflow,
        targetActivityModel.activity.id
      );

      if (!sourceActivity.routing) {
        sourceActivity.routing = [];
      }

      let sourceRouteModel: RouteDefinition;
      let activitySaved = false;
      if (
        sourceActivity.type === 'decision-activity' &&
        sourceActivity.routing.length > 0
      ) {
        // if this is an additional route from a condition activity, create a condition target
        sourceRouteModel = new RouteDefinition({
          id: Utilities.generateId(),
          sourceId: sourceActivityModel.activity.id,
          targetId: targetActivityModel.activity.id,
          criteria: [
            new RouteCriteria({
              criteria: new ConditionTarget({
                value: targetActivityModel.activity.id
              }),
              targetId: targetActivityModel.activity.id
            })
          ]
        });
        cell.setValue(sourceRouteModel);

        this.addActivityChange({
          changeType: 'activity',
          typeOfChange: 'update',
          changedActivity: JSON.parse(JSON.stringify(sourceActivity))
        });
        activitySaved = true;
      } else {
        sourceRouteModel = new RouteDefinition({
          id: Utilities.generateId(),
          sourceId: sourceActivityModel.activity.id,
          targetId: targetActivityModel.activity.id
        });
        cell.setValue(sourceRouteModel);
      }

      if (
        targetActivity.previousActivityIds.indexOf(
          sourceActivityModel.activity.id
        ) === -1
      ) {
        targetActivity.previousActivityIds.push(
          sourceActivityModel.activity.id
        );
      }
      sourceActivity.routing.push(sourceRouteModel);
      if (!activitySaved) {
        this.changes.push({
          changeType: 'connection',
          typeOfChange: 'add',
          sourceId: sourceActivity.id,
          targetId: targetActivity.id
        });
      }
      this.saved = false;
      this._workflowSvc.routingChanges.emit();

      // save the workflow
      this.saveWorkflow();
      return;
    } else if (cell.isVertex()) {
      this.saved = false;

      // cell is activity
      const model = _.cloneDeep(graph.getModel().getValue(cell));

      const activity: Activity<ActivityModel> = ActivityFactory.createActivity(
        model.activity
      );

      // only replace the ID if it doesn't already have one
      if (!activity.id || activity.id === Utilities.EMPTY_GUID) {
        activity.id = Utilities.generateId();
      }

      model.activity = activity;
      cell.setValue(model);

      this._context.workflow.version.graph.addActivity(
        activity,
        this._context.workflow.designStatus
      );

      this.activityCellIndex[activity.id] = { cell, activity };
      this.editor.showProperties(cell);

      this.activityAdded.emit(activity);
    }

    this.validateWorkflow();
  }

  /**
   * Handle cell removal
   */
  cellRemovedHandler(graph: mxGraph, event: mxEventObject) {
    this.saved = false;
    const cells: mxCell[] = event.getProperty('cells');

    for (const cell of cells) {
      if (cell.isEdge()) {
        const edgeValue = graph.getModel().getValue(cell);
        const sourceActivity: Activity<ActivityModel> = this._workflowSvc.getWorkflowActivity(
          this._context.workflow,
          edgeValue.sourceId
        );
        const targetActivity: Activity<ActivityModel> = this._workflowSvc.getWorkflowActivity(
          this._context.workflow,
          edgeValue.targetId
        );

        if (sourceActivity) {
          const route = sourceActivity.routing.find(f => f.id === edgeValue.id);
          if (route) {
            sourceActivity.routing.splice(
              sourceActivity.routing.indexOf(route),
              1
            );
          }
        }

        if (targetActivity && sourceActivity) {
          this.changes.push({
            changeType: 'connection',
            typeOfChange: 'delete',
            sourceId: sourceActivity.id,
            targetId: targetActivity.id
          });

          const i = targetActivity.previousActivityIds.indexOf(
            sourceActivity.id
          );
          if (i !== -1) {
            targetActivity.previousActivityIds.splice(i, 1);
          }
        }

        this._workflowSvc.routingChanges.emit();
      } else if (cell.isVertex()) {
        const a: Activity<ActivityModel> = cell.getValue().activity;

        if (a) {
          const graphObj = new WorkflowGraph(
            this._context.workflow.version.graph
          );
          graphObj.removeActivity(a, this._context.workflow.designStatus);
          this.addActivityChange({
            changeType: 'activity',
            typeOfChange: 'delete',
            changedActivity: JSON.parse(JSON.stringify(a))
          });
          // this.changes.push({
          //   changeType: 'activity',
          //   typeOfChange: 'delete',
          //   changedActivity: a
          // });

          this.activitySidebar.close();
        }
      }
    }

    // save the workflow
    this.saveWorkflow();
  }

  toggleCellSelection(cell: mxCell, isSelected: boolean) {
    if (cell.isVertex()) {
      cell.value.activity.model.isSelected = isSelected;
      this.graph.model.setValue(cell, cell.value);
    }
  }

  /**
   * Handle cell selection changes
   */
  cellSelectionChangedHandler(selectionModel: any, event: mxEventObject) {
    // according to mxGraph docs "The names are inverted due to historic reasons." - thus removed is added and added is removed 🤷‍
    const removed = (event.getProperty('added') as mxCell[]) || [];
    const added = (event.getProperty('removed') as mxCell[]) || [];
    try {
      this.graph.getModel().beginUpdate();
      for (const cell of added) {
        this.toggleCellSelection(cell, true);
      }
      for (const cell of removed) {
        this.toggleCellSelection(cell, false);
      }
    } finally {
      this.graph.getModel().endUpdate();
    }
  }

  keyupHandlers(evt: KeyboardEvent) {
    switch (evt.keyCode) {
      // delete key
      case 46: // delete
      case 8: // backspace
        if (this.graph.isEnabled()) {
          this.graph.removeCells();
        }
        break;

      default:
        break;
    }
  }

  doubleClickHandler(graph: mxGraph, event: mxEventObject) {
    if (event.getProperty('cell')) {
      this.cellClickHandler(event.getProperty('cell'), 'double');
    }
  }
  singleClickHandler(graph: mxGraph, event: mxEventObject) {
    if (event.getProperty('cell')) {
      this.cellClickHandler(event.getProperty('cell'), 'single');
    }
  }

  focusGraph() {
    this.editor.graph.container.setAttribute('tabindex', '-1');
    this.editor.graph.container.focus();
  }

  /**
   * Center the graph view on a certain geometry.
   *
   * @param geo The geometry to center on
   */
  private centerOnGeometry(geo: mxGeometry) {
    const container = this.diagramContainer.nativeElement;
    this.graph.view.setTranslate(
      -geo.x - (geo.width - container.clientWidth) / 2,
      -geo.y - (geo.height - container.clientHeight) / 2
    );
  }

  centerGraph() {
    const bounds = this.graph.getGraphBounds();
    this.centerOnGeometry(bounds);
  }

  centerOnActivity(activity: Activity<ActivityModel>) {
    const cell = this.activityCellIndex[activity.id].cell;

    this.centerOnGeometry(cell.geometry);

    const selectModel = this.graph.getSelectionModel();
    selectModel.clear();
    selectModel.addCell(cell);
  }

  build(sidebar = true) {
    // if (sidebar) {
    this.buildSidebar();
    // }

    this.buildWorkflow().subscribe(() => {
      this.setupHandlers();
      this.validateWorkflow();
      setTimeout(() => {
        this.loaded = true;
        // this.autoLayout();
        // this.centerGraph();
      }, 100);
    });
  }

  initListeners() {
    this.cellAddedListener = mxUtils.bind(this, this.cellAddedHandler);
    this.cellRemovedListener = mxUtils.bind(this, this.cellRemovedHandler);
    this.cellSelectionChangedListener = mxUtils.bind(
      this,
      this.cellSelectionChangedHandler
    );
    this.doubleClickListener = this.doubleClickHandler.bind(this);
    this.singleClickListener = this.singleClickHandler.bind(this);
    this.keyupListener = this.keyupHandlers.bind(this);
    this.pointerdownListener = this.focusGraph.bind(this);

    Mousetrap.bind(['command+c', 'ctrl+c'], () => {
      this.copyActivity();
    });

    Mousetrap.bind(['command+v', 'ctrl+v'], () => {
      this.pasteActivity();
    });
  }

  setupHandlers() {
    if (this.graph) {
      const selectModel = this.graph.getSelectionModel();

      this.graph.addListener(mxEvent.CELLS_ADDED, this.cellAddedListener);
      this.graph.addListener(mxEvent.CELLS_REMOVED, this.cellRemovedListener);
      this.graph.addListener(mxEvent.DOUBLE_CLICK, this.doubleClickListener);
      this.graph.addListener(mxEvent.CLICK, this.singleClickListener);
      selectModel.addListener(
        mxEvent.CHANGE,
        this.cellSelectionChangedListener
      );

      this.diagramContainer.nativeElement.addEventListener(
        'keyup',
        this.keyupListener
      );
      this.diagramContainer.nativeElement.addEventListener(
        'pointerdown',
        this.pointerdownListener
      );

      this.focusGraph();

      this.validatingListener = this._validation.validating.subscribe(() => {
        this.setValidating();
      });

      this.validatedListener = this._validation.validated.subscribe(() => {
        this.validateWorkflow();
      });

      this.savingListener = this._workflowSvc.workflowChangesSaving.subscribe(
        () => {
          this.setSavingStatus();
        }
      );

      this.savedListener = this._workflowSvc.workflowChangesSaved.subscribe(
        () => {
          this.setSavedStatus();
        }
      );
    }
  }

  removeHandlers() {
    if (this.graph) {
      this.graph.removeListener(this.cellAddedListener);
      this.graph.removeListener(this.cellRemovedListener);
      this.graph.removeListener(this.singleClickListener);
      this.graph.removeListener(this.doubleClickListener);
      this.graph
        .getSelectionModel()
        .removeListener(this.cellSelectionChangedListener);
      this.diagramContainer.nativeElement.removeEventListener(
        'keyup',
        this.keyupListener
      );
      this.diagramContainer.nativeElement.removeEventListener(
        'pointerdown',
        this.pointerdownListener
      );
    }
  }

  buildWorkflow(): Observable<any> {
    try {
      this.removeHandlers();
      // Displays version in statusbar
      this.graph.getModel().beginUpdate();

      this.sw.lap('handlers removed');
      return this.initWorkflowActivities();
    } finally {
      this.sw.lap('activities initialized');
      // this.autoLayout();
      // this.sw.lap('layout executed');

      this.graph.getModel().endUpdate();
      this.sw.lap('update finished');

      // reset the undo history so undo doesn't removed programmatically added items
      this.editor.resetHistory();
      this.sw.lap('history reset');
    }
  }

  clearGraph() {
    this.sw.lap('start remove Handlers');
    this.removeHandlers();
    this.sw.lap('finish remove Handlers');

    if (this.graph) {
      this.sw.lap('start remove cells');
      this.graph.removeCells(this.graph.getChildVertices(this.parent), true);
      this.sw.lap('finish remove cells');
      this.sw.lap('start destroy graph');
      this.graph.destroy();
      this.sw.lap('finish destroy graph');
    }
  }

  resetGraph() {
    this.removeHandlers();

    this.activityCellIndex = {};
    this.graph.removeCells(this.graph.getChildVertices(this.parent), true);

    this.buildWorkflow().subscribe(() => {
      this.setupHandlers();
      setTimeout(() => {
        this.loaded = true;
      }, 100);
    });
  }

  private setActivityStatus(activityId, status) {
    const cell = this.getActivityCell(activityId);

    if (cell) {
      // const model = this.graph.getModel().getValue(cell);

      if (cell.isVertex() && cell.value) {
        cell.value.status = status;
        this.graph.model.setValue(cell, cell.value);
      }
    }
  }

  private loadWorkflow() {
    this._validation.clearValidationCache();
    let loadObs = null;
    if (!this.versionId) {
      loadObs = this._workflowSvc.getWorkflow(this.workflowId, true, false);
    } else {
      loadObs = this._workflowSvc.getWorkflowVersion(this.versionId, true);
    }

    loadObs.subscribe(workflow => {
      workflow.designStatus = ApplicationStatus.InProgress;
      this._context.workflow$.next(workflow);
    });
  }

  resetAndBuildGraph(
    workflow: Workflow,
    buildSidebar = true
  ): Observable<void> {
    this.graphLoading = true;
    this.sw.start();
    this.clearGraph();

    const done = Observable.create(o => {
      setTimeout(() => {
        try {
          if (this.activityAddedSub) {
            this.activityAddedSub.unsubscribe();
          }

          this.sw.lap('begin render graph');
          this.renderGraph(buildSidebar);
          this.sw.lap('finished render graph');

          // this is moved here so that the graph is created before we try to react to scale changing
          window.onresize = this.scaleChange.bind(this);

          // resize all activities
          this.graph.getModel().beginUpdate();
          this.sw.lap('begin graph update');
          const activities = new WorkflowGraph(
            workflow.version.graph
          ).getActivities(workflow.designStatus);
          activities.forEach((activity, index) => {
            this.setActivityStatus(activity.id, 'NotStarted');
            this.resizeActivity(activity.id, false);
          });
        } finally {
          if (this.graph) {
            this.graph.getModel().endUpdate();
          }
          // this.outline.update(true);
          this.sw.lap('finish graph update');
          this.autoLayout();
          this.sw.lap('finish auto layout');
          this.sw.lap('center graph started');
          this.centerGraph();
          this.sw.lap('center graph finished');
          this.sw.stop();
          this.graphLoading = false;
        }

        this.activityAddedSub = this.activityAdded.subscribe(activity => {
          // add an activity added change to be saved
          this.addActivityChange({
            changeType: 'activity',
            typeOfChange: 'add',
            changedActivity: JSON.parse(JSON.stringify(activity))
          });
          // this.changes.push({
          //   changeType: 'activity',
          //   typeOfChange: 'add',
          //   changedActivity: activity
          // });
          // this timeout is a hack to make sure that the element exists before accessing it
          setTimeout(() => {
            this.graph.clearSelection();
            this.resizeActivity(activity.id, true);
          }, 50);
        });
        o.next();
        o.complete();
      }, 5);
    });

    return done;
  }

  ngAfterViewInit(): void {
    this.sw.lap('start get Workflow');
    let loadObs = null;
    if (!this.isReadOnly()) {
      loadObs = this._workflowSvc.getWorkflow(this.workflowId, true, false);
    } else {
      loadObs = this._workflowSvc.getWorkflowVersion(this.versionId, true);
    }

    loadObs.subscribe(workflow => {
      this.sw.lap('workflow retrieved from server');
      // if there is no workflow, create one
      if (!workflow) {
        workflow = new Workflow({
          id: this.workflowId,
          version: new WorkflowVersion({
            graph: new PermitWorkflowGraph({
              onStarted: new OnStartedActivity(
                WorkflowService.activities[ACTIVITIES.OnStarted]
              ),
              onCompleted: new OnCompletedActivity(
                WorkflowService.activities[ACTIVITIES.OnCompleted]
              )
            })
          }),
          client: this._context.client
        });
        workflow.version.graph.onStarted.id = Utilities.generateId();
        workflow.version.graph.onCompleted.id = Utilities.generateId();
      }

      workflow.designStatus = ApplicationStatus.InProgress;

      this._context.client$.next(workflow.client);
      this._context.workflow$.next(workflow);

      this.displayAppStatus().subscribe(() => {
        if (!this.isReadOnly()) {
          this.startSaveInterval();
        }
      });
    });
  }

  displayAppStatus(): Observable<ApplicationDiagramInfo> {
    let buildObs = null;

    if (this.applicationId) {
      buildObs = this._workflowSvc.getApplicationDiagramInfo(
        this.applicationId,
        this.viewingIteration
      );
    } else {
      buildObs = of(null);
    }

    buildObs.subscribe(appDiagramInfo => {
      this.resetAndBuildGraph(this._context.workflow).subscribe(() => {
        this._appDiagramInfo = appDiagramInfo;
        // this._appDiagramInfo.iterations.unshift(0);
        if (appDiagramInfo) {
          this.graph.getModel().beginUpdate();
          if (appDiagramInfo.completedActivities) {
            appDiagramInfo.completedActivities.forEach((a, idx) => {
              // outline each of the activities with Green if they have been completed
              this.setActivityStatus(a.activityId, 'Completed');
            });
          }

          if (appDiagramInfo.inProgressActivities) {
            appDiagramInfo.inProgressActivities.forEach((a, idx) => {
              // outline each of the activities with yellow that are in progress
              this.setActivityStatus(a.activityId, 'InProgress');
            });
          }

          if (appDiagramInfo.canceledActivities) {
            appDiagramInfo.canceledActivities.forEach((a, idx) => {
              // outline each of the activities with red that have been cancelled
              this.setActivityStatus(a.activityId, 'Canceled');
            });
          }

          this.graph.getModel().endUpdate();
        }
        // begin select any given activity..
        this.route.queryParams.subscribe(params => {
          const activityId = params['activityId'];
          if (activityId) {
            // if I have an activityId use it to select the activity....
            const activityCell = this.getActivityCell(activityId);
            if (activityCell) {
              this.navigateToSearchItem({
                type: '',
                id: '',
                activityId: activityId,
                activityName: '',
                templateCode: '',
                dataEntityLabel: ''
              });
              this.toggleWorkflowSearch();
              // open the editor.
              this.openActivity(activityCell);
            }
          }
        });

        // end select any given activity
        // this.sw.stop();
      });
    });

    return buildObs;
  }

  ngOnDestroy() {
    this.activityCellIndex = {};
    // Stop listening to client changes.

    if (this.graph) {
      this.removeHandlers();
      this.graph.destroy();
    }

    if (this.centerOnActivitySub) {
      this.centerOnActivitySub.unsubscribe();
    }

    if (this.activityAddedSub) {
      this.activityAddedSub.unsubscribe();
    }
    if (this.activitySidebarSub) {
      this.activitySidebarSub.unsubscribe();
    }
    if (this.activitySidebarCloseSub) {
      this.activitySidebarCloseSub.unsubscribe();
    }

    if (this.validatedListener) {
      this.validatedListener.unsubscribe();
    }

    if (this.validatingListener) {
      this.validatingListener.unsubscribe();
    }

    if (this.savingListener) {
      this.savingListener.unsubscribe();
    }

    if (this.savedListener) {
      this.savedListener.unsubscribe();
    }

    if (this.validationModalClickSub) {
      this.validationModalClickSub.unsubscribe();
    }

    clearInterval(this.saveInterval);
  }
}
